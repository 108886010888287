import React from 'react';
import { NavLink } from 'react-router-dom';

import PhotoUrlPathHelper from '../../../api/PhotoUrlPathHelper';
import SingleImageGallery from '../../gallery/SingleImageGallery';

const CommentLightItem = ({ id, created, text, photoIds, isPrivate }) => {
    let getOriginal = !isPrivate ?
        PhotoUrlPathHelper.getCommentOrig :
        PhotoUrlPathHelper.getPrivateCommentGalOrig;
    let getThumb = !isPrivate ?
        PhotoUrlPathHelper.getCommentThumb :
        PhotoUrlPathHelper.getPrivateCommentGalThumb;

    return (<tr>
        <td>{text}</td>
        <td className='text-right'>
            <SingleImageGallery
                id={id}
                photoIds={photoIds}
                getOriginal={getOriginal}
                getThumb={getThumb} />
            <NavLink to={'/comment/' + id}>
                {new Date(created).toLocaleDateString()}
            </NavLink>
        </td>
    </tr>);
};

export default CommentLightItem;