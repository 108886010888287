import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons';

import RecordTypeGroup from '../filter/RecordTypeGroup';
import RecordRangeList from '../filter/RecordRangeList';
import Checkbox from '../form/Checkbox';
import FilterSelectionPanel from '../filter/FilterSelectionPanel';

class AtlasFilterPanel extends React.Component {

    constructor(props) {
        super(props);

        this.state = { ...this.props.filter };
        this.defaultFilter = { ...this.props.defaultFilter };

        this.onRecordTypeChange = this.onRecordTypeChange.bind(this);
        // this.onCheckboxChange = this.onCheckboxChange.bind(this);
        this.onRangeChange = this.onRangeChange.bind(this);
        this.onFilterReset = this.onFilterReset.bind(this);
    }

    onFilterReset() {
        let filter = { ...this.defaultFilter };
        this.setState(filter);
        this.props.onFilterChange(filter)
    }

    onRecordTypeChange(event) {
        let state = { ...this.state };
        state.recordType = event.target.value;
        this.setState({ ...state });

        this.props.onFilterChange(state);
    }

    // onCheckboxChange(event) {
    //     const target = event.target;
    //     const value = target.type === 'checkbox' ? target.checked : target.value;
    //     const name = target.name;
    //     this.setState({
    //         [name]: value
    //     });
    // }

    onRangeChange(field, min, max) {
        this.setState({
            ['min' + field]: min,
            ['max' + field]: max
        });
    }

    render() {
        return (<div className='text-center mt-2'>
            <RecordTypeGroup recordType={this.state.recordType} onChange={this.onRecordTypeChange} />
            <div className="col-auto">
                <FilterSelectionPanel filter={this.state} configuration={this.props.configuration} />
                <div className='pointer collapsed' data-toggle="collapse" data-target="#collapseFilter" aria-expanded="false" aria-controls="collapseFilter">
                    rozšířený filtr <i className="fa"></i>
                </div>
                <div className="collapse" id="collapseFilter">
                    <div style={{ margin: '0 auto', width: '60%' }} className='my-3'>
                        <RecordRangeList updateFilter={this.onRangeChange} {...this.state} configuration={this.props.configuration} />
                        {/* <Checkbox text={'Skrýt navšítevé'} name={'hideVisited'} onChange={this.onCheckboxChange} value={this.state.hideVisited} /> */}
                    </div>
                    <div className="col-auto">
                        <div className="btn-group btn-group-toggle m-2">
                            <button className="btn btn-outline-primary mb-2" onClick={() => this.props.onFilterChange({ ...this.state })} >Filtrovat</button>
                            <button className="btn btn-outline-primary mb-2" title='Resetovat' onClick={() => this.onFilterReset()}><FontAwesomeIcon icon={faTrash} /></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}

export default AtlasFilterPanel;