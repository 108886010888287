import React from 'react';

class FilterSelectionPanel extends React.Component {

    constructor(props) {
        super(props);


        this.buildSelected = this.buildSelected.bind(this);
        this.addWhenNotDefault = this.addWhenNotDefault.bind(this);
    }

    buildSelected(filter, configuration) {
        let selections = [];

        this.addWhenNotDefault(selections, filter.minRating, 0, filter.maxRating, 5, 'hodnocení')
        this.addWhenNotDefault(selections, filter.minNumRating, 0, filter.maxNumRating, configuration.maxRatings, 'počet hodnocení')
        this.addWhenNotDefault(selections, filter.minVisits, 0, filter.maxVisits, configuration.maxVisits, 'návštěvy')
        this.addWhenNotDefault(selections, filter.minComments, 0, filter.maxComments, configuration.maxComments, 'komentáře')
        this.addWhenNotDefault(selections, filter.minFavourites, 0, filter.maxFavourites, configuration.maxFavourites, 'oblíbené')

        return selections;
    };

    addWhenNotDefault(selections, minValue, minDefValue, maxValue, maxDefValue, field) {
        if (minValue !== minDefValue && maxValue !== maxDefValue) {
            selections.push(minValue + ' < ' + field + ' < ' + maxValue);
            return;
        }

        if (minValue !== minDefValue) {
            selections.push(minValue + ' < ' + field);
        }

        if (maxValue !== maxDefValue) {
            selections.push(field + ' < ' + maxValue);
        }
    }

    render() {
        let selections = this.buildSelected(this.props.filter, this.props.configuration)

        return (<div className='filter-selection'>
            {selections.map((text, i) =>
                <span key={i}>{text} </span>
            )}
        </div>);
    }
}

export default FilterSelectionPanel;