import React from 'react';
import { NavLink } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as selectedStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as unselectedStar } from '@fortawesome/free-regular-svg-icons';
import { faPlace } from '../../icons/place';
import { faRoad } from '../../icons/road';

const RatingItem = ({ recordTitle, recordId, type, created, stars }) => {

    var userStars = [];

    for (let k = 1; k < 6; k++) {
        if (stars >= k) {
            userStars.push(<FontAwesomeIcon className='primary' key={k} icon={selectedStar} />);
        }
        else {
            userStars.push(<FontAwesomeIcon className='primary' key={k} icon={unselectedStar} />);
        }
    }

    return (<tr>
        <td>
            {type === 1 ?
                <span>
                    <FontAwesomeIcon icon={faPlace} className='primary' /> <NavLink to={"/place/" + recordId}>{recordTitle}</NavLink>
                </span> :
                <span>
                    <FontAwesomeIcon icon={faRoad} className='primary' /> <NavLink to={"/road/" + recordId}>{recordTitle}</NavLink>
                </span>
            }
        </td>

        <td className={'text-right'}>
            <div>{userStars}</div>
            <div>{new Date(created).toLocaleString()}</div>
        </td>
    </tr>);
};

export default RatingItem;