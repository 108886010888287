import { combineReducers } from 'redux';

import {
    CONFIGURATION_FETCH_SUCCESS,
    CONFIGURATION_FETCH_LOADING,
    CONFIGURATION_FETCH_ERRORED,
} from '../actions/configurationActionTypes';

const defaultConfiguration = {
    isLoaded: false
};

function configurationLoaded(state = defaultConfiguration, action) {
    switch (action.type) {
        case CONFIGURATION_FETCH_SUCCESS:
            action.configuration.isLoaded = true;
            return action.configuration;
        default:
            return state;
    }
}

function configurationIsLoading(state = true, action) {
    switch (action.type) {
        case CONFIGURATION_FETCH_LOADING:
            return true;
        case CONFIGURATION_FETCH_ERRORED:
        case CONFIGURATION_FETCH_SUCCESS:
            return false;
        default:
            return state;
    }
}

function configurationErrored(state = false, action) {
    switch (action.type) {
        case CONFIGURATION_FETCH_ERRORED:
            return true;
        case CONFIGURATION_FETCH_LOADING:
        case CONFIGURATION_FETCH_SUCCESS:
            return false;
        default:
            return state;
    }
}

const configurationReducer = combineReducers({
    configuration: configurationLoaded,
    isLoading: configurationIsLoading,
    errored: configurationErrored
});

export default configurationReducer;