import React, { useContext } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import { TripStats, UserStats } from '../../../types/seasonTypes';
import { UserContext } from '../UserContext';
import LoginButton from '../../user/LoginButton';

const TripUserProgress = (props: { trip: TripStats, user: UserStats }) => {

    const user = useContext(UserContext);

    const trip = props.trip;
    const userProgress = props.user;
    const percent = Math.floor(userProgress.traveledMeters / trip.totalMeters * 100);

    return (
        <>
            <div className="season-progress">
                {!user &&
                    <div className="season-progress__done">
                        <p>Pro účast v sezóně musíte být přihlášen</p>
                        <LoginButton />
                    </div>
                }
                <div className="season-progress__caption">Tvůj progress</div>
                <div className="season-progress__content">
                    {percent === 100 &&
                        <div className="season-progress__done">
                            <FontAwesomeIcon className='primary' icon={faCheckCircle} />
                            <p>Celý výlet máte splněný!</p>
                            {/* <a href="#">Call to Action</a> */}
                        </div>
                    }
                    <div className='season-progress__stats'>
                        <div className='season-progress__stats-col'>
                            <div className='season-progress__stats-name'>Etapy</div>
                            <div className='season-progress__stats-val'>{userProgress.traveledStages}/{trip.totalStages}</div>
                        </div>
                        <div className='season-progress__stats-col'>
                            <div className='season-progress__stats-name'>Kilometry</div>
                            <div className='season-progress__stats-val'>{Math.floor(userProgress.traveledMeters / 1000)}km / {Math.floor(trip.totalMeters / 1000)}km</div>
                        </div>
                    </div>

                    <div className="season-progress__bar">
                        <div className="progress">
                            <div className="progress-bar" role="progressbar" aria-valuenow={percent} aria-valuemin={0} aria-valuemax={100}
                                style={{ width: `${percent}%` }}>
                                <span className="progress-bar__tooltip">{percent}%</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TripUserProgress;
