import React from 'react';
import AsyncScriptLoader from 'react-async-script';

import { googleMapsKey } from '../../configuration';

import LoadingComponent from '../common/LoadingComponent';
import ErrorComponent from '../common/ErrorComponent';

class EmptyComponent extends React.Component {
    render() {
        return (<div></div>);
    }
}

const withClusterMap = (WrappedComponent) => {

    const GoogleLoader = AsyncScriptLoader('https://maps.googleapis.com/maps/api/js?libraries=places,geometry&key=' + googleMapsKey)(EmptyComponent);

    class HOC extends React.Component {
        constructor(props) {
            super(props);

            this.state = {
                googleLoaded: false,
                googleErrored: false
            };

            this.onGoogleLoad = this.onGoogleLoad.bind(this);
        }

        onGoogleLoad(a) {
            this.setState({
                googleLoaded: a.loaded,
                googleErrored: a.errored
            })
        }

        render() {
            if (this.state.googleErrored) {
                return <ErrorComponent />;
            }

            if (!this.state.googleLoaded) {
                return <div id={this.props.mapId}>
                    <div className='vertical-center-div'>
                        <LoadingComponent />
                    </div>
                    <GoogleLoader asyncScriptOnLoad={this.onGoogleLoad} />
                </div>;
            }

            return <WrappedComponent {...this.props} />
        }
    }

    return HOC;
};

export default withClusterMap;