import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons';

import SeasonUrlHelper from './SeasonUrlHelper';
import PartnerList from './PartnerList';
import { SeasonContext } from './SeasonContext';

const SeasonInfo = () => {
    let season = useContext(SeasonContext);
    const startDate = season.starTime.toLocaleDateString('cs-CZ');
    const endDate = season.endTime.toLocaleDateString('cs-CZ');
    const seasonHomePageUrl = SeasonUrlHelper.getSeasonHomeUrl(season.id, season.urlKey);

    return (
        <div className="py-3">
            <div className='container p-3 py-5'>
                <NavLink to={seasonHomePageUrl}><FontAwesomeIcon icon={faArrowAltCircleLeft} /> zpět na sezónu</NavLink>

                <h1>{season.name}</h1>
                <h5>{startDate} - {endDate}</h5>

                {/* <div className='py-4'>
                    <PartnerList seasonId={season.id} partners={season.partners} />
                </div> */}

                <div dangerouslySetInnerHTML={{ __html: season.pageData }} ></div>
            </div>
        </div>
    );
}

export default SeasonInfo;