import React from 'react';
import { connect } from 'react-redux';
import { stringify } from 'qs';
import RequestHelper from '../../api/RequestHelper';

import { fetchAtlasDetailsPageByFilter } from '../../actions/atlasActionCreators';

import AtlasFilterPanel from './AtlasFilterPanel';
import AtlasSortPanel from './AtlasSortPanel';
import FetchMoreButton from './FetchMoreButton';
import AtlasRecordList from './AtlasRecordList';

class AtlasDetailsPanel extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            pageLoading: false
        }

        this.fetchNextPage = this.fetchNextPage.bind(this);
    }

    fetchNextPage() {
        let items = this.props.pagination.itemsOnPage;
        let page = this.props.pagination.page + 1;

        let map = stringify(RequestHelper.buildMapQuery(this.props.map));
        let filter = stringify(RequestHelper.buildFilter(this.props.filter, this.props.configuration));
        let paging = stringify(RequestHelper.buildPaginationAndSort(page, items, this.props.sort));

        let query = map + '&' + filter + '&' + paging;
        console.log('fetching page - ' + page + ' ' + query);
        this.setState({ pageLoading: true });
        this.props.fetchPage(query);
    }

    componentDidUpdate(prevProps) {
        if (this.props.details.details.length !== prevProps.details.details.length) {
            this.setState({ pageLoading: false });
        }
    }

    render() {
        let pagination = this.props.details.pagination;
        let canFetch = pagination.page * pagination.itemsOnPage < pagination.totalItems;

        return (
            <div className='atlas-road-list' id={'atlas-records-panel'}>
                <AtlasFilterPanel
                    filter={this.props.filter}
                    defaultFilter={this.props.filter}
                    onFilterChange={this.props.onFilterChange}
                    configuration={this.props.configuration} />

                <AtlasSortPanel
                    sortChange={this.props.onSortChange}
                    sort={this.props.sort}
                    total={this.props.details.pagination.totalItems}
                    loading={this.props.details.loading} />

                <AtlasRecordList
                    records={this.props.details.details}
                    loading={this.props.details.loading}
                    errored={this.props.details.errored}

                    selectedRecordId={this.props.selectedRecordId}
                    selectRoad={this.props.selectRoad} />

                <FetchMoreButton
                    loading={this.state.pageLoading}
                    canFetch={canFetch}
                    fetch={this.fetchNextPage} />
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchPage: (query) => dispatch(fetchAtlasDetailsPageByFilter(query))
    };
};

const mapStateToProps = (state) => {
    return {
        details: state.atlas.details,
        pagination: state.atlas.details.pagination,
        configuration: state.configuration.configuration
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AtlasDetailsPanel);