/*global google*/
class MapToUrlHelper {
    static convertToUrl(response, width, height) {
        let urlPoints = 'https://maps.googleapis.com/maps/api/staticmap?';
        let size = 'size=' + width + "x" + height;
        let startMaker = this.createMarkerUrl('A', response.routes["0"].legs["0"].start_location);
        let endMarker = this.createMarkerUrl('B', response.routes["0"].legs["0"].end_location);
        let path = this.pathUrl(response);

        let result = urlPoints + size + startMaker + endMarker + path;
        console.log(result);
        return result;
    }

    static pathUrl(response) {
        let path = "&path=enc:";
        let b = response.routes["0"].overview_polyline;
        return path + b;
    }

    static createMarkerUrl(text, location) {
        let path = '&markers=size:mid';
        let label = '%7Clabel:' + text;
        let latLng = '%7C' + location.lat() + ',' + location.lng();
        return path + label + latLng;
    }

    static directionsToPoint(lat, lng) {
        let path = 'https://www.google.com/maps/dir//'
        let url = lat + ',' + lng;
        return path + url;
    }

    /* static toNavigation(ids, start, end, waypoints) {
        let path = 'https://www.google.com/maps/dir/?api=1&'
        let origin = 'origin=' + start.lat + ',' + start.lng;
        let originId = '&origin_place_id=' + ids.geoWaypoints[0];
        let destination = '&destination=' + end.lat + ',' + end.lng;
        let destinationId = '&destination_place_id=' + ids.geoWaypoints[1];

        let waypointsParam = '&waypoints=';
        let waypointIds = '&waypoint_place_ids=';
        for (let index = 0; index < waypoints.length; index++) {
            let value = ids.geoWaypoints[index + 1];
            let waypoint = waypoints[index];
            waypointsParam += 'via:' + waypoint.lat + ',' + waypoint.lng + "|";
            waypointIds += value + "|";
        }

        return path + origin + originId + destination + destinationId + waypointsParam + waypointIds;
    }*/

    static toGoogleDirections(directionsResult) {
        let directions = JSON.parse(directionsResult);
        directions.request = { travelMode: 'DRIVING' };

        directions.routes[0].legs[0].steps = [];
        for (let index = 0; index < directions.path.length; index++) {
            let polyline = directions.path[index];
            let points = google.maps.geometry.encoding.decodePath(polyline);
            let step = {
                travel_mode: 'DRIVING',
                polyline: { points: polyline },
                path: points,
                lat_lngs: points
            };

            directions.routes[0].legs[0].steps.push(step);
        }

        let start = directions.start;
        directions.routes[0].legs[0].start_location = new google.maps.LatLng({ lat: start.lat, lng: start.lng });
        return directions;
    }

    static toDirectionsResult(response) {
        let geoWaypoints = [];
        for (let i = 0; i < response.geocoded_waypoints.length; i++) {
            let waypoint = response.geocoded_waypoints[i];
            geoWaypoints.push(waypoint.place_id);
        }

        let path = [];
        for (let index = 0; index < response.routes[0].legs[0].steps.length; index++) {
            let polyline = response.routes[0].legs[0].steps[index].polyline.points;
            path.push(polyline);
        }

        return {
            geoWaypoints,
            path,
            start: response.routes[0].legs[0].start_location,
            routes: [{
                bounds: response.routes[0].bounds,
                legs: [{
                    distance: response.routes[0].legs[0].distance,
                    end_location: response.routes[0].legs[0].end_location,
                    start_location: response.routes[0].legs[0].start_location
                }]
            }]
        };
    }
}

export default MapToUrlHelper;
