import {
    DIARY_COMMENTS_FETCH_SUCCESS,
    DIARY_COMMENTS_FETCH_LOADING,
    DIARY_COMMENTS_FETCH_ERRORED
} from './commentsActionTypes';

import { DriversRoadsApi, jsonAPIAuthorizedRequest, onApiError } from '../../api/apiHelper';

function commentsFetchSuccess(comments, pagination) {
    return {
        type: DIARY_COMMENTS_FETCH_SUCCESS,
        comments,
        pagination
    };
}

function commentsFetchLoading() {
    return {
        type: DIARY_COMMENTS_FETCH_LOADING
    };
}

function commentsFetchErrored() {
    return {
        type: DIARY_COMMENTS_FETCH_ERRORED
    };
}

export function commentsFetch(query) {
    return (dispatch) => {
        dispatch(commentsFetchLoading());
        let options = jsonAPIAuthorizedRequest("GET", undefined);

        fetch(DriversRoadsApi + 'api/diary/comments?' + query, options)
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => response.json())
            .then((response) => {
                dispatch(commentsFetchSuccess(response.items, response.pagination));
            })
            .catch((e) => {
                onApiError(e);
                dispatch(commentsFetchErrored());
            });
    };
}