import { combineReducers } from 'redux';

import {
    DIARY_OVERVIEW_FETCH_SUCCESS,
    DIARY_OVERVIEW_FETCH_LOADING,
    DIARY_OVERVIEW_FETCH_ERRORED
} from '../../actions/diary/diaryActionTypes';

import { favouritesLoaded, favouritesPagination, favouritesLoading, favouritesErrored } from './favouritesReducer';
import { visitsLoaded, visitsPagination, visitsIsLoading, visitsErrored } from './visitsReducer';
import { ratingsLoaded, ratingsPagination, ratingsIsLoading, ratingsErrored } from './ratingsReducer';
import { commentsLoaded, commentsPagination, commentsIsLoading, commentsErrored } from './commentsReducer';
import { recordsLoaded, recordsPagination, recordsIsLoading, recordsErrored } from './recordsReducer';

const defaultDiary = {
    records: [],
    comments: [],
    favourites: [],
    visits: [],
    ratings: []
}

function diaryLoaded(state = defaultDiary, action) {
    switch (action.type) {
        case DIARY_OVERVIEW_FETCH_SUCCESS:
            return action.records;
        case DIARY_OVERVIEW_FETCH_ERRORED:
            return defaultDiary;
        default:
            return state;
    }
}

function diaryIsLoading(state = false, action) {
    switch (action.type) {
        case DIARY_OVERVIEW_FETCH_LOADING:
            return true;
        case DIARY_OVERVIEW_FETCH_ERRORED:
        case DIARY_OVERVIEW_FETCH_SUCCESS:
            return false;
        default:
            return state;
    }
}

function diaryErrored(state = false, action) {
    switch (action.type) {
        case DIARY_OVERVIEW_FETCH_ERRORED:
            return true;
        case DIARY_OVERVIEW_FETCH_LOADING:
        case DIARY_OVERVIEW_FETCH_SUCCESS:
            return false;
        default:
            return state;
    }
}

const diaryReducer = combineReducers({
    diary: diaryLoaded,
    isLoading: diaryIsLoading,
    errored: diaryErrored,
    visits: combineReducers({
        visits: visitsLoaded,
        pagination: visitsPagination,
        isLoading: visitsIsLoading,
        errored: visitsErrored
    }),
    favourites: combineReducers({
        favourites: favouritesLoaded,
        pagination: favouritesPagination,
        isLoading: favouritesLoading,
        errored: favouritesErrored
    }),
    ratings: combineReducers({
        ratings: ratingsLoaded,
        pagination: ratingsPagination,
        isLoading: ratingsIsLoading,
        errored: ratingsErrored
    }),
    comments: combineReducers({
        comments: commentsLoaded,
        pagination: commentsPagination,
        isLoading: commentsIsLoading,
        errored: commentsErrored
    }),
    records: combineReducers({
        records: recordsLoaded,
        pagination: recordsPagination,
        isLoading: recordsIsLoading,
        errored: recordsErrored
    })
});

export default diaryReducer;