import React from 'react';
import PropTypes from 'prop-types';
import Lightbox from 'react-images';

class LightboxModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            currentImage: 0
        };

        this.openLightbox = this.openLightbox.bind(this);
        this.closeLightbox = this.closeLightbox.bind(this);
        this.gotoNext = this.gotoNext.bind(this);
        this.gotoPrevious = this.gotoPrevious.bind(this);
        this.handleClickImage = this.handleClickImage.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            currentImage: nextProps.currentImage,
            isOpen: nextProps.openLightbox
        });
    }

    openLightbox(index) {
        this.setState({
            currentImage: index,
            isOpen: true
        });
    }

    closeLightbox() {
        this.setState({
            currentImage: 0,
            isOpen: false
        });
        this.props.onClose();
    }
    gotoPrevious() {
        this.setState({
            currentImage: this.state.currentImage - 1
        });
    }
    gotoNext() {
        this.setState({
            currentImage: this.state.currentImage + 1
        });
    }

    handleClickImage() {
        if (this.state.currentImage === this.props.images.length - 1) return;
        this.gotoNext();
    }

    render() {
        let closeOnEsc = true;
        let closeOnClickOutside = true;

        return (
            <div>
                <Lightbox images={this.props.images}
                    currentImage={this.state.currentImage}
                    isOpen={this.state.isOpen}
                    onClickImage={this.handleClickImage}
                    onClickNext={this.gotoNext}
                    onClickPrev={this.gotoPrevious}
                    onClose={this.closeLightbox}
                    shouldCloseOnOverlayClick={closeOnClickOutside}
                    shouldCloseOnEsc={closeOnEsc} />
            </div>
        )
    }
}

LightboxModal.propTypes = {
    images: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
    currentImage: PropTypes.number.isRequired,
    openLightbox: PropTypes.bool.isRequired
};

export default LightboxModal;
