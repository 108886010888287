import React from 'react';
import CommentItem from './CommentItem';

import { connect } from 'react-redux';
import { toggleCommentLike } from '../../actions/comment/commentLikeActionCreators';

class CommentsList extends React.Component {
    render() {
        let isLoggedIn = this.props.user != null && !this.props.user.expired;
        return (
            <div className="row justify-content-center my-2">
                <div className='col-lg-8'>
                    {this.props.comments.map((comment, i) => {
                        return <CommentItem key={i} {...comment} isLoggedIn={isLoggedIn} isPrivate={this.props.isPrivate} onLikeClick={this.props.toggleCommentLike} />;
                    })}
                </div>
            </div>
        );
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        toggleCommentLike: (id, likes) => dispatch(toggleCommentLike(id, likes))
    };
};

const mapStateToProps = (state) => {
    return {
        user: state.oidc.user
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CommentsList);