import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPen, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import LoadingComponent from '../../common/LoadingComponent';
import Likes from './Likes';
import AddPhotoButton from '../../common/AddPhotoButton';

class CommentPanel extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            editing: false,
            text: ''
        };

        this.onEditClick = this.onEditClick.bind(this);
        this.onEditDoneClick = this.onEditDoneClick.bind(this);
        this.onEditCancelClick = this.onEditCancelClick.bind(this);

        this.deleteComment = this.deleteComment.bind(this);

        this.handleChange = this.handleChange.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
    }

    deleteComment() {
        var result = window.confirm("Opravdu chcete komentář smazat?");
        if (result == true) {
            this.props.deleteComment(this.props.comment.id)
        };
    }

    handleChange(event) {
        this.setState({ text: event.target.value });
    }

    onEditClick() {
        this.setState({
            editing: true,
            text: this.props.comment.text
        });
    }

    onEditCancelClick() {
        this.setState({
            editing: false,
            text: this.props.comment.text
        });
    }

    onEditDoneClick() {
        this.setState({
            editing: false
        });
        this.props.editComment(this.props.comment.id, this.state.text);
    }

    onFileChange(file) {
        this.props.addCommentPhoto(this.props.comment.id, file);
    }

    render() {
        return (
            <div className='card-body'>
                <div className="">
                    {this.props.comment.editLoading ?
                        <LoadingComponent /> :
                        this.state.editing ?
                            <textarea className='form-control lead' value={this.state.text} onChange={this.handleChange} /> :
                            <p className='lead'>{this.props.comment.text} </p>
                    }
                </div>
                <div className='row'>
                    <div className="col-sm-2 text-left">
                        <Likes
                            commentId={this.props.comment.id}
                            enabled={this.props.isLoggedIn}
                            userLike={this.props.comment.userLike}
                            loading={this.props.comment.likeLoading}
                            numberOfLikes={this.props.comment.numberOfLikes}
                            onClick={this.props.toggleCommentLike} />
                    </div>

                    <div className="col-sm-3 text-center">
                        {this.props.isOwner &&
                            <AddPhotoButton addPhoto={this.onFileChange}
                                currentPhotos={this.props.comment.photoIds.length}
                                maxPhotos={5} />
                        }
                    </div>

                    <div className="col-sm-2 text-center">
                        {this.props.isOwner && <div className='pointer' onClick={() => this.deleteComment()}>
                            <div>
                                <FontAwesomeIcon icon={faTrash} />
                            </div>
                            Smazat
                        </div>}
                    </div>

                    <div className="col-sm-2  text-center">
                        {this.props.isOwner && <div>
                            {this.state.editing ?
                                <div style={{ display: 'inline-block' }}>
                                    <FontAwesomeIcon className='pointer' icon={faCheckCircle}
                                        onClick={() => this.onEditDoneClick()} /> &nbsp;
                                <FontAwesomeIcon className='pointer'
                                        icon={faTimesCircle} onClick={() => this.onEditCancelClick()} />
                                </div> :
                                <div>
                                    <FontAwesomeIcon className='pointer'
                                        icon={faPen}
                                        onClick={() => this.onEditClick(this.props.comment.id)} />
                                </div>
                            }
                            Upravit
                            </div>
                        }
                    </div>
                    <div className="col-sm-3 text-right">
                        <div className="ml-2">
                            <div className="h5 m-0">{this.props.comment.username}</div>
                            <div className="h7 text-muted">{new Date(this.props.comment.created).toLocaleDateString()}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CommentPanel;