import React from 'react';
import { NavLink } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldAlt, faGlobe } from '@fortawesome/free-solid-svg-icons';

import PhotoUrlPathHelper from '../../../api/PhotoUrlPathHelper';

const RecordLightItem = ({ id, created, title, type, profilePhotoId, isPrivate, isDeleted }) => {

    if (isDeleted) {
        return (<tr className="align-middle text-center">
            <td>
                Smazaný záznam
            </td>
        </tr>);
    }


    let imageSrc = !isPrivate ? PhotoUrlPathHelper.getRecordGalThumb(profilePhotoId, id) : PhotoUrlPathHelper.getPrivateRecordGalThumb(profilePhotoId);

    let accessIcon = isPrivate ?
        <FontAwesomeIcon icon={faShieldAlt} /> :
        <FontAwesomeIcon icon={faGlobe} />
    let accessText = isPrivate ? 'Soukromé' : 'Veřejné';

    return (<tr className="align-middle">
        <td>
            <img className="media-object img-rounded img-responsive img-max-100" src={imageSrc} alt="placehold.it/350x250" />
        </td>
        <td>
            <h3>
                {type === 1 ?
                    <img alt='Place' src="/img/place-marker.png" /> :
                    <img alt='Road' src="/img/road-marker.png" />
                }&nbsp;
                {type === 1 ?
                    <NavLink to={"/place/" + id}>{title}</NavLink> :
                    <NavLink to={"/road/" + id}>{title}</NavLink>
                }
            </h3>
        </td>
        <td className='text-right'>
            <div>{accessIcon} {accessText}</div>
            {new Date(created).toLocaleString()}
        </td>
    </tr>);
};

export default RecordLightItem;