import { combineReducers } from 'redux';

import {
    RECORD_COMMENTS_FETCH_SUCCESS,
    RECORD_COMMENTS_FETCH_LOADING,
    RECORD_COMMENTS_FETCH_ERRORED,

    RECORD_COMMENTS_PAGINATION_CHANGED
} from '../actions/record/recordCommentsActionTypes';

import {
    COMMENT_LIKE_TOGGLE_LOADING,
    COMMENT_LIKE_TOGGLE_SUCCESS,
    COMMENT_LIKE_TOGGLE_ERRORED
} from '../actions/comment/commentLikeActionTypes'

const defaultPagination = {
    page: 1
};

function commentsPagination(state = defaultPagination, action) {
    switch (action.type) {
        case RECORD_COMMENTS_PAGINATION_CHANGED:
            return action.pagination;
        default:
            return state;
    }
}

function commentsLoaded(state = [], action) {
    switch (action.type) {
        case COMMENT_LIKE_TOGGLE_LOADING:
            return state.map((comment) => {
                if (comment.id === action.commentId) {
                    return Object.assign({}, comment, {
                        likeLoading: true
                    });
                }

                return comment;
            });
        case COMMENT_LIKE_TOGGLE_ERRORED:
            return state.map((comment) => {
                if (comment.id === action.commentId) {
                    return Object.assign({}, comment, {
                        likeLoading: false
                    });
                }

                return comment;
            });
        case COMMENT_LIKE_TOGGLE_SUCCESS:
            return state.map((comment) => {
                if (comment.id === action.commentId) {
                    return Object.assign({}, comment, {
                        userLike: action.userLike,
                        numberOfLikes: action.numberOfLikes,
                        likeLoading: false
                    });
                }
                return comment;
            });

        case RECORD_COMMENTS_FETCH_SUCCESS:
            return action.comments;
        default:
            return state;
    }
}

function commentsIsLoading(state = false, action) {
    switch (action.type) {
        case RECORD_COMMENTS_FETCH_LOADING:
            return true;
        case RECORD_COMMENTS_FETCH_ERRORED:
        case RECORD_COMMENTS_FETCH_SUCCESS:
            return false;
        default:
            return state;
    }
}

function commentsErrored(state = false, action) {
    switch (action.type) {
        case RECORD_COMMENTS_FETCH_ERRORED:
            return true;
        case RECORD_COMMENTS_FETCH_LOADING:
        case RECORD_COMMENTS_FETCH_SUCCESS:
            return false;
        default:
            return state;
    }
}

const recordCommentsReducer = combineReducers({
    comments: commentsLoaded,
    pagination: commentsPagination,
    isLoading: commentsIsLoading,
    errored: commentsErrored
});

export default recordCommentsReducer;