import React from 'react';
import { connect } from 'react-redux';

import GallerySlider from './GallerySlider';
import PhotoUrlPathHelper from '../../api/PhotoUrlPathHelper';
import { deleteCommentPhoto } from '../../actions/comment/commentPhotoActionCreators';

const CommentsGalleryResponsive = [
    {
        breakpoint: 10000,
        settings: {
            slidesToShow: 5,
            slidesToScroll: 5
        }
    },
    {
        breakpoint: 1200,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 3
        }
    },
    {
        breakpoint: 500,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 2
        }
    }
];

class CommentGallery extends React.Component {

    constructor(props) {
        super(props);

        this.onPhotoDeleteClick = this.onPhotoDeleteClick.bind(this);
    }

    onPhotoDeleteClick(photoId) {
        this.props.deleteCommentPhoto(this.props.id, photoId, this.props.isPrivate);
    }

    render() {

        let getOriginal = !this.props.isPrivate ?
            PhotoUrlPathHelper.getCommentOrig :
            PhotoUrlPathHelper.getPrivateCommentGalOrig;
        let getThumb = !this.props.isPrivate ?
            PhotoUrlPathHelper.getCommentThumb :
            PhotoUrlPathHelper.getPrivateCommentGalThumb;

        return (
            <GallerySlider
                id={this.props.id}
                canDeleteFirst={true}
                isOwner={this.props.isOwner}
                responsive={CommentsGalleryResponsive}
                photoIds={this.props.photoIds}
                onDeleteClick={this.onPhotoDeleteClick}
                getOriginal={getOriginal}
                getThumb={getThumb} />
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        deleteCommentPhoto: (commentId, photoId, isPrivate) => dispatch(deleteCommentPhoto(commentId, photoId, isPrivate)),
    };
};

export default connect(null, mapDispatchToProps)(CommentGallery);