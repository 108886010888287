/*global google*/
import React from 'react';
import PropTypes from 'prop-types';
import toastr from 'toastr';
import { debounce } from 'lodash';

import { CreateMap } from './MapConfiguration';
import MapToUrlHelper from './MapToUrlHelper';
import withGoogleMap from '../hoc/withGoogleMap';
import LocationHelper from '../../utils/LocationHelper';
import GoogleApiHelper from '../../utils/GoogleApiHelper';
import LocalePanel from './panels/LocalePanel';
import MapUserPositionHelper from './PositionHelper';

class RoadEditMap extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.avoidHighways = this.props.avoidHighways;
    this.directions = null;
    this.directionsService = null;
    this.directionsDisplay = null;
    this.map = null;
    this.geocoder = null;
    this.positionHelper = null;

    this.mapClick = this.mapClick.bind(this);
    this.drawRoute = this.drawRoute.bind(this);
    this.setRouteDirections = this.setRouteDirections.bind(this);
    this.onDirectionsChanged = this.onDirectionsChanged.bind(this);

    this.getLocationName = this.getLocationName.bind(this);
    this.debounceDetails = debounce(this.getLocationName, 500);

    this.onStartLocationChanged = this.onStartLocationChanged.bind(this);

    this.onInitLoad = this.onInitLoad.bind(this);
    this.onLocateMeError = this.onLocateMeError.bind(this);
    this.onLocateMeSuccess = this.onLocateMeSuccess.bind(this);

    this.onLocationChange = this.onLocationChange.bind(this);

    this.revertDirections = this.revertDirections.bind(this);
    this.saveDirections = this.saveDirections.bind(this);
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.avoidHighways !== this.avoidHighways) {
      this.avoidHighways = nextProps.avoidHighways;
      if (nextProps.startLocation !== '' && nextProps.destLocation !== '') {
        this.drawRoute();
        return;
      }
    }

    if (nextProps.startLocation === '' && nextProps.destLocation === '') {
      this.directionsDisplay.setMap(null);
    }
  }

  onStartLocationChanged(address) {
    let map = this.directionsDisplay.getMap();
    if (!map) {
      return;
    }

    var directions = this.directionsDisplay.getDirections();
    let distance = directions.routes["0"].legs["0"].distance.value;
    if (this.props.allowedCountries.includes(address.countryCode.toLowerCase())) {
      this.saveDirections(directions, address, distance);
    }
    else {
      this.revertDirections(address.country + ' není podporováno');
    }
  }

  saveDirections(directions, address, distance) {
    var start = directions.routes["0"].legs["0"].start_address;
    var startLocation = directions.routes["0"].legs["0"].start_location;
    var end = directions.routes["0"].legs["0"].end_address;
    var endLocation = directions.routes["0"].legs["0"].end_location;
    var waypoints = directions.routes["0"].legs["0"].via_waypoints;

    var previewUrl = MapToUrlHelper.convertToUrl(directions, 650, 650);
    this.props.setPreviewUrl(previewUrl);

    var result = MapToUrlHelper.toDirectionsResult(directions);
    this.props.setWaypoints(result, start, startLocation, end, endLocation, waypoints, distance, address.countryCode.toLowerCase());
    this.directions = directions;
  }

  revertDirections(message) {
    toastr.warning(message);
    if (this.directions) {
      this.directionsDisplay.setDirections(this.directions);
    }
  }

  onDirectionsChanged() {
    let directions = this.directionsDisplay.getDirections();
    if (directions.routes.length <= 0) {
      return;
    }

    let distance = directions.routes["0"].legs["0"].distance.value;
    if (distance > 500000) {
      this.revertDirections('Maximální délka trasy 500km překročena - ' + (Math.round(distance / 1000 * 10) / 10) + 'km');
      return;
    }

    //validate start address
    var startLocation = directions.routes["0"].legs["0"].start_location;
    GoogleApiHelper.geocodeLocation(startLocation.lng(), startLocation.lat()).then(this.onStartLocationChanged);
  }

  setRouteDirections(response, status) {
    if (status === 'OK') {
      this.directionsDisplay.setDirections(response);
      let map = this.directionsDisplay.getMap();
      if (!map) {
        this.directionsDisplay.setMap(this.map);
      }
    } else {
      window.alert('Directions request failed due to ' + status);
    }
  }

  drawRoute() {
    var waypoints = [];
    for (var i = 0; i < this.props.waypoints.length; i++) {
      waypoints.push({
        location: new google.maps.LatLng(this.props.waypoints[i].lat(),
          this.props.waypoints[i].lng()),
        stopover: false
      });
    }

    this.directionsService.route({
      origin: this.props.startLocation,
      destination: this.props.destLocation,
      waypoints: waypoints,
      optimizeWaypoints: false,
      travelMode: 'DRIVING',
      avoidFerries: false,
      avoidHighways: this.avoidHighways,
      avoidTolls: false
    }, (resp, status) => this.setRouteDirections(resp, status));
  }

  getLocationName(addresses, latLng) {
    if (addresses === null) {
      toastr.warning('Místo se nepodařilo lokalizovat');
      return;
    }

    if (addresses.length === 0) {
      toastr.warning('Neplatné místo na mapě');
      return;
    }

    if (this.props.selectingStart) {
      let location = GoogleApiHelper.parseAutocompleteResult(addresses[0]);
      if (this.props.allowedCountries.includes(location.countryCode.toLowerCase())) {
        toastr.info('Nastavuji start na ' + addresses[0].formatted_address);
        this.props.startSelected(addresses[0].formatted_address, location.countryCode.toLowerCase());
      }
      else {
        toastr.warning(location.country + ' není podporováno');
      }
    }
    if (this.props.selectingFinish) {
      toastr.info('Nastavuji cíl na ' + addresses[0].formatted_address);
      this.props.finishSelected(addresses[0].formatted_address);
    }

    if (this.props.startLocation !== '' && this.props.destLocation !== '') {
      this.drawRoute();
    }
  }

  mapClick(e) {
    if (this.props.selectingStart || this.props.selectingFinish) {
      let location = e.latLng;
      let request = { 'location': location };
      this.geocoder.geocode(request, (results) => this.getLocationName(results, location));
    }
  }

  onLocateMeSuccess(gps) {
    this.map.setCenter({ lat: gps.lat, lng: gps.lng });
  }

  onLocateMeError() {
    this.map.setCenter({ lat: 50, lng: 14 });
  }

  onInitLoad() {
    this.positionHelper = new MapUserPositionHelper(this.map);
    this.positionHelper.startWatching();
    LocationHelper.locateGPS(this.onLocateMeSuccess, this.onLocateMeError);
  }

  onLocationChange(location) {
    this.map.panTo({ lat: location.lat, lng: location.lng });
  }

  componentDidMount() {
    this.geocoder = new google.maps.Geocoder();
    this.directionsService = new google.maps.DirectionsService();
    this.directionsDisplay = new google.maps.DirectionsRenderer(
      {
        draggable: true,
        preserveViewport: true
      });

    this.directionsDisplay.directions_changed = this.onDirectionsChanged;

    this.map = CreateMap(this.props.mapId);
    google.maps.event.addListenerOnce(this.map, 'idle', this.onInitLoad);
    this.map.addListener('click', (e) => this.mapClick(e));

    var localeMapButton = document.getElementById('locale-map-button');
    localeMapButton.index = 1;
    this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(localeMapButton);

    this.directionsDisplay.setMap(this.map);
  }

  componentWillUnmount() {
    this.positionHelper.clearWatch();
  }

  render() {
    return (<div id="wrapper">
      <div style={{ visibility: 'collapse', position: 'absolute' }}>
        <div><LocalePanel onLocationChange={this.onLocationChange} /></div>
      </div>
      <div id={this.props.mapId}></div>
    </div >);
  }
}

RoadEditMap.propTypes = {
  mapId: PropTypes.string.isRequired
};

export default withGoogleMap(RoadEditMap);