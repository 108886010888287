import {
    COMMENT_ADD_RECORD_PREVIEW_LOADING,
    COMMENT_ADD_RECORD_PREVIEW_SUCCESS,
    COMMENT_ADD_RECORD_PREVIEW_ERRORED,

    COMMENT_ADD_LOADING,
    COMMENT_ADD_SUCCESS,
    COMMENT_ADD_ERRORED
} from '../actions/comment/commentAddActionTypes';

export function commentRecordPreviewLoaded(state = {}, action) {
    switch (action.type) {
        case COMMENT_ADD_RECORD_PREVIEW_SUCCESS:
            return action.record
        default:
            return state;
    }
}

export function commentRecordPreviewIsLoading(state = false, action) {
    switch (action.type) {
        case COMMENT_ADD_RECORD_PREVIEW_LOADING:
            return true;
        case COMMENT_ADD_RECORD_PREVIEW_SUCCESS:
        case COMMENT_ADD_RECORD_PREVIEW_ERRORED:
            return false;
        default:
            return state;
    }
}

export function commentRecordPreviewErrored(state = false, action) {
    switch (action.type) {
        case COMMENT_ADD_RECORD_PREVIEW_ERRORED:
            return true;
        case COMMENT_ADD_RECORD_PREVIEW_LOADING:
        case COMMENT_ADD_RECORD_PREVIEW_SUCCESS:
            return false;
        default:
            return state;
    }
}

export function commentAddLoading(state = false, action) {
    switch (action.type) {
        case COMMENT_ADD_LOADING:
            return true;
        case COMMENT_ADD_SUCCESS:
        case COMMENT_ADD_ERRORED:
            return false;
        default:
            return state;
    }
}

export function commentAddErrored(state = false, action) {
    switch (action.type) {
        case COMMENT_ADD_ERRORED:
            return true;
        case COMMENT_ADD_LOADING:
        case COMMENT_ADD_SUCCESS:
            return false;
        default:
            return state;
    }
}