import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
// import { ConnectedRouter } from 'connected-react-router';
// import history from '../../store/configureHistory';

import DiaryOverViewPage from './DiaryOverviewPage';

import DiaryRecordsPage from './DiaryRecordsPage';
import DiaryCommentsPage from './DiaryCommentsPage';
import FavouritesPage from '../favourite/FavouritesPage';
import VisitsPage from '../visits/VisitsPage';
import RatingsPage from '../ratings/RatingsPage';

class DiaryComponent extends React.Component {
  /* <BrowserRouter>
  <ConnectedRouter history={history}> */
  render() {
    return (

      <Switch>
        <Route path='/diary/favourites' ><FavouritesPage /></Route>
        <Route path='/diary/visits' ><VisitsPage /></Route>
        <Route path='/diary/ratings' ><RatingsPage /></Route>
        <Route path='/diary/comments' ><DiaryCommentsPage /></Route>
        <Route path='/diary/records' ><DiaryRecordsPage /></Route>
        <Route path='/diary' ><DiaryOverViewPage /></Route>
      </Switch>
    );
  }
  /* </ConnectedRouter> </BrowserRouter> */
}

export default DiaryComponent;
