import {
    RECORD_FAVOURITE_TOGGLE_LOADING,
    RECORD_FAVOURITE_TOGGLE_SUCCESS,
    RECORD_FAVOURITE_TOGGLE_ERRORED,
} from './recordFavouriteActionTypes';

import { DriversRoadsApi, jsonAPIAuthorizedRequest, onApiError } from '../../api/apiHelper';

function recordFavouriteSuccess(recordId, numberOfFavourites, userFavourite) {
    return {
        type: RECORD_FAVOURITE_TOGGLE_SUCCESS,
        recordId,
        numberOfFavourites,
        userFavourite
    };
}

function recordFavouriteError(recordId) {
    return {
        type: RECORD_FAVOURITE_TOGGLE_ERRORED,
        recordId
    };
}

function recordFavouriteProcessing(recordId) {
    return {
        type: RECORD_FAVOURITE_TOGGLE_LOADING,
        recordId
    };
}

export function toggleRecordFavourite(recordId, Favourited) {
    return (dispatch) => {
        dispatch(recordFavouriteProcessing(recordId));
        let options = jsonAPIAuthorizedRequest("POST");
        var url = '';
        if (Favourited) {
            url = DriversRoadsApi + 'api/record/favourite/remove/' + recordId;
        } else {
            url = DriversRoadsApi + 'api/record/favourite/add/' + recordId;
        }

        fetch(url, options)
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => response.json())
            .then((result) => {
                dispatch(recordFavouriteSuccess(recordId, result.count, result.user));
            })
            .catch((e) => {
                onApiError(e);
                dispatch(recordFavouriteError(recordId));
            });
    };
}
