import { DriversRoadsApi } from './apiHelper';
import { PhotoUrl } from '../configuration';

class PhotoUrlPathHelper {

    static getSeasonPartnerPhotoUrl(seasonId, fileName) {
        return `${PhotoUrl}season-${seasonId}/partners/${fileName}`;
    }

    static getPhotoUrl(seasonId, tripId, stageId, photoName) {
        return `${PhotoUrl}season-${seasonId}/trip-${tripId}/stage-${stageId}/${photoName}`;
    }
  
    static getStagePhotoThumbUrl(seasonId, tripId, stageId, photoName) {
        let split = photoName.split(".");
        return `${PhotoUrl}season-${seasonId}/trip-${tripId}/stage-${stageId}/${split[0]}-trip-thumb.${split[1]}`;
    }

    static getRecordThumbFromRecord(photoId, recordId) {
        if (photoId == null) {
            return "http://via.placeholder.com/100x100"
        }
        else {
            return this.getRecordGalThumb(photoId, recordId);
        }
    }

    static getRecordOrig(photoId, recordId) {
        let path = 'record/' + recordId + '/';
        let size = '?size=orig';
        return API_URL + path + photoId + size;
    }

    static getPrivateRecordOrig(photoId) {
        let path = 'private/';
        let size = '?size=orig';

        return API_URL + path + photoId + size;
    }

    static getRecordGalThumb(photoId, recordId) {
        let path = 'record/' + recordId + '/';
        let sizeQuery = '?size=record-thumb';

        if (typeof photoId !== "string") {
            photoId = photoId.toString()
        }
        let indexOfReload = photoId.indexOf('?');
        if (indexOfReload > -1) {
            let reloadId = photoId.substring(indexOfReload + 1, photoId.length)
            let id = photoId.substring(0, indexOfReload);
            return API_URL + path + id + sizeQuery + '&reload=' + reloadId;
        }

        return API_URL + path + photoId + sizeQuery;
    }

    static getPrivateCommentGalThumb(photoId) {
        let path = 'private/';
        let sizeQuery = '?size=comment-thumb';

        if (typeof photoId !== "string") {
            photoId = photoId.toString()
        }
        let indexOfReload = photoId.indexOf('?');
        if (indexOfReload > -1) {
            let reloadId = photoId.substring(indexOfReload + 1, photoId.length)
            let id = photoId.substring(0, indexOfReload);
            return API_URL + path + id + sizeQuery + '&reload=' + reloadId;
        }

        return API_URL + path + photoId + sizeQuery;
    }

    static getPrivateCommentGalOrig(photoId) {
        let path = 'private/';
        let sizeQuery = '?size=orig';

        if (typeof photoId !== "string") {
            photoId = photoId.toString()
        }
        let indexOfReload = photoId.indexOf('?');
        if (indexOfReload > -1) {
            let reloadId = photoId.substring(indexOfReload + 1, photoId.length)
            let id = photoId.substring(0, indexOfReload);
            return API_URL + path + id + sizeQuery + '&reload=' + reloadId;
        }

        return API_URL + path + photoId + sizeQuery;
    }

    static getPrivateRecordGalThumb(photoId) {
        let path = 'private/';
        let sizeQuery = '?size=record-thumb';

        if (typeof photoId !== "string") {
            photoId = photoId.toString()
        }
        let indexOfReload = photoId.indexOf('?');
        if (indexOfReload > -1) {
            let reloadId = photoId.substring(indexOfReload + 1, photoId.length)
            let id = photoId.substring(0, indexOfReload);
            return API_URL + path + id + sizeQuery + '&reload=' + reloadId;
        }

        return API_URL + path + photoId + sizeQuery;
    }

    static getCommentOrig(photoId, commentId) {
        let path = 'comment/' + commentId + '/';
        let size = '?size=orig';
        return API_URL + path + photoId + size;
    }

    static getCommentThumb(photoId, commentId) {
        let path = 'comment/' + commentId + '/';
        let size = '?size=comment-thumb';
        return API_URL + path + photoId + size;
    }

    static getCommentGalleryPhoto(photoId, commentId) {
        let path = 'comment/' + commentId + '/';
        let size = '?size=gall-thumb';
        return API_URL + path + photoId + size;
    }
}

export const API_URL = DriversRoadsApi + 'api/photo/';

export default PhotoUrlPathHelper;
