import { unionBy } from 'lodash';
import { combineReducers } from 'redux';

import {
    ATLAS_POINTS_FETCH_LOADING,
    ATLAS_POINTS_FETCH_SUCCESS,
    ATLAS_POINTS_FETCH_ERRORED,

    ATLAS_DETAILS_FETCH_LOADING,
    ATLAS_DETAILS_FETCH_SUCCESS,
    ATLAS_DETAILS_FETCH_ERRORED,

    ATLAS_DETAILS_FETCH_PAGE_SUCCESS,
} from '../actions/atlasActionTypes';


function pointsLoaded(state = [], action) {
    switch (action.type) {
        case ATLAS_POINTS_FETCH_SUCCESS:
            if (action.reset) {
                return action.points;
            }
            return unionBy(state, action.points, 'recordId');
        default:
            return state;
    }
}

function pointsLoading(state = false, action) {
    switch (action.type) {
        case ATLAS_POINTS_FETCH_LOADING:
            return true;
        case ATLAS_POINTS_FETCH_ERRORED:
        case ATLAS_POINTS_FETCH_SUCCESS:
            return false;
        default:
            return state;
    }
}

function pointsErrored(state = false, action) {
    switch (action.type) {
        case ATLAS_POINTS_FETCH_ERRORED:
            return true;
        case ATLAS_POINTS_FETCH_LOADING:
        case ATLAS_POINTS_FETCH_SUCCESS:
            return false;
        default:
            return state;
    }
}

const defaultPagination = {
    page: 1,
    itemsOnPage: 5
};

function detailsPaginationLoaded(state = defaultPagination, action) {
    switch (action.type) {
        case ATLAS_DETAILS_FETCH_SUCCESS:
        case ATLAS_DETAILS_FETCH_PAGE_SUCCESS:
            return action.details.pagination;
        default:
            return state;
    }
}

function detailsLoaded(state = [], action) {
    switch (action.type) {
        case ATLAS_DETAILS_FETCH_PAGE_SUCCESS:
            return [
                ...state,
                ...action.details.items
            ];
        case ATLAS_DETAILS_FETCH_SUCCESS:
            return action.details.items;
        default:
            return state;
    }
}

function detailsLoading(state = false, action) {
    switch (action.type) {
        case ATLAS_DETAILS_FETCH_LOADING:
            return true;
        case ATLAS_DETAILS_FETCH_ERRORED:
        case ATLAS_DETAILS_FETCH_SUCCESS:
            return false;
        default:
            return state;
    }
}

function detailsErrored(state = false, action) {
    switch (action.type) {
        case ATLAS_DETAILS_FETCH_ERRORED:
            return true;
        case ATLAS_DETAILS_FETCH_LOADING:
        case ATLAS_DETAILS_FETCH_SUCCESS:
            return false;
        default:
            return state;
    }
}

const atlasReducer = combineReducers({
    points: combineReducers({
        points: pointsLoaded,
        loading: pointsLoading,
        errored: pointsErrored
    }),
    details: combineReducers({
        details: detailsLoaded,
        pagination: detailsPaginationLoaded,
        loading: detailsLoading,
        errored: detailsErrored
    })
});

export default atlasReducer;