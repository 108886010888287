import React from 'react';

const AddPlaceGuidePanel = ({ selectingStart, startLocation, name, description }) => {

    let selectingClass = '';
    if (selectingStart || startLocation !== '') {
        selectingClass = 'font-weight-bold';
    }

    let startClass;
    if (startLocation !== '') {
        startClass = 'font-weight-bold';
    }

    let nameClass;
    if (name !== '') {
        nameClass = 'font-weight-bold';
    }

    let descriptionClass;
    if (description !== '') {
        descriptionClass = 'font-weight-bold';
    }

    return <div className="alert alert-primary add-guide">
        <div className="collapse" id="guideCollapse" aria-expanded="false">
            <ol>
                <li className={selectingClass}>Klikněte na tlačítko pro výběr Místa</li>
                <li className={startClass}>Následně klikněte do mapy, kam chcete místo přidat</li>
                <li>Posouváním můžete upravit polohu místa</li>
                <li className={nameClass}>Vyplňte název místa</li>
                <li className={descriptionClass}>Popište místo - proč a kdy sem zajet</li>
                <li>Zvolte, zda je záznam veřejný nebo soukromý. Soukromý záznam ostatní neuvidí</li>
                <li>Klikněte Uložit</li>
            </ol>
            <p>
                Prosíme nepřidávejte komerční místa. Vhodná místa jsou parkoviště, letiště, plácky, místa pro fotku, vyhlídky nebo rozhledny.
            </p>
        </div>
        <div role="button" className="pointer collapsed text-center" data-toggle="collapse" data-target="#guideCollapse" aria-expanded="false" aria-controls="guideCollapse">
            Návod <i className="fa"></i></div>
    </div >
};

export default AddPlaceGuidePanel;