import {
    USER_SUCCESS,
    USER_IS_LOADING,
    USER_ERRORED
} from './userActionTypes';

import { DriversRoadsApi, jsonAPIAuthorizedRequest, onApiError } from '../api/apiHelper';

export function userSuccess(user) {
    return {
        type: USER_SUCCESS,
        user
    };
}

export function userIsLoading() {
    return {
        type: USER_IS_LOADING
    };
}

export function userErrored() {
    return {
        type: USER_ERRORED
    };
}

export function loadUser() {
    return (dispatch) => {
        dispatch(userIsLoading());

        const url = DriversRoadsApi + "api/autozka-user/update";
        var options = jsonAPIAuthorizedRequest("POST");

        fetch(url, options)
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => response.json())
            .then((user) => {
                dispatch(userSuccess(user));
            })
            .catch((e) => {
                onApiError(e);
                dispatch(userErrored());
            });
    };
};