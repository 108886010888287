import React from 'react';

const AddRoadGuidePanel = ({ selectingStart, selectingFinish, startLocation, destLocation, name, description }) => {
    let startSelectingClass = '';
    if (selectingStart || startLocation !== '') {
        startSelectingClass = 'font-weight-bold';
    }

    let startClass;
    if (startLocation !== '') {
        startClass = 'font-weight-bold';
    }

    let destSelectingClass = '';
    if (selectingFinish || destLocation !== '') {
        destSelectingClass = 'font-weight-bold';
    }

    let destClass;
    if (destLocation !== '') {
        destClass = 'font-weight-bold';
    }

    let nameClass;
    if (name !== '') {
        nameClass = 'font-weight-bold';
    }

    let descriptionClass;
    if (description !== '') {
        descriptionClass = 'font-weight-bold';
    }

    return <div className="alert alert-primary add-guide">
        <div className="collapse" id="guideCollapse" aria-expanded="false">
            <ol>
                <li className={startSelectingClass}>Klikněte na tlačítko pro výběr Startu</li>
                <li className={startClass}>Následně klikněte do mapy, odkud má trasa začínat</li>
                <li className={destSelectingClass}>Klikněte na tlačítko pro výběr Cíle</li>
                <li className={destClass}>Následně klikněte do mapy, kde má trasa skončit</li>
                <li>Posuvem můžete upravit start a cíl, nebo tažením upravit, kudy trasa prochází</li>
                <li className={nameClass}>Vyplňte název cesty - silnice</li>
                <li className={descriptionClass}>Popište cestu - jaký je zde asfalt, provoz a pro jaká auta se hodí</li>
                <li>Zvolte, zda je záznam veřejný nebo soukromý. Soukromý záznam ostatní neuvidí.</li>
                <li>Klikněte Uložit</li>
            </ol>
        </div>
        <div role="button" className="pointer collapsed text-center" data-toggle="collapse" data-target="#guideCollapse" aria-expanded="false" aria-controls="guideCollapse">
            Návod <i className="fa"></i></div>
    </div >
};

export default AddRoadGuidePanel;