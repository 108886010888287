import {
    RECORD_PHOTO_ADD_LOADING,
    RECORD_PHOTO_ADD_SUCCESS,
    RECORD_PHOTO_ADD_ERRROED,

    RECORD_PHOTO_DELETE_LOADING,
    RECORD_PHOTO_DELETE_SUCCESS,
    RECORD_PHOTO_DELETE_ERRROED
} from './recordPhotoActionTypes';

import { DriversRoadsApi, jsonAPIAuthorizedRequest, formAPIAuthorizedRequest, onApiError } from '../../api/apiHelper';
import { generateChars } from '../../utils/randomHelper';

function recordPhotoAdded(recordId, photoId, loadingPhotoId) {
    return {
        type: RECORD_PHOTO_ADD_SUCCESS,
        recordId,
        photoId,
        loadingPhotoId
    };
}

function recordPhotoAddLoading(recordId, loadingPhotoId) {
    return {
        type: RECORD_PHOTO_ADD_LOADING,
        recordId,
        loadingPhotoId
    };
}

function recordPhotoAddError(recordId, loadingPhotoId) {
    return {
        type: RECORD_PHOTO_ADD_ERRROED,
        recordId,
        loadingPhotoId
    };
}

export function addRecordPhoto(recordId, photo) {
    return (dispatch) => {

        let loadingPhotoId = generateChars(3);
        dispatch(recordPhotoAddLoading(recordId, loadingPhotoId));

        var formData = new FormData();
        formData.append('file', photo);

        let options = formAPIAuthorizedRequest("POST", formData);

        fetch(DriversRoadsApi + 'api/photo/add/record/' + recordId, options)
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => response.json())
            .then((photoId) => dispatch(recordPhotoAdded(recordId, photoId, loadingPhotoId)))
            .catch((e) => {
                onApiError(e);
                dispatch(recordPhotoAddError(recordId, loadingPhotoId));
            });
    };
}

function recordPhotoDeleted(recordId, photoId) {
    return {
        type: RECORD_PHOTO_DELETE_SUCCESS,
        recordId,
        photoId
    };
}

function recordPhotoDeleting(recordId, photoId) {
    return {
        type: RECORD_PHOTO_DELETE_LOADING,
        recordId,
        photoId
    };
}

function recordPhotoDeleteError(recordId, photoId) {
    return {
        type: RECORD_PHOTO_DELETE_ERRROED,
        recordId,
        photoId
    };
}

export function deleteRecordPhoto(recordId, photoId, isPrivate) {
    return (dispatch) => {

        dispatch(recordPhotoDeleting(recordId, photoId));
        let options = jsonAPIAuthorizedRequest("POST");

        let url = '';
        if (isPrivate) {
            url = DriversRoadsApi + 'api/photo/delete/record/private/' + recordId + "?token=" + photoId;
        } else {
            url = DriversRoadsApi + 'api/photo/delete/record/' + recordId + "?photoId=" + photoId;
        }

        fetch(url, options)
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then(() => dispatch(recordPhotoDeleted(recordId, photoId)))
            .catch((e) => {
                onApiError(e);
                dispatch(recordPhotoDeleteError(recordId, photoId));
            });
    };
}