import {
    COMMENT_PHOTO_ADD_LOADING,
    COMMENT_PHOTO_ADD_SUCCESS,
    COMMENT_PHOTO_ADD_ERRROED,

    COMMENT_PHOTO_DELETE_LOADING,
    COMMENT_PHOTO_DELETE_SUCCESS,
    COMMENT_PHOTO_DELETE_ERRROED
} from './commentPhotoActionTypes';

import { DriversRoadsApi, jsonAPIAuthorizedRequest, formAPIAuthorizedRequest, onApiError } from '../../api/apiHelper';
import { generateChars } from '../../utils/randomHelper';

function commentPhotoAdded(commentId, photoId, loadingPhotoId) {
    return {
        type: COMMENT_PHOTO_ADD_SUCCESS,
        commentId,
        photoId,
        loadingPhotoId
    };
}

function commentPhotoAddLoading(commentId, loadingPhotoId) {
    return {
        type: COMMENT_PHOTO_ADD_LOADING,
        commentId,
        loadingPhotoId
    };
}

function commentPhotoAddError(commentId, loadingPhotoId) {
    return {
        type: COMMENT_PHOTO_ADD_ERRROED,
        commentId,
        loadingPhotoId
    };
}

export function addCommentPhoto(commentId, photo) {
    return (dispatch) => {

        let loadingPhotoId = generateChars(3);
        dispatch(commentPhotoAddLoading(commentId, loadingPhotoId));

        var formData = new FormData();
        formData.append('file', photo);

        let options = formAPIAuthorizedRequest("POST", formData);

        fetch(DriversRoadsApi + 'api/photo/add/comment/' + commentId, options)
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => response.json())
            .then((photoId) => dispatch(commentPhotoAdded(commentId, photoId, loadingPhotoId)))
            .catch((e) => {
                onApiError(e);
                dispatch(commentPhotoAddError(commentId, loadingPhotoId));
            });
    };
}

function commentPhotoDeleted(commentId, photoId) {
    return {
        type: COMMENT_PHOTO_DELETE_SUCCESS,
        commentId,
        photoId
    };
}

function commentPhotoDeleting(commentId, photoId) {
    return {
        type: COMMENT_PHOTO_DELETE_LOADING,
        commentId,
        photoId
    };
}

function commentPhotoDeleteError(commentId, photoId) {
    return {
        type: COMMENT_PHOTO_DELETE_ERRROED,
        commentId,
        photoId
    };
}

export function deleteCommentPhoto(commentId, photoId, isPrivate) {
    return (dispatch) => {

        debugger;
        dispatch(commentPhotoDeleting(commentId, photoId));
        let options = jsonAPIAuthorizedRequest("POST");

        let url = '';
        if (isPrivate) {
            url = DriversRoadsApi + 'api/photo/delete/comment/private/' + commentId + "?token=" + photoId;
        } else {
            url = DriversRoadsApi + 'api/photo/delete/comment/' + commentId + "?photoId=" + photoId;
        }

        fetch(url, options)
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then(() => dispatch(commentPhotoDeleted(commentId, photoId)))
            .catch((e) => {
                onApiError(e);
                dispatch(commentPhotoDeleteError(commentId, photoId));
            });
    };
}