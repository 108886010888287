import React from 'react';
import toastr from 'toastr';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import LoadingComponent from '../common/LoadingComponent';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faShieldAlt, faGlobe, faTimesCircle, faCheckCircle, faPen } from '@fortawesome/free-solid-svg-icons';

import { addRecordPhoto } from '../../actions/record/recordPhotoActionCreators';
import { editRecordDescription } from '../../actions/record/recordEditActionCreators';
import { deleteRecord } from '../../actions/record/recordOwnerActionCreators';
import AddPhotoButton from '../common/AddPhotoButton';

class RecordDescriptionPanel extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            editing: false,
            text: ''
        };

        this.onEditClick = this.onEditClick.bind(this);
        this.onEditDoneClick = this.onEditDoneClick.bind(this);
        this.onEditCancelClick = this.onEditCancelClick.bind(this);

        this.deleteRecord = this.deleteRecord.bind(this);
        this.addRecordPhoto = this.addRecordPhoto.bind(this);

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.record.isDeleted &&
            this.props.record.isDeleted !== prevProps.record.isDeleted) {
            toastr.info('Záznam byl smazán');
            this.props.history.push('/diary');
        }
    }

    addRecordPhoto(file) {
        this.props.addRecordPhoto(this.props.record.id, file);
    }

    deleteRecord() {
        var result = window.confirm("Opravdu chcete záznam smazat?");
        if (result == true) {
            this.props.deleteRecord(this.props.record.id);
        }
    }

    onEditClick() {
        this.setState({
            editing: true,
            text: this.props.record.description
        });
    }

    onEditCancelClick() {
        this.setState({
            editing: false,
            text: this.props.record.description
        });
    }

    onEditDoneClick() {
        this.setState({
            editing: false
        });
        this.props.editDescription(this.props.record.id, this.state.text);
    }

    handleChange(event) {
        this.setState({ text: event.target.value });
    }

    render() {
        let isOwner = this.props.user && this.props.record.creatorId.toUpperCase() === this.props.user.profile.sub.toUpperCase();

        let accessIcon = this.props.record.isPrivate ?
            <FontAwesomeIcon icon={faShieldAlt} /> :
            <FontAwesomeIcon icon={faGlobe} />
        let accessText = this.props.record.isPrivate ? 'Soukromé' : 'Veřejné';

        return (
            <div>
                {this.props.record.descriptionEditLoading ?
                    <LoadingComponent /> :
                    this.state.editing ?
                        <textarea className='form-control lead' value={this.state.text} onChange={this.handleChange} /> :
                        <p className='lead'>{this.props.record.description} </p>
                }
                {isOwner &&
                    <div className="row text-center">
                        <div className="col-sm-3">
                            <div>
                                {this.state.editing ?
                                    <div style={{ display: 'inline-block' }}>
                                        <FontAwesomeIcon className='pointer' icon={faCheckCircle} onClick={() => this.onEditDoneClick()} /> &nbsp;
                                    <FontAwesomeIcon className='pointer' icon={faTimesCircle} onClick={() => this.onEditCancelClick()} />
                                    </div> :
                                    <FontAwesomeIcon className='pointer' icon={faPen} onClick={() => this.onEditClick()} />
                                }
                            </div>
                            Upravit popis
                        </div>

                        <div className="col-sm-3">
                            <AddPhotoButton
                                addPhoto={this.addRecordPhoto}
                                currentPhotos={this.props.record.photoIds.length}
                                maxPhotos={11} />
                        </div>

                        <div className="col-sm-3">
                            <div>
                                {accessIcon}
                            </div>
                            {accessText}
                        </div>

                        <div className="col-sm-3" >
                            <div className='pointer' onClick={() => this.deleteRecord()}>
                                <div>
                                    <FontAwesomeIcon icon={faTrash} />
                                </div>
                                Smazat
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        deleteRecord: (id) => dispatch(deleteRecord(id)),
        // makeRecordPrivate: (id) => dispatch(makeRecordPrivate(id)),
        // makeRecordPublic: (id, ) => dispatch(makeRecordPublic(id)),
        addRecordPhoto: (recordId, file) => dispatch(addRecordPhoto(recordId, file)),
        editDescription: (id, text) => dispatch(editRecordDescription(id, text))
    };
};


export default withRouter(connect(null, mapDispatchToProps)(RecordDescriptionPanel));

