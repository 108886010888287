import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import PhotoUrlPathHelper from '../../api/PhotoUrlPathHelper';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment } from '@fortawesome/free-solid-svg-icons';
import { faPlace } from '../../icons/place';
import { faRoad } from '../../icons/road';

import UserRating from '../record/helper/UserRating';
import TotalRating from '../record/helper/TotalRating';
import Favourites from '../record/helper/Favourites';
import Visits from '../record/helper/Visits';

class DatabaseRecordItem extends React.Component {
  render() {
    let imageSrc = PhotoUrlPathHelper.getRecordThumbFromRecord(this.props.photoId, this.props.id);

    let url = '';
    if (this.props.recordType === 1) {
      url = "/place/" + this.props.id;
    } else {
      url = "/road/" + this.props.id;
    }

    return (
      <tr className='database-record'>
        <td>
          <figure className="pull-left">
            <NavLink to={url}>
              <img className="media-object img-rounded img-responsive" src={imageSrc} width={200} height={200} alt="placehold.it/350x250" />
            </NavLink>
          </figure>
        </td>
        <td>
          {this.props.recordType === 1 ?
            <NavLink to={"/place/" + this.props.id}><h4 className="list-group-item-heading">
              <FontAwesomeIcon icon={faPlace} />{this.props.name} </h4> </NavLink> :
            <div>
              <NavLink to={"/road/" + this.props.id}><h4 className="list-group-item-heading">
                <FontAwesomeIcon icon={faRoad} />{this.props.name} </h4> </NavLink>
              {Math.round(this.props.distance / 100.0) / 10 + " km"}
            </div>
          }
          {this.props.startAddress}
          <br></br>
          {/* MOMENT JS removed - add time helper */}
          {/* {created.locale('cs').format('D. M. YYYY, H:mm:ss')} */}
        </td>
        <td>
          <Favourites recordId={this.props.id}
            loading={this.props.favouriteLoading}
            userFavourite={this.props.userFavourite}
            numberOfFavourites={this.props.numberOfFavourites}
            enabled={this.props.logged}
            onClick={this.props.onFavouriteClick} />
        </td>
        <td>
          <Visits recordId={this.props.id}
            loading={this.props.visitLoading}
            userVisited={this.props.userVisit}
            numberOfVisits={this.props.numberOfVisits}
            enabled={this.props.logged}
            onClick={this.props.onVisitClick} />
        </td>
        <td>
          <FontAwesomeIcon icon={faComment} title='Komentáře' />
          <div>{this.props.numberOfComments}</div>
        </td>
        <td>
          <TotalRating totalRating={this.props.rating} />
          Celkově {Math.round(this.props.rating * 100) / 100} x{this.props.numberOfRatings}
        </td>
        {
          this.props.logged && <td>
            <UserRating recordId={this.props.id}
              loading={this.props.ratingLoading}
              userRating={this.props.userRating}
              enabled={this.props.logged}
              onRatingClick={this.props.onRatingClick} />
            Tvoje hodnocení
        </td>
        }
      </tr >
    );
  }
}

DatabaseRecordItem.propTypes = {
  rating: PropTypes.number.isRequired,
  numberOfRatings: PropTypes.number.isRequired,
  distance: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired
};

export default DatabaseRecordItem;
