import React from 'react';
import RecordTypeItem from './RecordTypeItem';

const RecordTypeGroup = ({ recordType, onChange }) => {
    return (
        <div className="btn-group btn-group-toggle m-2">
            <RecordTypeItem text={'Místa'}
                name={'places_radio'}
                value={'places'}
                onChange={onChange}
                checked={recordType === 'places'} />
            <RecordTypeItem
                text={'Cesty'}
                ame={'roads_radio'}
                value={'roads'}
                onChange={onChange}
                checked={recordType === 'roads'} />
            <RecordTypeItem
                text={'Vše'}
                name={'all_radio'}
                value={'all'}
                onChange={onChange}
                checked={recordType === 'all'} />
        </div>
    );
}

export default RecordTypeGroup;