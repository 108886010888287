import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWheel as selectedWheel } from '../../../icons/wheel';
import { faWheelOutline as unselectedWheel } from '../../../icons/wheel-outline';

import LoadingComponent from '../../common/LoadingComponent';

const Visits = ({ enabled, recordId, loading, userVisited, numberOfVisits, onClick }) => {
    if (loading) {
        return (<LoadingComponent />);
    }

    if (!enabled) {
        return (
            <div> <FontAwesomeIcon icon={unselectedWheel} title='Návštevy' />
                <div> {numberOfVisits}</div>
            </div>
        );
    }

    let style = { cursor: 'pointer' };

    return (
        <div className='user-interaction' onClick={() => onClick(recordId, userVisited)}> {userVisited ?
            <FontAwesomeIcon icon={selectedWheel} title='Návštevy' /> :
            <FontAwesomeIcon icon={unselectedWheel} title='Návštevy' />}
            <div> {numberOfVisits}</div>
        </div>
    );
}

export default Visits;