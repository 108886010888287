import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

const MapEditButton = ({ onEditClick, onDoneClick, onCancelClick, editing }) => {
    const className = "btn btn-outline-primary map-button text-white m-2";

    return (
        <div id='edit-map-button'>
            {editing &&
                <button className={className} title='Uložit' onClick={() => onDoneClick()}>
                    <FontAwesomeIcon icon={faCheck} />
                </button>}
            {editing &&
                <button className={className} title='Konec' onClick={() => onCancelClick()}>
                    <FontAwesomeIcon icon={faTimes} />
                </button>}
            {!editing &&
                <button className={className} title='Upravit' onClick={() => onEditClick()}>
                    <FontAwesomeIcon icon={faPen} />
                </button>}
        </div>
    );
}

export default MapEditButton;