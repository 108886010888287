/*global google*/
import React from 'react';
import withClusterMap from '../hoc/withClusterMap';
import MarkerClusterer from '@google/markerclustererplus';

import AddRecordPanel from './panels/AddRecordPanel';
import RecordPreviewPanel from './panels/RecordPreviewPanel';
import LocalePanel from './panels/LocalePanel';
import ToAtlasItems from './panels/ToAtlasItems';

import { CreateAtlasMap } from './MapConfiguration';
import LoadingComponent from '../common/LoadingComponent';
import LocationHelper from '../../utils/LocationHelper';
import MapToUrlHelper from './MapToUrlHelper';
import MapUserPositionHelper from './PositionHelper';

class ClusterMap extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            selectedRecordId: undefined,
            infoRecordUrl: '',
            infoRecordText: '',
            infoRecordType: 0
        }

        this.worldBounds = {
            maxLat: 72,
            minLat: -57,
            maxLng: 180,
            minLng: -180
        };

        this.directionsDisplay = null;
        this.infowindow = null;
        this.map = null;
        this.markers = [];
        this.selectedMarker = null;
        this.cluster = null;
        this.positionHelper = null;

        this.mapMarker = this.mapMarker.bind(this);
        this.onMarkerClick = this.onMarkerClick.bind(this);
        this.drawSelectedRoad = this.drawSelectedRoad.bind(this);
        this.drawSelectedPlace = this.drawSelectedPlace.bind(this);
        this.onInitLoad = this.onInitLoad.bind(this);
        this.closePreview = this.closePreview.bind(this);
        this.onLocationChange = this.onLocationChange.bind(this);
        this.onLocateMeSuccess = this.onLocateMeSuccess.bind(this);
        this.onLocateMeError = this.onLocateMeError.bind(this);
        this.changeBounds = this.changeBounds.bind(this);

        this.panPlace = this.panPlace.bind(this);
        this.panRoad = this.panRoad.bind(this);
    }

    changeBounds() {
        let bounds = this.map.getBounds();
        if (!bounds || bounds === null) {
            return;
        }

        let ne = bounds.getNorthEast();
        let sw = bounds.getSouthWest();

        let map = {
            minLatitude: sw.lat(),
            maxLatitude: ne.lat(),
            minLongitude: sw.lng(),
            maxLongitude: ne.lng()
        }

        let isOk = map.minLongitude <= this.worldBounds.maxLng &&
            map.maxLongitude >= this.worldBounds.minLng &&
            map.minLatitude <= this.worldBounds.maxLat &&
            map.maxLatitude >= this.worldBounds.minLat;

        if (isOk) {
            this.props.changeBounds(map);
        }
    }

    onLocationChange(location) {
        this.map.panTo({ lat: location.lat, lng: location.lng });
        this.changeBounds();
    }

    onLocateMeSuccess(gps) {
        this.map.setCenter({ lat: gps.lat, lng: gps.lng });
        this.positionHelper.updateUserPosition(gps);
        google.maps.event.addListenerOnce(this.map, 'bounds_changed', this.changeBounds);
    }

    onLocateMeError() {
        this.map.setCenter({ lat: 50, lng: 14 });
        this.positionHelper.invalidateUserPosition();
        google.maps.event.addListenerOnce(this.map, 'bounds_changed', this.changeBounds);
    }

    onInitLoad() {
        this.positionHelper = new MapUserPositionHelper(this.map);
        this.positionHelper.startWatching();
        LocationHelper.locateGPS(this.onLocateMeSuccess, this.onLocateMeError);
    }

    panPlace() {
        let zoom = this.map.getZoom();
        if (zoom < 11) {
            this.map.setZoom(11);
        }
        this.map.panTo(this.selectedMarker.position);
    }

    panRoad() {
        this.map.fitBounds(this.directions.routes[0].bounds);
    }

    closePreview() {
        if (this.selectedMarker) {
            if (this.selectedMarker.type === 1) {
                this.selectedMarker.setIcon({ url: '/img/place-marker.png' });
            }

            this.selectedMarker.setVisible(true);
            this.setState({ selectedRecordId: undefined });
            this.props.selectRoad(undefined);
            this.selectedMarker = null;
        }

        if (this.directionsDisplay) {
            this.directionsDisplay.setDirections({ routes: [] });
        }
    }

    onMarkerClick(recordId, marker) {
        //Cant click on already selected marker
        if (this.selectedMarker && recordId === this.selectedMarker.recordId) {
            return;
        }

        //Reset old selected marker
        if (this.selectedMarker) {
            this.selectedMarker.setVisible(true);
            if (this.selectedMarker.type === 1) {
                this.selectedMarker.setIcon({ url: '/img/place-marker.png' });
            }
            if (this.directionsDisplay) {
                this.directionsDisplay.setDirections({ routes: [] });
            }
        }

        this.selectedMarker = marker;
        this.setState({ selectedRecordId: recordId });
        this.props.selectRoad(recordId);
    }

    mapMarker(point) {
        let marker = new google.maps.Marker({
            position: {
                lat: parseFloat(point.lat),
                lng: parseFloat(point.lng)
            },
            type: point.recordType,
            map: this.map,
            name: point.name
        });
        this.markers.push(marker);

        if (point.recordType === 1) {
            marker.setIcon({ url: '/img/place-marker.png' });
        }
        else {
            marker.setIcon({ url: '/img/road-marker.png' });
        }
        marker.recordId = point.recordId;
        marker.addListener('click', () => this.onMarkerClick(point.recordId, marker));

        //recreate selected point
        if (this.selectedMarker && point.recordId === this.selectedMarker.recordId) {
            if (point.recordType === 1) {
                marker.setIcon({ url: '/img/place-marker-red.png' });
            }
            else {
                marker.setVisible(false);
            }
            this.selectedMarker = marker;
        }

        return marker;
    }

    makeMarkers(data) {
        if (this.markers) {
            if (this.cluster) {
                this.cluster.removeMarkers(this.markers);
            }
            this.markers.forEach(o => o.setMap(null));
            this.markers = [];
        }
        data.forEach((location, i) => this.mapMarker(location, i));
        this.cluster = new MarkerClusterer(this.map, this.markers, {
            maxZoom: 11
        });
    }

    drawSelectedRoad(data) {
        let directions = MapToUrlHelper.toGoogleDirections(data.directionsResult);
        this.directions = directions;
        this.selectedMarker.setVisible(false);
        this.directionsDisplay.setDirections(directions);
    }

    drawSelectedPlace(data) {
        this.selectedMarker.setIcon({ url: '/img/place-marker-red.png' });
    }

    componentDidUpdate(prevProps) {

        let oldFilter = JSON.stringify(this.filter);
        let newFilter = JSON.stringify(this.props.filter);
        if (oldFilter !== newFilter) {
            this.filter = { ...this.props.filter };
            if (this.markers.length !== 0) {
                console.log('fitler changed - removing: ' + this.markers.length);
                this.cluster.removeMarkers(this.markers);
                this.markers.forEach(o => o.setMap(null));
                this.markers = [];
            }
            return;
        }

        if (this.props.records.length !== prevProps.records.length || this.markers.length !== prevProps.records.length) {
            this.cluster.removeMarkers(this.markers);
            console.log('removing: ' + this.markers.length);
            this.markers.forEach(o => o.setMap(null));
            this.markers = [];
            console.log('creating: ' + this.props.records.length);
            this.makeMarkers(this.props.records);
        }

        if (this.props.selectedRecordId !== prevProps.selectedRecordId) {
            let marker = this.markers.find((marker) => {
                return marker.recordId === this.props.selectedRecordId;
            });
            if (marker !== undefined) {
                this.onMarkerClick(marker.recordId, marker);
            }
        }
    }

    componentWillUnmount() {
        if (this.cluster) {
            this.cluster.removeMarkers(this.markers);
        }
        this.markers.forEach(o => o.setMap(null));
        this.markers = [];

        this.positionHelper.clearWatch();
    }

    componentDidMount() {
        this.infowindow = new google.maps.InfoWindow();
        this.directionsDisplay = new google.maps.DirectionsRenderer(
            {
                draggable: false,
                preserveViewport: true
            });

        this.map = CreateAtlasMap(this.props.config.mapId);
        this.cluster = new MarkerClusterer(this.map, [], {
            maxZoom: 11
        });

        google.maps.event.addListenerOnce(this.map, 'idle', this.onInitLoad);
        this.map.addListener('dragend', this.changeBounds);
        this.map.addListener('bounds_changed', this.changeBounds);
        this.map.addListener('zoom_changed', this.changeBounds);

        this.directionsDisplay.setMap(this.map);

        if (this.props.config.recordAddEnabled) {
            var addMapButton = document.getElementById('add-map-button');
            addMapButton.index = 1;
            this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(addMapButton);
        }

        if (this.props.config.locateMeEnabled) {
            var localeMapButton = document.getElementById('locale-map-button');
            localeMapButton.index = 1;
            this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(localeMapButton);
        }

        var scrollMapbutton = document.getElementById('scroll-map-button');
        scrollMapbutton.index = 1;
        this.map.controls[google.maps.ControlPosition.RIGHT_CENTER].push(scrollMapbutton);

        var infoWindow = document.getElementById('infoWindow');
        infoWindow.index = 1;
        this.map.controls[google.maps.ControlPosition.LEFT_TOP].push(infoWindow);
    }

    render() {
        return (
            <div id="wrapper">
                <div style={{ visibility: 'collapse', position: 'absolute' }}>
                    {this.props.config.recordAddEnabled ? <div><AddRecordPanel /></div> : null}

                    {this.props.config.recordAddEnabled ? <div><LocalePanel onLocationChange={this.onLocationChange} /></div> : null}

                    {<div><ToAtlasItems /></div>}
                </div>
                <div id={this.props.config.mapId}>
                </div>

                {this.props.loading || this.props.init ?
                    <div id="over_map">
                        <div className='vertical-center-div'>
                            <LoadingComponent />
                        </div>
                    </div> :
                    <div></div>}

                <RecordPreviewPanel
                    panPlace={this.panPlace}
                    panRoad={this.panRoad}
                    selectedRecordId={this.state.selectedRecordId}
                    drawSelectedRoad={this.drawSelectedRoad}
                    drawSelectedPlace={this.drawSelectedPlace}
                    onCloseClick={this.closePreview} />
            </div >
        );
    }
}

export default withClusterMap(ClusterMap);