import React from 'react';
import { NavLink } from 'react-router-dom';

const PublicHeaderLink = ({ location, text }) => {
    return (
        <li className="nav-item" data-toggle="collapse" data-target="#main-navbar.show">
            <NavLink activeClassName="nav-active-item" className="nav-link" to={location}>
                {text}
            </NavLink>
        </li>
    );
}

export default PublicHeaderLink;