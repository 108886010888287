import React from 'react';
import RatingItem from './RatingItem';

const RatingList = ({ ratings }) => {
    return (<table className="table rating-table">
        <tbody>
            {ratings.map((rating, i) => <RatingItem {...rating} key={i} />)}
        </tbody>
    </table>);
};

export default RatingList;