import {
    COMMENT_FETCH_SUCCESS,
    COMMENT_FETCH_LOADING,
    COMMENT_FETCH_ERRORED
} from './commentActionTypes';

import { DriversRoadsApi, jsonAPIAuthorizedRequest, onApiError } from '../../api/apiHelper';

function commentFetchSuccess(comment) {
    return {
        type: COMMENT_FETCH_SUCCESS,
        comment
    };
}

function commentFetchLoading() {
    return {
        type: COMMENT_FETCH_LOADING
    };
}

function commentFetchErrored() {
    return {
        type: COMMENT_FETCH_ERRORED
    };
}

export function commentFetchById(commentId) {
    return (dispatch) => {
        dispatch(commentFetchLoading());
        let options = jsonAPIAuthorizedRequest("GET");

        fetch(DriversRoadsApi + 'api/comment/get/' + commentId, options)
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => response.json())
            .then((comment) => dispatch(commentFetchSuccess(comment)))
            .catch((e) => {
                onApiError(e);
                dispatch(commentFetchErrored());
            });
    };
}