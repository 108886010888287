/*global google*/
import React from 'react';
import toastr from 'toastr';
import { debounce } from 'lodash';

import { CreateMap } from './MapConfiguration';
import MapEditButton from './MapEditButton';
import withGoogleMap from '../hoc/withGoogleMap';
import GoogleApiHelper from '../../utils/GoogleApiHelper';
import NavigationPanel from './panels/NavigationPanel';
import MapUserPositionHelper from './PositionHelper';

const GetInitState = () => {
    return {
        editing: false,
        isDirty: false,
        countryCode: '',
        startLocation: "",
        startLocationLatLng: '',
    };
}

class PlacePreviewMap extends React.Component {
    constructor(props) {
        super(props);

        this.state = GetInitState();

        this.geocoder = null;
        this.map = null;
        this.marker = null;
        this.lastMarkerPosition = null;
        this.positionHelper = null;

        this.mapMarker = this.mapMarker.bind(this);

        this.edit = this.edit.bind(this);
        this.done = this.done.bind(this);
        this.cancel = this.cancel.bind(this);

        this.onInitLoad = this.onInitLoad.bind(this);
        this.onLocationChange = this.onLocationChange.bind(this);

        this.getLocationName = this.getLocationName.bind(this);
        this.debounceDetails = debounce(this.getLocationName, 500);
    }

    getLocationName(addresses, latLng) {
        if (addresses === null) {
            if (this.lastMarkerPosition) {
                this.marker.setPosition(this.lastMarkerPosition);
            }
            toastr.warning('Místo se nepodařilo lokalizovat');
            return;
        }

        if (addresses.length === 0) {
            if (this.lastMarkerPosition) {
                this.marker.setPosition(this.lastMarkerPosition);
            }
            toastr.warning('Neplatné místo na mapě');
            return;
        }

        //find country code
        let location = GoogleApiHelper.parseAutocompleteResult(addresses[0]);
        if (this.props.allowedCountries.includes(location.countryCode.toLowerCase())) {
            this.setState({
                editing: true,
                isDirty: true,
                countryCode: location.countryCode.toLowerCase(),
                startLocation: addresses[0].formatted_address,
                startLocationLatLng: latLng
            });
            this.lastMarkerPosition = latLng;
        }
        else {
            toastr.warning(location.country + ' není podporováno');

            if (this.lastMarkerPosition) {
                this.marker.setPosition(this.lastMarkerPosition);
            }
        }
    }

    edit() {
        this.setState({
            editing: true
        });
        this.marker.setDraggable(true);
    }

    cancel() {
        let initState = GetInitState();
        this.setState(initState);

        this.marker.setPosition({
            lat: parseFloat(this.props.record.startPoint.lat),
            lng: parseFloat(this.props.record.startPoint.lng)
        });
        this.marker.setDraggable(false);
    }

    done() {
        if (!this.state.isDirty) {
            this.cancel();
            return;
        }

        this.setState({
            editing: false,
            isDirty: false
        });
        this.marker.setDraggable(false);
        this.props.editPlace(this.props.record.id, {
            countryCode: this.state.countryCode,
            startLocationTitle: this.state.startLocation,
            startLocation: {
                lat: this.state.startLocationLatLng.lat(),
                lng: this.state.startLocationLatLng.lng()
            }
        })
    }

    mapMarker(record) {
        let position = {
            lat: parseFloat(record.startPoint.lat),
            lng: parseFloat(record.startPoint.lng)
        };

        let marker = new google.maps.Marker({
            position: position,
            type: record.recordType,
            map: this.map
        });
        this.lastMarkerPosition = position;

        var markerImage = {
            url: '/img/place-marker.png',
        };
        marker.setIcon(markerImage);
        marker.setMap(this.map);
        marker.addListener("dragend", (e) => this.onLocationChange(e));

        this.marker = marker;
    }

    onLocationChange(e) {
        let location = e.latLng;
        let request = { 'location': location };
        this.geocoder.geocode(request, (result) => this.getLocationName(result, location));
    }

    onInitLoad() {
        this.positionHelper = new MapUserPositionHelper(this.map);
        this.positionHelper.startWatching();
        this.map.setZoom(12);
        this.map.panTo(this.marker.position);
    }

    componentDidMount() {
        this.geocoder = new google.maps.Geocoder();
        this.map = CreateMap(this.props.mapId);

        let navigationMapButton = document.getElementById('navigation-map-button');
        navigationMapButton.index = 1;
        this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(navigationMapButton);

        if (this.props.isOwner) {
            let editPlaceButton = document.getElementById('edit-map-button');
            editPlaceButton.index = 1;
            this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(editPlaceButton);
        }

        this.mapMarker(this.props.record);
        google.maps.event.addListenerOnce(this.map, 'idle', this.onInitLoad);
    }

    componentWillUnmount() {
        this.positionHelper.clearWatch();
    }

    render() {
        return (<div id="wrapper">
            <div style={{ visibility: 'collapse', position: 'absolute' }}>
                {this.props.isOwner && <div><MapEditButton
                    editing={this.state.editing}
                    onEditClick={this.edit}
                    onCancelClick={this.cancel}
                    onDoneClick={this.done} /></div>}
                <NavigationPanel record={this.props.record} />
            </div>
            <div id={this.props.mapId}></div>
        </div >);
    }
}

export default withGoogleMap(PlacePreviewMap);