import { createUserManager } from 'redux-oidc';
import { WebStorageStateStore } from 'oidc-client';
import { AutozkaUrl, ClientId, Scope } from '../configuration';

const userManager = createUserManager({
  authority: AutozkaUrl,
  client_id: ClientId,
  redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/callback`,
  response_type: 'code',
  scope: Scope + ' profile openid offline_access',

  post_logout_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/`,
  monitorSession: false,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
  userStore: new WebStorageStateStore({ store: localStorage })
});

export default userManager;