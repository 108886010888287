import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import PublicHeaderLink from './header/PublicHeaderLink';
import PrivateHeaderLink from './header/PrivateHeaderLink';
import LoginHeaderLink from './header/LoginHeaderLink';
import SeasonMenuItem from './SeasonMenuItem';

class Header extends React.Component {
  render() {

    let isLoggedIn = this.props.user != null && !this.props.user.expired;
    let userText = isLoggedIn ? this.props.user.profile.name : 'Přihlásit';

    return (
      <nav className="navbar navbar-expand-md navbar-absolute">
        <div className='container'>
          <div data-toggle="collapse" data-target="#main-navbar.show">
            <NavLink exact={true} activeClassName="nav-active-item" to="/" className="navbar-brand">
              <img src='/favicon-32x32.png' width="30" height="30" className="d-inline-block align-top" alt="Drivers' Roads Logo" />
              Drivers' Roads
          </NavLink>
          </div>
          <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#main-navbar" aria-controls="main-navbar" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse justify-content-end" id="main-navbar">
            <ul className="navbar-nav">            
              <SeasonMenuItem config={this.props.configuration.configuration} />
              <PublicHeaderLink location={'/atlas'} text="Atlas" />
              <PublicHeaderLink location={'/database'} text="Databáze" />
              <PrivateHeaderLink location={'/diary'} text="Deník" disabled={!isLoggedIn} />
              {isLoggedIn ?
                <PrivateHeaderLink location={'/user'} text={userText} disabled={!isLoggedIn} /> :
                <LoginHeaderLink text={userText} />
              }
            </ul>
          </div>
        </div>
      </nav>
    );
  };
};

const mapStateToProps = (state) => {
  return {
    user: state.oidc.user,
    configuration: state.configuration
  };
};

export default withRouter(connect(mapStateToProps, null)(Header));