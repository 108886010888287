import React from 'react';
import { stringify } from 'qs';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";

import { recordsFetch } from '../../actions/diary/recordsActionCreators';
import RequestHelper from '../../api/RequestHelper';

import LoadingComponent from '../common/LoadingComponent';
import RecordLightList from '../record/lightList/RecordLightList';
import Pagination from '../common/Pagination';

class DiaryRecordsPage extends React.Component {

    constructor(props) {
        super(props);

        this.itemsOnPage = 10;
        this.changeToPage = this.changeToPage.bind(this);
    }

    changeToPage(pageNumber) {
        let pagination = RequestHelper.buildPaginationAndSort(pageNumber, this.itemsOnPage, 0);
        let paginationQuery = stringify(pagination);
        this.props.recordsFetch(paginationQuery);
    }

    componentDidMount() {
        this.changeToPage(1);
    }

    render() {
        return (
            <div className='container content-wrapper'>
                <Helmet>
                    <title>Deník - Moje cesty a místa - Drivers’ Roads</title>
                </Helmet>
                <div className='row justify-content-center'>
                    <div className='col-md-8'>
                        <h1 className="display-4">Moje cesty a místa</h1>
                        {this.props.isLoading ?
                            <LoadingComponent /> :
                            <div>
                                <RecordLightList records={this.props.records} />
                                <Pagination
                                    changeToPage={this.changeToPage}
                                    pagination={this.props.pagination} />
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        recordsFetch: (query) => dispatch(recordsFetch(query))
    };
}

const mapStateToProps = (state) => {
    return {
        records: state.diary.records.records,
        pagination: state.diary.records.pagination,
        errored: state.diary.records.errored,
        isLoading: state.diary.records.isLoading
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DiaryRecordsPage);