import React from 'react';
import { getPaginationSummary } from '../../utils/paginationHelper';

const DatabaListSummary = ({ pagination }) => {

    let result = getPaginationSummary(pagination.page, pagination.itemsOnPage, pagination.totalItems);
    return (<div className='table-responsive-lg'>
        <div>
            celkem: {pagination.totalItems} od {result.start} - {result.end}
        </div>
    </div>);
}

export default DatabaListSummary;
