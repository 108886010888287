import {
    DIARY_FAVOUTRITES_FETCH_SUCCESS,
    DIARY_FAVOUTRITES_FETCH_LOADING,
    DIARY_FAVOUTRITES_FETCH_ERRORED
} from './favouritesActionTypes';

import { DriversRoadsApi, jsonAPIAuthorizedRequest, onApiError } from '../../api/apiHelper';

function favouritesFetchSuccess(favourites, pagination) {
    return {
        type: DIARY_FAVOUTRITES_FETCH_SUCCESS,
        favourites,
        pagination
    };
}

function favouritesFetchLoading() {
    return {
        type: DIARY_FAVOUTRITES_FETCH_LOADING
    };
}

function favouritesFetchErrored() {
    return {
        type: DIARY_FAVOUTRITES_FETCH_ERRORED
    };
}

export function favouritesFetch(query) {
    return (dispatch) => {
        dispatch(favouritesFetchLoading());
        let options = jsonAPIAuthorizedRequest("GET", undefined);

        fetch(DriversRoadsApi + 'api/diary/favourites?' + query, options)
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => response.json())
            .then((response) => {
                dispatch(favouritesFetchSuccess(response.items, response.pagination));
            })
            .catch((e) => {
                onApiError(e);
                dispatch(favouritesFetchErrored());
            });
    };
}