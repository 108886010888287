import React from 'react';
import FavouriteItem from './FavouriteItem';

const FavouriteList = ({ favourites }) => {
    return (<table className="table">
        <tbody>
            {favourites.map((favourite, i) => <FavouriteItem {...favourite} key={i} />)}
        </tbody>
    </table>);
};

export default FavouriteList;