import React from 'react';
import { NavLink } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment } from '@fortawesome/free-solid-svg-icons';

const AddCommentButton = ({ id, count }) => {
    return (
        <NavLink to={'/add-comment/' + id} className="btn btn-outline-primary float-left">
            <FontAwesomeIcon icon={faComment} /> &nbsp;
                Přidat komentář {count}
        </NavLink>
    );

}

export default AddCommentButton;