import React from 'react';
import { withRouter } from 'react-router-dom';
import { AutozkaUrl } from '../../configuration';

class Footer extends React.Component {

  render() {
    if (this.props.location.pathname === "/atlas") {
      return (null);
    }

    return (
      <footer className="footer mt-auto py-2">
        <div className="container clearfix">
          <div className="row">
            <div className="col-sm-4 m-auto">
              <a className='mx-2' href='https://www.autozka.com/' target='_blank' rel="nofollow">O nás</a>
              <a className='mx-2' href="https://www.facebook.com/driversroadsofficial/" target="_blank" rel="nofollow" title="Facebook">
                <img src="/img/facebook-logo-white.png" alt=""  />
              </a>
              <a className='mx-2' href="https://www.instagram.com/drivers_roads" target="_blank" rel="nofollow" title="Instagram">
                <img src="/img/instagram-logo-white.png" alt="" />
              </a>
            </div>
            <div className="col-sm-4 text-center m-auto">
              <a className="navbar-brand" href={AutozkaUrl}>
                <img src="/img/wheel.svg" width="30" height="30"
                  className="d-inline-block align-top" title='Autozka'
                  alt="Autozka ikona" />
                <span> Autozka</span>
              </a>
            </div>
            <div className="col-sm-4 text-center m-auto">
              <cite title="Autozka moto">Tvoříme aplikace pro milovníky aut</cite>
            </div>
          </div>
        </div>
      </footer >
    );
  }
}


export default withRouter(Footer);