import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDirections } from '@fortawesome/free-solid-svg-icons';

import MapToUrlHelper from '../MapToUrlHelper';

const NavigationPanel = ({ record }) => {

    if (record.type === 1) {
        let placeUrl = MapToUrlHelper.directionsToPoint(record.startPoint.lat, record.startPoint.lng);
        return (
            <div id='navigation-map-button'>
                <a title='Navigovat' className="btn btn-primary map-button m-2" href={placeUrl} role="button" target="_blank" >
                    <FontAwesomeIcon icon={faDirections} color={'white'} />
                </a>
            </div>
        )
    }

    let startUrl = MapToUrlHelper.directionsToPoint(record.mapData.start.lat, record.mapData.start.lng);
    // let finishUrl = MapToUrlHelper.directionsToPoint(record.mapData.end.lat, record.mapData.end.lng);

    /* 
    let b = JSON.parse(record.directionsResult);
    let navigationUrl = MapToUrlHelper.toNavigation(b, record.mapData.start, record.mapData.end, record.mapData.waypoints);

    <div className='map-button-link-wrapper'>
        <a title='Přidat' className="btn btn-primary map-button m-2" href={navigationUrl} role="button" target="_blank" >
            Navigace
        </a>
    </div>

          <div id='navigation-map-button'>
            <a title='Přidat' className="btn btn-primary map-button m-2" data-toggle="collapse" href="#collapseAddRecord" role="button" aria-expanded="false" aria-controls="collapseExample">
                <FontAwesomeIcon icon={faDirections} color={'white'} />
            </a>
            <div className="collapse" id="collapseAddRecord">
                <div className='map-button-link-wrapper'>
                    <a title='Přidat' className="btn btn-primary map-button m-2" href={startUrl} role="button" target="_blank" >
                        A
                    </a>
                </div>
                <div className='map-button-link-wrapper'>
                    <a title='Přidat' className="btn btn-primary map-button m-2" href={finishUrl} role="button" target="_blank" >
                        B
                    </a>
                </div>
            </div>
        </div >
    */

    return (
        <div id='navigation-map-button'>
            <a title='Navigovat' className="btn btn-primary map-button m-2" href={startUrl} role="button" target="_blank" >
                <FontAwesomeIcon icon={faDirections} color={'white'} />
            </a>
        </div >
    )
}

export default NavigationPanel;
