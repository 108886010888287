import { combineReducers } from 'redux';

import {
    DATABASE_FETCH_SUCCESS,
    DATABASE_FETCH_LOADING,
    DATABASE_FETCH_ERRORED
} from '../actions/databaseActionTypes';

import {
    RECORD_FAVOURITE_TOGGLE_SUCCESS,
    RECORD_FAVOURITE_TOGGLE_ERRORED,
    RECORD_FAVOURITE_TOGGLE_LOADING
} from '../actions/record/recordFavouriteActionTypes';

import {
    RECORD_VISIT_TOGGLE_SUCCESS,
    RECORD_VISIT_TOGGLE_ERRORED,
    RECORD_VISIT_TOGGLE_LOADING
} from '../actions/record/recordVisitActionTypes';

import {
    RECORD_RATING_LOADING,
    RECORD_RATING_SUCCESS,
    RECORD_RATING_ERRORED
} from '../actions/record/recordRatingActionTypes';

const defaultPagination = {
    items: 5,
    page: 1
};

function databasePagination(state = defaultPagination, action) {
    switch (action.type) {
        case DATABASE_FETCH_SUCCESS:
            return action.pagination;
        default:
            return state;
    }
}

function databaseFilter(state = null, action) {
    switch (action.type) {
        case DATABASE_FETCH_SUCCESS:
            return action.filter;
        default:
            return state;
    }
}

function databaseRecordsLoaded(state = [], action) {
    switch (action.type) {
        case RECORD_RATING_LOADING:
            return state.map((record) => {
                if (record.id === action.recordId) {
                    return Object.assign({}, record, {
                        ratingLoading: true
                    })
                }
                return record;
            });
        case RECORD_RATING_ERRORED:
            return state.map((record) => {
                if (record.id === action.recordId) {
                    return Object.assign({}, record, {
                        ratingLoading: false
                    })
                }
                return record
            });
        case RECORD_RATING_SUCCESS:
            return state.map((recordd) => {
                if (recordd.id === action.recordId) {
                    return Object.assign({}, recordd, {
                        rating: action.rating,
                        numberOfRatings: action.numberOfRatings,
                        userRating: action.userRating,
                        ratingLoading: false
                    })
                }
                return recordd
            });

        case RECORD_VISIT_TOGGLE_LOADING:
            return state.map((record) => {
                if (record.id === action.recordId) {
                    return Object.assign({}, record, {
                        visitLoading: true
                    })
                }
                return record
            });
        case RECORD_VISIT_TOGGLE_ERRORED:
            return state.map((record) => {
                if (record.id === action.recordId) {
                    return Object.assign({}, record, {
                        visitLoading: false
                    })
                }
                return record
            });
        case RECORD_VISIT_TOGGLE_SUCCESS:
            return state.map((recordd) => {
                if (recordd.id === action.recordId) {
                    return Object.assign({}, recordd, {
                        userVisit: action.userVisit,
                        numberOfVisits: action.numberOfVisits,
                        visitLoading: false
                    })
                }
                return recordd
            });



        case RECORD_FAVOURITE_TOGGLE_LOADING:
            return state.map((record) => {
                if (record.id === action.recordId) {
                    return Object.assign({}, record, {
                        favouriteLoading: true
                    })
                }
                return record
            });
        case RECORD_FAVOURITE_TOGGLE_ERRORED:
            return state.map((record) => {
                if (record.id === action.recordId) {
                    return Object.assign({}, record, {
                        favouriteLoading: false
                    })
                }
                return record
            });
        case RECORD_FAVOURITE_TOGGLE_SUCCESS:
            return state.map((recordd) => {
                if (recordd.id === action.recordId) {
                    return Object.assign({}, recordd, {
                        userFavourite: action.userFavourite,
                        numberOfFavourites: action.numberOfFavourites,
                        favouriteLoading: false
                    })
                }
                return recordd
            });
        case DATABASE_FETCH_SUCCESS:
            return action.records;
        case DATABASE_FETCH_ERRORED:
            return [];
        default:
            return state;
    }
}

function databaseRecordsIsLoading(state = false, action) {
    switch (action.type) {
        case DATABASE_FETCH_LOADING:
            return true;
        case DATABASE_FETCH_ERRORED:
        case DATABASE_FETCH_SUCCESS:
            return false;
        default:
            return state;
    }
}

function databaseRecordsErrored(state = false, action) {
    switch (action.type) {
        case DATABASE_FETCH_ERRORED:
            return true;
        case DATABASE_FETCH_LOADING:
        case DATABASE_FETCH_SUCCESS:
            return false;
        default:
            return state;
    }
}

const databaseReducer = combineReducers({
    records: databaseRecordsLoaded,
    pagination: databasePagination,
    filter: databaseFilter,
    isLoading: databaseRecordsIsLoading,
    errored: databaseRecordsErrored
});

export default databaseReducer;