import React from 'react';
import toastr from 'toastr';

import userManager from '../../utils/userManager';
import LoadingComponent from '../common/LoadingComponent';

class LoginPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false
        }
    }

    componentDidMount() {

        let redirectUrl = window.location.pathname;
        if (redirectUrl === '/user') {
            redirectUrl = '/diary';
        }

        userManager.signinRedirect({
            data: { redirectUrl: redirectUrl }
        }).then(o => {
            console.log('signing user ' + JSON.stringify(o))
        }).catch(o => {
            this.setState({ loading: false });
            console.error('error signing user ' + JSON.stringify(o))
            userManager.clearStaleState();
            toastr.error('Přihlášení se nezdařilo, zkuste to prosím za chvíli.')
        });
    }

    render() {
        return (<LoadingComponent />);
    }
}

export default LoginPage;
