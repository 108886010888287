import React from 'react';

class EditRoadFields extends React.Component {
  render() {
    return (<div className="row justify-content-center mt-3">
      <div className="col-lg-8">
        <form>
          <div className="form-group">
            <label htmlFor="nameField">Název</label>
            <input type="text" value={this.props.name} name="name" onChange={this.props.handleChange} className="form-control" id="nameField" />
          </div>
          <div className="form-group">
            <label htmlFor="descriptionField">Popis</label>
            <textarea value={this.props.description} placeholder="Popis silnice, okolí, zatáček, (ne)kvality asfaltu, výhledů. Jaký je zde provoz, kdy a jakým autem je nejlepší sem zajet?" name="description" onChange={this.props.handleChange} className="form-control" id="descriptionField" rows="3"></textarea>
          </div>
          <div className="form-group row">
            <div className="col-sm-4">Soukromé</div>
            <div className="col-sm-8">
              <div className="form-check">
                <input checked={this.props.isPrivate} name="isPrivate" onChange={this.props.handleChange} className="form-check-input" type="checkbox" />
              </div>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="distanceField">Vzdálenost</label>
            <input type="text" value={Math.round(this.props.distance / 100.0) / 10 + " km"} className="form-control" id="distanceField" readOnly placeholder="" />
          </div>
          <div className="form-group">
            <label htmlFor="distanceField">Start GPS</label>
            <input type="text" value={this.props.startLocation} className="form-control" id="distanceField" readOnly placeholder="" />
          </div>
          <div className="form-group">
            <label htmlFor="distanceField">Cíl GPS</label>
            <input type="text" value={this.props.destLocation} className="form-control" id="distanceField" readOnly placeholder="" />
          </div>
        </form>
      </div>
    </div>);
  }
}

export default EditRoadFields;
