import React from 'react';
import LoadingComponent from '../common/LoadingComponent';

import { getPagination } from '../../utils/paginationHelper';

class Pagination extends React.Component {

    render() {
        if (Object.keys(this.props.pagination).length === 0 && this.props.pagination.constructor === Object) {
            return (<LoadingComponent />);
        }

        let pagination = getPagination(this.props.pagination.page,
            this.props.pagination.itemsOnPage,
            this.props.pagination.totalItems);

        let buttons = [];
        for (var i = pagination.start; i <= pagination.end; i++) {
            let page = i;
            if (this.props.pagination.page === i) {
                buttons.push(<li key={page} className='page-item active pointer'>
                    <span className="page-link">{i}</span>
                </li>);
            } else {
                buttons.push(<li key={page} className='page-item pointer'>
                    <span className="page-link" onClick={() => this.props.changeToPage(page)}>{i}</span>
                </li>);
            }
        }

        let previousActiveClass = 'page-item' + (pagination.previousActive ? ' pointer' : ' disabled');
        let nextActiveClass = 'page-item' + (pagination.nextActive ? ' pointer' : ' disabled');

        return (
            <nav className="pagination">
                <ul className="pagination">
                    <li className={previousActiveClass}>
                        <span className="page-link" onClick={() =>
                            this.props.changeToPage(this.props.pagination.page - 1)
                        }>Přechozí</span>
                    </li>
                    {buttons}
                    <li className={nextActiveClass}>
                        <span className="page-link" onClick={() =>
                            this.props.changeToPage(this.props.pagination.page + 1)
                        }>Další</span>
                    </li>
                </ul>
            </nav>
        );
    }
}

export default Pagination;