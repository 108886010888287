import React from 'react';
import { connect } from 'react-redux';
import { stringify } from 'qs';
import { debounce } from 'lodash';
import { Helmet } from "react-helmet";

import {
  fetchAtlasPointsByFilter,
  fetchAtlasDetailsByFilter,
  atlasDetailsFetchLoading,
  atlasDetailsFetchSuccess
} from '../../actions/atlasActionCreators';

import MapWeb from '../map/mapWeb';
import ClusterMap from '../map/ClusterMap';
import history from '../../store/configureHistory';
import RequestHelper from '../../api/RequestHelper';

import AtlasDetailsPanel from './AtlasDetailsPanel';

const mapConfiguration = {
  locateMeEnabled: true,
  recordAddEnabled: true,
  mapId: 'atlas-page-map'
}

const GetDefaultFilter = (configuration) => {
  return {
    recordType: 'all',
    minRating: 0,
    maxRating: 5,
    minNumRating: 0,
    maxNumRating: configuration.maxRatings,
    minVisits: 0,
    maxVisits: configuration.maxVisits,
    minComments: 0,
    maxComments: configuration.maxComments,
    minFavourites: 0,
    maxFavourites: configuration.maxFavourites,
    minDistance: 0,
    maxDistance: 500
    // hideVisited: false //not in first version
  }
}

class AtlasPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRecordId: undefined,
      visibleMap: {},
      filter: GetDefaultFilter(props.configuration),
      sort: 0
    };

    this._isMounted = false;
    this.items = 15;
    this.mapWeb = new MapWeb(props.configuration.bounds);

    this.selectRoad = this.selectRoad.bind(this);
    this.changeVisibleMap = this.changeVisibleMap.bind(this);
    this.setQueryUrl = this.setQueryUrl.bind(this);

    this.fetchPoints = this.fetchPoints.bind(this);

    this.fetchDetails = this.fetchDetails.bind(this);
    this.debounceDetails = debounce(this.fetchDetails, 3000);

    this.changeBounds = this.changeBounds.bind(this);
    this.debounceBounds = debounce(this.changeBounds, 1000);

    this.onSortChange = this.onSortChange.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 1);
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onFilterChange(filter) {
    let newFilter = JSON.stringify(filter);
    let oldFilter = JSON.stringify(this.state.filter);

    if (newFilter !== oldFilter) {
      let state = this.state;
      this._isMounted && this.setState({ filter: filter });
      this.fetchDetails(state.visibleMap, filter, this.state.sort);

      this.mapWeb.rebuildWeb();
      let visibleMap = this.state.visibleMap;
      let mapToLoad = this.mapWeb.isLoaded(visibleMap);
      this.mapWeb.setLoadedBounds(visibleMap);
      this.fetchPoints(visibleMap, mapToLoad, filter, true);
    }
  }

  onSortChange(newSort) {
    let state = this.state;
    this._isMounted && this.setState({ sort: newSort });
    this.fetchDetails(state.visibleMap, state.filter, newSort);
  }

  selectRoad(recordId) {
    if (recordId === this.state.selectedRecordId) {
      console.log('already selected record ' + recordId);
      return;
    }
    this._isMounted && this.setState({
      selectedRecordId: recordId
    });
  }

  changeVisibleMap(visibleMap) {
    this.setQueryUrl(visibleMap, this.state.filter);
    this._isMounted && this.setState({
      visibleMap
    });
  }

  setQueryUrl(visibleMap, filter) {
    let visibleMapObject = RequestHelper.buildMapQuery(visibleMap);
    let visibleMapQuery = stringify(visibleMapObject);

    let filterRequest = RequestHelper.buildFilter(filter, this.props.configuration);
    let filterQuery = stringify(filterRequest);

    let query = visibleMapQuery + '&' + filterQuery;

    if (this.props.history.location.pathname === '/atlas') {
      history.push({
        pathname: this.props.history.location.pathname,
        search: '?' + query
      });
    }
  }

  changeBounds(visibleMap) {
    this.changeVisibleMap(visibleMap);
    let state = this.state;
    this.props.detailsLoading();
    this.debounceDetails(state.visibleMap, state.filter, state.sort, this.defaultPagination);

    let fetchMap = this.mapWeb.isLoaded(visibleMap);
    if (!fetchMap.isLoaded) {
      this.mapWeb.setLoadedBounds(visibleMap);
      this.fetchPoints(visibleMap, fetchMap, this.state.filter, false);
    }
  }

  fetchDetails(map, filter, sort) {
    if (this.mapWeb.isInAllowedBounds(map)) {
      let mapQuery = stringify(RequestHelper.buildMapQuery(map));
      let filterQuery = stringify(RequestHelper.buildFilter(filter, this.props.configuration));
      let pagingQuery = stringify(RequestHelper.buildPaginationAndSort(1, this.items, sort));

      let query = mapQuery + '&' + filterQuery + '&' + pagingQuery;
      this.props.fetchDetails(query);
    } else {
      //dont fetch just reset to empty data
      this.props.setEmptyDetails({ items: [], pagination: { page: 1, totalItems: 0, itemsOnPage: 0 } })
    }
  }

  fetchPoints(visibleMap, fetchMap, filter, reset) {
    this.setQueryUrl(visibleMap, filter)

    let filterRequest = RequestHelper.buildFilter(filter, this.props.configuration);
    let filterQuery = stringify(filterRequest);

    let fetchMapRequest = RequestHelper.buildMapQuery(fetchMap);
    let fetchMapQuery = stringify(fetchMapRequest);
    console.log('fetching - ' + JSON.stringify(fetchMapRequest));
    this.props.fetchPoints(fetchMapQuery + '&' + filterQuery, reset);
  }

  render() {
    return (<div className='container-fluid'>
      <Helmet>
        <title>Atlas nejlepších silnic - Drivers’ Roads</title>
      </Helmet>
      <div className='row'>
        <div className='col-lg-9 nopadding'>
          <ClusterMap
            config={mapConfiguration}

            records={this.props.points.points}
            loading={this.props.points.loading}
            filter={this.state.filter}

            changeBounds={this.debounceBounds}

            selectedRecordId={this.state.selectedRecordId}
            selectRoad={this.selectRoad} />
        </div>
        <div className='col-lg-3 nopadding'>
          <AtlasDetailsPanel
            map={this.state.visibleMap}
            filter={this.state.filter}
            sort={this.state.sort}

            selectedRecordId={this.state.selectedRecordId}
            selectRoad={this.selectRoad}

            onSortChange={this.onSortChange}
            onFilterChange={this.onFilterChange} />
        </div>
      </div>
    </div>);
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPoints: (query, reset) => dispatch(fetchAtlasPointsByFilter(query, reset)),
    fetchDetails: (query) => dispatch(fetchAtlasDetailsByFilter(query)),
    setEmptyDetails: (details) => dispatch(atlasDetailsFetchSuccess(details)),
    detailsLoading: () => dispatch(atlasDetailsFetchLoading())
  };
};

const mapStateToProps = (state) => {
  return {
    points: state.atlas.points,
    configuration: state.configuration.configuration
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AtlasPage);