import {
    RECORD_ADD_LOADING,
    RECORD_ADD_SUCCESS,
    RECORD_ADD_ERRORED
} from '../actions/record/recordAddActionTypes';

export function recordAddLoading(state = false, action) {
    switch (action.type) {
        case RECORD_ADD_LOADING:
            return true;
        case RECORD_ADD_SUCCESS:
        case RECORD_ADD_ERRORED:
            return false;
        default:
            return state;
    }
}