import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart, faComment } from '@fortawesome/free-solid-svg-icons';
import { faPlace } from '../../icons/place';
import { faRoad } from '../../icons/road';
import { faWheel } from '../../icons/wheel';

import TotalRating from '../record/helper/TotalRating';
import PhotoUrlPathHelper from '../../api/PhotoUrlPathHelper';

const AtlasRecordItem = ({ recordId, recordType, photoId, name, distance, numberOfFavourites,
  numberOfRatings, numberOfVisits, numberOfComments, rating, selectedRecordId, selectRoad }) => {
  let isSelected = selectedRecordId === recordId;
  let imageSrc = PhotoUrlPathHelper.getRecordThumbFromRecord(photoId, recordId);
  let className = 'atlas-list-item';
  if (isSelected) {
    className += ' selected-atlas-item'
  }

  const icon = recordType === 1 ?
    <FontAwesomeIcon icon={faPlace} /> :
    <FontAwesomeIcon icon={faRoad} />;

  const link = recordType === 1 ? <Link to={'/place/' + recordId} target='_blank' >{name}</Link> : <Link to={'/road/' + recordId} target='_blank'>{name}</Link>;

  return (<div className={className}
    onClick={() => selectRoad(recordId)}>
    <div className="d-inline-block align-top">
      <img className='profile-img' src={imageSrc} alt="" />
    </div>
    <div className="atlas-item-fields">
      <div>
      <span className='record-title'><span className='record-type'>{icon}</span> {link}</span> {recordType === 0 && <span>{Math.round(distance / 100.0) / 10 + " km"}</span>}
      </div>
      <div>
        {numberOfRatings}x <TotalRating totalRating={rating} className={'d-inline'} />
      </div>
      <div>
        <FontAwesomeIcon icon={faHeart} title='Oblíbené' />  {numberOfFavourites} <FontAwesomeIcon icon={faWheel} title='Návštěvy' />  {numberOfVisits} <FontAwesomeIcon icon={faComment} title='Komentáře' />  {numberOfComments}
      </div>
    </div>
  </div>);
}

AtlasRecordItem.propTypes = {
  rating: PropTypes.number.isRequired,
  numberOfRatings: PropTypes.number.isRequired,
  distance: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  recordId: PropTypes.number.isRequired,
  selectRoad: PropTypes.func.isRequired
};

export default AtlasRecordItem;
