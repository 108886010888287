import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";

import { recordByIdFetch } from '../../actions/record/recordActionCreators';
import { editRecordMapData } from '../../actions/record/recordEditActionCreators';

import RecordDescriptionPanel from '../record/RecordDescriptionPanel';
import RecordHeader from '../record/RecordHeader';
import RecordTitle from '../record/RecordTitle';

import RoadPreviewMap from '../map/RoadPreviewMap';
import LoadingComponent from '../common/LoadingComponent';
import RecordComments from '../record/RecordComments';
import RecordGallery from '../gallery/RecordGallery';
import ErrorComponent from '../common/ErrorComponent';

class RoadPage extends React.Component {

    componentDidMount() {
        this.props.recordByIdFetch(this.props.match.params.id);
    }

    render() {
        if (this.props.hasErrored) {
            return (<div className="container content-wrapper"><ErrorComponent message='Cestu se nepodařilo načíst' /></div>);
        }

        if (this.props.isLoading || this.props.record.id != this.props.match.params.id ||
            (Object.keys(this.props.record).length === 0 && this.props.record.constructor === Object)) {
            return (<div className="container content-wrapper"><LoadingComponent /></div>);
        }

        let isLoggedIn = this.props.user != null && !this.props.user.expired;
        let isOwner = isLoggedIn && this.props.record.creatorId.toUpperCase() === this.props.user.profile.sub.toUpperCase();

        return (
            <div>
                <Helmet>
                    <title>{this.props.record.name} - nejlepší silnice - Drivers’ Roads</title>
                    <meta name="description" content={this.props.record.name + "- objevujte a sdílejte nejlepší silnice v aplikaci Drivers’ Roads."} />
                </Helmet>
                <div className='container py-2'>
                    <RecordTitle title={this.props.record.name} recordType={this.props.record.type} distance={this.props.record.distance} />
                    <RecordHeader record={this.props.record} logged={isLoggedIn} editing={this.props.record.mapIsEditing} />
                </div>
                <RoadPreviewMap
                    mapId={"edit-page-map"}
                    isOwner={isOwner}
                    editRoad={this.props.editRoad}
                    allowedCountries={this.props.configuration.allowedCountryCodes}
                    record={this.props.record} />
                <div className='bg-light'>
                    <div className='container'>
                        <div className='col-md-12'>
                            <div className='gallery-slide-panel'>
                                <RecordGallery
                                    id={this.props.record.id}
                                    isOwner={isOwner}
                                    photoIds={this.props.record.photoIds}
                                    isPrivate={this.props.record.isPrivate} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container'>
                    <RecordDescriptionPanel record={this.props.record} user={this.props.user} />
                    <hr />
                </div>
                <RecordComments recordId={this.props.record.id} isPrivate={this.props.record.isPrivate} />
            </div>);
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        recordByIdFetch: (recordId) => dispatch(recordByIdFetch(recordId, 'road')),
        editRoad: (id, data) => dispatch(editRecordMapData(id, 'road', data))
    };
};

const mapStateToProps = (state) => {
    return {
        user: state.oidc.user,
        record: state.record.record,
        hasErrored: state.record.errored,
        isLoading: state.record.isLoading,
        configuration: state.configuration.configuration
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RoadPage);