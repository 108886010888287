import {
    DIARY_RECORDS_FETCH_SUCCESS,
    DIARY_RECORDS_FETCH_LOADING,
    DIARY_RECORDS_FETCH_ERRORED
} from '../../actions/diary/recordsActionTypes';

export function recordsLoaded(state = [], action) {
    switch (action.type) {
        case DIARY_RECORDS_FETCH_SUCCESS:
            return action.records;
        case DIARY_RECORDS_FETCH_ERRORED:
            return [];
        default:
            return state;
    }
}

const defaultPagination = {
    items: 5,
    page: 1
};

export function recordsPagination(state = defaultPagination, action) {
    switch (action.type) {
        case DIARY_RECORDS_FETCH_SUCCESS:
            return action.pagination;
        default:
            return state;
    }
}

export function recordsIsLoading(state = false, action) {
    switch (action.type) {
        case DIARY_RECORDS_FETCH_LOADING:
            return true;
        case DIARY_RECORDS_FETCH_ERRORED:
        case DIARY_RECORDS_FETCH_SUCCESS:
            return false;
        default:
            return state;
    }
}

export function recordsErrored(state = false, action) {
    switch (action.type) {
        case DIARY_RECORDS_FETCH_ERRORED:
            return true;
        case DIARY_RECORDS_FETCH_LOADING:
        case DIARY_RECORDS_FETCH_SUCCESS:
            return false;
        default:
            return state;
    }
}