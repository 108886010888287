import {
    CONFIGURATION_FETCH_LOADING,
    CONFIGURATION_FETCH_SUCCESS,
    CONFIGURATION_FETCH_ERRORED,
} from './configurationActionTypes';

import { DriversRoadsApi, getPlaintJsonOptions, onApiError } from '../api/apiHelper';

function configurationRecordsFetchSuccess(configuration) {
    return {
        type: CONFIGURATION_FETCH_SUCCESS,
        configuration
    };
}

function configurationRecordsFetchLoading() {
    return {
        type: CONFIGURATION_FETCH_LOADING
    };
}

function configurationRecordsFetchErrored() {
    return {
        type: CONFIGURATION_FETCH_ERRORED
    };
}

export function fetchConfiguration() {
    return (dispatch) => {
        dispatch(configurationRecordsFetchLoading());

        let options = getPlaintJsonOptions("GET");
        fetch(DriversRoadsApi + 'api/configuration/client', options)
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => response.json())
            .then((records) => dispatch(configurationRecordsFetchSuccess(records)))
            .catch((e) => {
                onApiError(e);
                dispatch(configurationRecordsFetchErrored());
            });
    };
}