import React from 'react';
import { Link } from 'react-router-dom';
import { DriversRoadsApi } from '../../../configuration';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCrosshairs, faTimes, faHeart, faComment } from '@fortawesome/free-solid-svg-icons';
import { faWheel } from '../../../icons/wheel';
import { faPlace } from '../../../icons/place';
import { faRoad } from '../../../icons/road';

import TotalRating from '../../record/helper/TotalRating';
import LoadingComponent from '../../common/LoadingComponent';

class RecordPreviewPanel extends React.Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedRecordId !== prevProps.selectedRecordId && this.props.selectedRecordId) {
            this.setState({ loading: true });
            fetch(DriversRoadsApi + 'api/atlas/detail/' + this.props.selectedRecordId)
                .then((resp) => resp.json())
                .then((data) => {
                    this.setState({ ...data, loading: false });
                    if (data.recordType === 0) {
                        this.props.drawSelectedRoad(data);
                    } else {
                        this.props.drawSelectedPlace(data);
                    };
                })
                .catch((e) => {

                });
        }
    }

    render() {
        if (!this.props.selectedRecordId) {
            return (<div id='infoWindow'> </div>)
        }

        if (this.state.loading) {
            return (<div id='infoWindow' className='m-2 p-3 bg-light map-record-preview-border'><LoadingComponent sizeClass={'fs-3'} /></div>)
        }

        let panfunction;
        if (this.state.recordType === 0) {
            panfunction = this.props.panRoad;
        } else {
            panfunction = this.props.panPlace;
        };

        const link = this.state.recordType === 1 ?
            <Link to={'/place/' + this.state.recordId} target='_blank' >{this.state.name}</Link> :
            <Link to={'/road/' + this.state.recordId} target='_blank'>{this.state.name}</Link>;

        const icon = this.state.recordType === 1 ?
            <FontAwesomeIcon icon={faPlace} /> :
            <FontAwesomeIcon icon={faRoad} />;

        return (
            <div id='infoWindow' className='m-2 map-record-preview-border map-record-preview'>
                <div className='atlas-detail-close c-pointer' onClick={this.props.onCloseClick} ><FontAwesomeIcon icon={faTimes} /></div>
                <div>
                    <div className={'c-pointer my-1'}>
                        <span className='record-title'><span className='record-type'>{icon}</span> {link}</span> {this.state.recordType === 0 && <span>{Math.round(this.state.distance / 100.0) / 10 + " km"}</span>}
                    </div>
                    <div className={'my-1'}>
                        {this.state.numberOfRatings}x <TotalRating totalRating={this.state.rating} className={'d-inline'} />
                    </div>
                    <div className={'my-1'}>
                        <FontAwesomeIcon icon={faHeart} title='Oblíbené' />  {this.state.numberOfFavourites} <FontAwesomeIcon icon={faWheel} title='Návštěvy' />  {this.state.numberOfVisits} <FontAwesomeIcon icon={faComment} title='Komentáře' />  {this.state.numberOfComments}
                    </div>
                </div>
                <div onClick={panfunction} className={'c-pointer mt-1'}> <FontAwesomeIcon icon={faCrosshairs} title='Zaměřit'/> zaměřit</div>
            </div>
        );
    }
}

export default RecordPreviewPanel;