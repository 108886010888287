import React from 'react';
import { NavLink } from 'react-router-dom';

import Likes from './helper/Likes';
import CommentGallery from '../gallery/CommentGallery';

const CommentItem = ({ id, created, text, username, userLike, photoIds, numberOfLikes, likeLoading, onLikeClick, isLoggedIn, isPrivate }) => {

    return (
        <div className="card my-2">
            <div className='card-body'>
                <p className='lead'>{text} </p>
                <div className="d-flex justify-content-between">
                    <Likes
                        commentId={id}
                        userLike={userLike}
                        numberOfLikes={numberOfLikes}
                        loading={likeLoading}
                        onClick={onLikeClick}
                        enabled={isLoggedIn} />
                    <div className="ml-2">
                        <div className="h5 m-0">{username}</div>
                        <div className="h7 text-muted">
                            <NavLink to={'/comment/' + id} >
                                {new Date(created).toLocaleDateString()}
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
            {photoIds && photoIds.length !== 0 &&
                <div className='card-footer gallery-lr-wrapping'>
                    <div className='comment-gallery-slide-panel'>
                        <CommentGallery
                            id={id}
                            isOwner={false}
                            photoIds={photoIds}
                            isPrivate={isPrivate} />
                    </div>
                </div>
            }
        </div>
    );
}

export default CommentItem;