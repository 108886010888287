import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faPlace } from '../../icons/place';
import { faRoad } from '../../icons/road';

const FavouriteItem = ({ recordId, recordTitle, type, created }) => {
    return (<tr>
        <td>
            {type === 1 ?
                <span>
                    <FontAwesomeIcon icon={faPlace} className='primary' /> <NavLink to={"/place/" + recordId}>{recordTitle}</NavLink>
                </span> :
                <span>
                    <FontAwesomeIcon icon={faRoad} className='primary' /> <NavLink to={"/road/" + recordId}>{recordTitle}</NavLink>
                </span>
            }
            <span className='float-right'>{new Date(created).toLocaleString()}</span>
        </td>
    </tr>);
};

export default FavouriteItem;