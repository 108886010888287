import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp as selectedThumbs } from '@fortawesome/free-solid-svg-icons';
import { faThumbsUp as unselectedThumbs } from '@fortawesome/free-regular-svg-icons';

import LoadingComponent from '../../common/LoadingComponent';

const Likes = ({ enabled, commentId, loading, userLike, numberOfLikes, onClick }) => {
    if (loading) {
        return (<LoadingComponent />);
    }

    if (!enabled) {
        return (
            <div> <FontAwesomeIcon icon={unselectedThumbs} />
                <div> {numberOfLikes}</div>
            </div>
        );
    }

    return (
        <div className='user-interaction' onClick={() => onClick(commentId, userLike)}> {userLike ?
            <FontAwesomeIcon icon={selectedThumbs} /> :
            <FontAwesomeIcon icon={unselectedThumbs} />}
            <div> {numberOfLikes}</div>
        </div>
    );
}

export default Likes;