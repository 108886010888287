import React, { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons';

import ApiClient from '../../../api/ApiClient';
import TripMap from './TripMap';
import TripUserProgress from './TripUserProgress';


import LightGallery from 'lightgallery/react';


import { ISeasonConfigurationDto, ISeasonTripDto, IStageDetailDto, IStagePhotoDto, IUserStageProgressDto } from '../../../types/types';
import { UserStats } from '../../../types/seasonTypes';
import LoadingComponent from '../../common/LoadingComponent';
import withGoogleMap from '../../hoc/withGoogleMap';
import { StageItem } from './StageItem';
import PhotoUrlPathHelper from '../../../api/PhotoUrlPathHelper';
import { useContext } from 'react';
import { SeasonContext } from '../SeasonContext';
import SeasonUrlHelper from '../SeasonUrlHelper';
import LocationHelper from '../../../utils/LocationHelper';

interface ITripParams {
    tripId: string;
}

interface IStagePhotoWithStageId extends IStagePhotoDto {
    stageId: number;
}

const TripPage = () => {

    let season = useContext(SeasonContext);
    let { tripId } = useParams<ITripParams>();

    const [isLoading, setIsLoading] = useState(true);
    const [errored, setErrored] = useState(false);
    const [trip, setTrip] = useState<ISeasonTripDto>({} as ISeasonTripDto);
    const [photos, setPhotos] = useState<IStagePhotoWithStageId[]>([]);
    const [userPosition, setUserPosition] = useState<{ isSet: boolean, lng: number, lat: number }>({ isSet: false, lat: 0, lng: 0 });

    const [selectedStage, setSelectedStage] = useState<IStageDetailDto | null>(null);
    const [userProgressOverview, setUserProgressOverview] = useState<UserStats>({} as UserStats);
    const seasonHomePageUrl = SeasonUrlHelper.getSeasonHomeUrl(season.id, season.urlKey);


    const fetchTrip = async (id: number) => {
        setIsLoading(true);
        let trip = await ApiClient.getTrip(id);

        if (trip.errored) {
            setErrored(true);
            setIsLoading(false);
            return;
        }

        let allPhotos: IStagePhotoWithStageId[] = [];
        trip.data.trip.stages.sort((a, b) => a.order - b.order).forEach(o => {
            o.photos.sort((a, b) => a.order - b.order).forEach(p => {
                allPhotos.push({ ...p, stageId: o.id });
            });
        });

        setPhotos(allPhotos);
        setTrip(trip.data.trip);

        calculateProgress(trip.data.trip.stages);

        setIsLoading(false);
        // setErrored(false);
    }

    const calculateProgress = (stages: IStageDetailDto[]) => {
        let metersCovered = 0;
        let stagesCovered = 0;

        for (let s = 0; s < stages.length; s++) {
            const stage = stages[s];

            if (stage.userCompleted) {
                stagesCovered++;
                metersCovered += stage.distanceMeters;
            }
        }

        setUserProgressOverview({ traveledStages: stagesCovered, traveledMeters: metersCovered, traveledTrips: 0, completedTripsIds: [] });
    }

    const addProgress = (progress: IUserStageProgressDto) => {
        let stages = trip.stages.map(o => {
            if (o.id === progress.stageId) {
                o.userCompleted = true;
            }

            return o;
        })
        calculateProgress(stages);
        setTrip({ ...trip, stages: stages })
    }

    const startNavigationToStart = () => {
        let firstStage = trip.stages.find(o => o.order === 0);
        if (!firstStage) {
            return;
        }
        var startLat = firstStage.startLocation.lat;
        var startLng = firstStage.startLocation.lng;

        let path = 'https://www.google.com/maps/dir/'

        let userLocation = `/`;
        if (userPosition?.lat && userPosition?.lng) {
            userLocation = `${userPosition?.lat},${userPosition?.lng}/`;
        }

        let start = `${startLat},${startLng}/`;
        let url = path + userLocation + start;
        var win = window.open(url, '_blank');
        win?.focus();
    }

    useEffect(() => {
        fetchTrip(Number.parseInt(tripId));
    }, [tripId])

    useEffect(() => {
        const updatePos = (gps: any) => {
            setUserPosition({ isSet: true, lat: gps.lat, lng: gps.lng });
        }
        const invalidPos = () => {
            setUserPosition({ isSet: false, lat: 0, lng: 0 });
        }
        const watchId = LocationHelper.watchPosition(updatePos, invalidPos);
        return () => {
            LocationHelper.clearWatchPosition(watchId)
        }
    }, [])

    const mapStageClick = (item: IStageDetailDto) => {
        setSelectedStage(item);
    }

    let color = '#1c55ff';
    if (selectedStage) {
        color = '#d2d2d2';
    }

    return (
        <>
            <div className="trip-grid">
                <div className="trip-grid__container">

                    <h1 className="trip-grid__caption">{trip.name}</h1>
                    <NavLink to={seasonHomePageUrl}><FontAwesomeIcon icon={faArrowAltCircleLeft} /> zpět na sezónu</NavLink>

                    {/* <div className='py-4'>
                        <PartnerList seasonId={season.id} partners={season.partners} />
                    </div> */}

                    {/* <div className="stage-details">
                        <div className="trip-grid__desc"></div>
                        <div dangerouslySetInnerHTML={{ __html: trip.pageData }} />
                    </div> */}

                    {isLoading ? <LoadingComponent /> :
                        <>
                            <TripUserProgress
                                trip={{ totalStages: trip.stages.length, totalMeters: trip.stages.map(o => o.distanceMeters).reduce((a, b) => a + b, 0) }}
                                user={userProgressOverview} />

                            <div className="trip-tabs">
                                <div className="trip-tabs__nav">
                                    <div className="trip-tabs__step" onClick={() => setSelectedStage(null)} style={{ backgroundColor: selectedStage === null ? '#fff' : '#f1f1f1', color: selectedStage === null ? '#222' : '#888' }}>Úvod</div>
                                    {trip.stages.map((o, i) => <div key={i} className="trip-tabs__step" onClick={() => setSelectedStage(o)}
                                        style={{ backgroundColor: selectedStage === o ? '#fff' : '#f1f1f1', color: selectedStage === null ? '#222' : '#888' }} >{o.order + 1}. {o.name}</div>)}
                                </div>
                                {selectedStage === null && <div className="trip-tabs__box" style={{ borderColor: color }}>
                                    <div className='text-center m-2'>
                                        <button className="stage-card__button" onClick={() => startNavigationToStart()}>Zahájit navigaci na start</button>
                                    </div>
                                    <hr></hr>
                                    <div dangerouslySetInnerHTML={{ __html: trip.pageData }} />
                                </div>}
                                {trip.stages.map((o, i) =>
                                    <StageItem key={i}
                                        seasonId={season.id}
                                        tripId={tripId}
                                        stage={o}
                                        selectedStage={selectedStage}
                                        setSelectedStage={setSelectedStage}
                                        addProgress={addProgress} />)}
                            </div>
                        </>
                    }

                    {/* <div className="trip-grid__mobile-map">
                        {isLoading ? <LoadingComponent /> :
                            <TripMap items={trip.stages} selected={selectedStage} stageSelect={mapStageClick} />
                        }
                    </div> */}
                    {/* <div className="stage-details">
                        {
                            selectedStage && <div className="stage-details__text">
                                <h2>{selectedStage.name}</h2>
                                <div dangerouslySetInnerHTML={{ __html: selectedStage.pageData }} />
                            </div>
                        }
                    </div> */}

                    {/* <div className="stage-details">
                        {selectedStage && <div className="stage-details__text">
                            <h2>{selectedStage.name}</h2>
                            <div dangerouslySetInnerHTML={{ __html: selectedStage.pageData }} />
                        </div>} */}
                    {/* </div> */}
                    {/* <div className="stage-gallery">
                        <h2 className="stage-gallery__caption">Galerie</h2>
                        {photos.map((o, i) => {
                            return <div key={i} className="stage-gallery__item">
                                <a href="" className="stage-gallery__link" style={{
                                    backgroundImage: `url("${PhotoUrlPathHelper.getStagePhotoThumbUrl(season.id, tripId, o.stageId, o.fileName)}")`
                                }}></a>
                            </div>
                        })}
                    </div> */}
                    <h2 className="stage-gallery__caption">Galerie</h2>
                    {isLoading ? <LoadingComponent /> :
                        <>
                            {photos.map((o, i) => {
                                return <div key={i} id={`caption${o.id}`} style={{ display: 'none' }}>
                                    <p>{o.text}</p>
                                </div>
                            })}
                            <LightGallery elementClassNames='stage-gallery' speed={500}>
                                {photos.map((o, i) => {
                                    return <div key={i} data-sub-html={`#caption${o.id}`} className="stage-gallery__item" data-src={PhotoUrlPathHelper.getPhotoUrl(season.id, tripId, o.stageId, o.fileName)}>
                                        <a href="" data-sub-html={`#caption${o.id}`} className="stage-gallery__link" style={{
                                            backgroundImage: `url("${PhotoUrlPathHelper.getStagePhotoThumbUrl(season.id, tripId, o.stageId, o.fileName)}")`
                                        }}></a>
                                    </div>
                                })}
                            </LightGallery>
                        </>
                    }
                </div>
                <div className="trip-grid__map">
                    {isLoading ? <LoadingComponent /> :
                        <TripMap items={trip.stages} selected={selectedStage} stageSelect={mapStageClick} />
                    }
                </div>
            </div>
        </>
    );
}

export default withGoogleMap(TripPage);


// <h2>Popis</h2>

// <p><strong>Pellentesque habitant morbi tristique</strong> senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. <em>Aenean ultricies mi vitae est.</em> Mauris placerat eleifend leo. Quisque sit amet est et sapien ullamcorper pharetra. Vestibulum erat wisi, condimentum sed, <code>commodo vitae</code>, ornare sit amet, wisi. Aenean fermentum, elit eget tincidunt condimentum, eros ipsum rutrum orci, sagittis tempus lacus enim ac dui. <a href="#">Donec non enim</a> in turpis pulvinar facilisis. Ut felis.</p>

// <ol>
//     <li>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</li>
//     <li>Aliquam tincidunt mauris eu risus.</li>
// </ol>

// <blockquote>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus magna. Cras in mi at felis aliquet congue.</blockquote>

// <h3>Header Level 3</h3>

// <p>Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. <em>Aenean ultricies mi vitae est.</em></p>

// <ul>
//     <li>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</li>
//     <li>Aliquam tincidunt mauris eu risus.</li>
// </ul>

{/* <div className="youtube-video">
    <iframe width="560" height="315" src="https://www.youtube.com/embed/aiYpDDHKy18" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
</div> */}