import {
    COMMENT_FETCH_LOADING,
    COMMENT_FETCH_SUCCESS,
    COMMENT_FETCH_ERRORED
} from '../actions/comment/commentActionTypes';

import {
    COMMENT_EDIT_LOADING,
    COMMENT_EDIT_SUCCESS,
    // COMMENT_EDIT_ERRROED,

    COMMENT_DELETE_LOADING,
    COMMENT_DELETE_SUCCESS
} from '../actions/comment/commentOwnerActionTypes';

import {
    COMMENT_LIKE_TOGGLE_LOADING,
    COMMENT_LIKE_TOGGLE_SUCCESS,
    COMMENT_LIKE_TOGGLE_ERRORED
} from '../actions/comment/commentLikeActionTypes';

import {
    COMMENT_PHOTO_ADD_LOADING,
    COMMENT_PHOTO_ADD_SUCCESS,
    COMMENT_PHOTO_ADD_ERRROED
} from '../actions/comment/commentPhotoActionTypes';

import {
    COMMENT_PHOTO_DELETE_SUCCESS
} from '../actions/comment/commentPhotoActionTypes';

export function commentLoaded(state = {}, action) {
    switch (action.type) {
        case COMMENT_FETCH_SUCCESS:
            return action.comment;
        case COMMENT_DELETE_SUCCESS:
            if (state.id === action.commentId) {
                return Object.assign({}, state, {
                    isDeleted: true
                });
            }
            return state;
        case COMMENT_LIKE_TOGGLE_LOADING:
            if (state.id === action.commentId) {
                return Object.assign({}, state, {
                    likeLoading: true
                });
            }
            return state;
        case COMMENT_LIKE_TOGGLE_ERRORED:
            if (state.id === action.commentId) {
                return Object.assign({}, state, {
                    likeLoading: false
                });
            }
            return state;
        case COMMENT_LIKE_TOGGLE_SUCCESS:
            if (state.id === action.commentId) {
                return Object.assign({}, state, {
                    userLike: action.userLike,
                    numberOfLikes: action.numberOfLikes,
                    likeLoading: false
                });
            }
            return state;


        case COMMENT_PHOTO_DELETE_SUCCESS:
            if (state.id === action.commentId) {
                return Object.assign({}, state, {
                    photoIds: state.photoIds.filter((item) => item !== action.photoId)
                });
            }
            return state;
        case COMMENT_PHOTO_ADD_ERRROED:
            if (state.id === action.commentId) {
                debugger;
                return Object.assign({}, state, {
                    photoIds: state.photoIds.filter((item) => item.loadingPhotoId !== action.loadingPhotoId)
                });
            }
            return state;
        case COMMENT_PHOTO_ADD_SUCCESS:
            if (state.id === action.commentId) {
                return Object.assign({}, state, {
                    photoIds: state.photoIds.map((item) => {
                        if (item.loadingPhotoId !== action.loadingPhotoId) {
                            return item;
                        }

                        return action.photoId;
                    })
                });
            }
            return state;
        case COMMENT_PHOTO_ADD_LOADING:
            if (state.id === action.commentId) {
                return Object.assign({}, state, {
                    photoIds: [...state.photoIds, { loadingPhotoId: action.loadingPhotoId }]
                });
            }
            return state;
        case COMMENT_EDIT_LOADING:
            if (state.id === action.commentId) {
                return Object.assign({}, state, {
                    editLoading: true
                });
            }
            return state;
        case COMMENT_EDIT_SUCCESS:
            if (state.id === action.commentId) {
                return Object.assign({}, state, {
                    editLoading: false,
                    text: action.text
                });
            }
            return state;
        default:
            return state;
    }
}

export function commentIsLoading(state = false, action) {
    switch (action.type) {
        case COMMENT_FETCH_LOADING:
            return true;
        case COMMENT_FETCH_SUCCESS:
        case COMMENT_FETCH_ERRORED:
            return false;
        default:
            return state;
    }
}

export function commentErrored(state = false, action) {
    switch (action.type) {
        case COMMENT_FETCH_ERRORED:
            return true;
        case COMMENT_FETCH_LOADING:
        case COMMENT_FETCH_SUCCESS:
            return false;
        default:
            return state;
    }
}
