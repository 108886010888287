import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons';

import RecordTypeGroup from '../filter/RecordTypeGroup';
import RecordRangeList from '../filter/RecordRangeList';
import FilterSelectionPanel from '../filter/FilterSelectionPanel';

import LocationInputFieldWithButton from '../common/LocationInputFieldWithButton';
import OptionSelect from '../form/OptionSelect';

class DatabaseFilterPanel extends React.Component {
    constructor(props) {
        super(props);

        this.state = Object.assign({}, props.filter);
        this.defaultFilter = { ...props.defaultFilter };
        this.state.location = Object.assign({}, props.location);

        this.autocomplete = null;

        this.updateFilter = this.updateFilter.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.onLocationChange = this.onLocationChange.bind(this);

        this.onRecordTypeChange = this.onRecordTypeChange.bind(this);
        this.onRangeChange = this.onRangeChange.bind(this);
        this.onFilterReset = this.onFilterReset.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let state = { ...this.state };
        state[name] = value;
        this.setState({
            state
        });

        this.props.changeOption(name, value);
    }

    onLocationChange(location) {
        this.setState({ location: { ...location } });
        this.props.onLocationChange(location);
    }

    updateFilter(filter) {
        this.setState({ filter: filter });;
    }

    onFilterReset() {
        let filter = { ...this.defaultFilter };
        this.setState(filter);
    }

    onRecordTypeChange(event) {
        let state = { ...this.state };
        state.recordType = event.target.value;
        this.setState({ ...state });

        this.props.changeFilter(state);
    }

    onRangeChange(field, min, max) {
        this.setState({
            ['min' + field]: min,
            ['max' + field]: max
        });
    }

    render() {
        const rangeOptions =
            [{ value: 25, text: '25 km' },
            { value: 50, text: '50 km' },
            { value: 100, text: '100 km' },
            { value: 250, text: '250 km' },
            { value: this.state.location.countryCode, text: this.state.location.country }];

        const itemsOptions =
            [{ value: '10', text: '10' },
            { value: '20', text: '20' },
            { value: '50', text: '50' }];

        const sortOptions =
            [{ value: '0', text: 'Nejnovější' },
            { value: '1', text: 'Nejstarší' },
            { value: '4', text: 'Nejbližší' },
            { value: '2', text: 'Nejlépe hodnocené' },
            { value: '3', text: 'Nejhůře hodnocené' },
            { value: '9', text: 'Nejvíc hodnocení' },
            { value: '7', text: 'Nejoblíbenější' },
            { value: '6', text: 'Nejnavštěvovanější' },
            { value: '8', text: 'Nejkomentovanější' }];
        // { value: '7', text: 'Nejkratší' },
        // { value: '8', text: 'Nejdelší' }];

        return (
            <div>
                <form onSubmit={this.handleSubmit}>
                    <div className="form-row justify-content-center">
                        <LocationInputFieldWithButton onLocationChange={this.onLocationChange} address={this.state.location.address} />
                        <OptionSelect text={'Maximální vzdálenost'} name={'range'} value={this.props.range} options={rangeOptions} onChange={this.handleChange} className={"form-group col-md-3"} />
                        <OptionSelect text={'Počet výsledků na stránku'} name={'items'} value={this.props.items} options={itemsOptions} onChange={this.handleChange} className={"form-group col-md-3"} />
                        <OptionSelect text={'Řazení'} name={'sort'} value={this.props.sort} options={sortOptions} onChange={this.handleChange} className={"form-group col-md-3"} />
                    </div>
                    {/* <AtlasFilterPanel defaultFilter={this.props.defaultFilter} filter={this.state} configuration={this.props.configuration} onFilterChange={this.props.changeFilter} /> */}
                    <div className='text-center mt-2'>
                        <RecordTypeGroup recordType={this.state.recordType} onChange={this.onRecordTypeChange} />
                        <div className="col-auto">
                            <FilterSelectionPanel filter={this.state} configuration={this.props.configuration} />
                            <div className='pointer collapsed' data-toggle="collapse" data-target="#collapseFilter" aria-expanded="false" aria-controls="collapseFilter">
                                rozšířený filtr <i className="fa"></i>
                            </div>
                            <div className="collapse" id="collapseFilter">
                                <div style={{ margin: '0 auto', width: '60%' }} className='my-3'>
                                    <RecordRangeList updateFilter={this.onRangeChange} {...this.state} configuration={this.props.configuration} />
                                    {/* <Checkbox text={'Skrýt navšítevé'} name={'hideVisited'} onChange={this.onCheckboxChange} value={this.state.hideVisited} /> */}
                                </div>
                                <div className="col-auto">
                                    <div className="btn-group btn-group-toggle m-2">
                                        <button className="btn btn-outline-primary mb-2" onClick={() => this.props.changeFilter({ ...this.state })} >Filtrovat</button>
                                        <button className="btn btn-outline-primary mb-2" title='Resetovat' onClick={() => this.onFilterReset()}><FontAwesomeIcon icon={faTrash} /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form >
            </div >
        );
    }
}

export default DatabaseFilterPanel;