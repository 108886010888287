import { DriversRoadsApi as ApiUrl } from "../configuration";
import { jsonAPIAuthorizedRequest } from './apiHelper';

import {
    ISeasonWithUserProgress,
    ITripWithUserProgress,
    IAddUserStageProgressDto,
    IUserStageProgressDto,
    ISeasonConfigurationDto
} from '../types/types';

export class ApiCallResult<T> {
    data: T;
    errored: boolean = false;

    constructor(result: T | null) {
        if (result !== null) {
            this.data = result;
        } else {
            this.data = {} as T;
        }
    }

    static errored<T>(): ApiCallResult<T> {
        let result = new ApiCallResult<T>(null);
        result.errored = true;
        return result;
    }
}


class ApiClient {

    async processResponse<T>(response: Response): Promise<ApiCallResult<T>> {
        if (response.ok) {
            let body = await response.text();

            console.log();

            if (body) {
                let data: T = SerializationHelper.Deserialize(body);
                return new ApiCallResult<T>(data);
            }

            return new ApiCallResult<T>(null);
        } else {
            return ApiCallResult.errored<T>();
        }
    }

    async processRequest<T>(type: string, url: string, body?: any): Promise<ApiCallResult<T>> {

        let options = jsonAPIAuthorizedRequest(type, body);
        try {
            let request = new Request(ApiUrl + url, options);
            let response = await fetch(request);
            return await this.processResponse(response);

        } catch (error) {
            return ApiCallResult.errored<T>();
        }
    }


    async getSeason(seasonId: number): Promise<ApiCallResult<ISeasonWithUserProgress>> {
        let season = await this.processRequest<ISeasonWithUserProgress>('GET', `api/season/${seasonId}`);
        return season;
    }

    async getSeasonConfiguration(seasonId: number): Promise<ApiCallResult<ISeasonConfigurationDto>> {
        let seasonConfiguration = await this.processRequest<ISeasonConfigurationDto>('GET', `api/season/configuration/${seasonId}`);
        return seasonConfiguration;
    }

    async getTrip(tripId: number): Promise<ApiCallResult<ITripWithUserProgress>> {
        let trip = await this.processRequest<ITripWithUserProgress>('GET', `api/season/trip/${tripId}`);
        return trip;
    }

    async setStageProgress(progress: IAddUserStageProgressDto): Promise<ApiCallResult<IUserStageProgressDto>> {
        let userProgress = await this.processRequest<IUserStageProgressDto>('POST', `api/season/set-progress`, progress);
        return userProgress;
    }
}

export class SerializationHelper {
    public static Deserialize(data: string): any {
        return JSON.parse(data, this.ReviveDateTime);
    }

    private static ReviveDateTime(key: any, value: any): any {
        if (typeof value === 'string') {
            let a = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d(?:\.\d+)?Z?/.exec(value);
            if (a) {
                return new Date(a[0].endsWith('Z') ? a[0] : a[0] + 'Z');
            }
        }

        return value;
    }

    public static CleanEmpty(item: any) {
        for (var prop in item) {
            if (item[prop] === null || item[prop] === undefined || item[prop] === '') {
                delete item[prop];
            }
        }
        return item
    }
}

const instance = new ApiClient();
export default instance;