import React, { useContext, useEffect, useState } from 'react';
import ApiClient from '../../../api/ApiClient';
import useModal from '../../../hooks/useModal';
import StageStartModal from './StageStartModal';
import { IStageDetailDto, IWaypoint, IUserStageProgressDto } from '../../../types/types';
import LoadingComponent from '../../common/LoadingComponent';
import { UserContext } from '../UserContext';
import { SeasonContext } from '../SeasonContext';
import LocationHelper from '../../../utils/LocationHelper';
import LoginButton from '../../user/LoginButton';

export const StageItem = (props: {
    seasonId: number;
    tripId: string;
    stage: IStageDetailDto;
    selectedStage: IStageDetailDto | null;
    setSelectedStage: React.Dispatch<React.SetStateAction<IStageDetailDto | null>>;
    addProgress: (progress: IUserStageProgressDto) => void;
}) => {

    const user = useContext(UserContext);
    const season = useContext(SeasonContext);
    // const navigationDistance = season.startNavigationDistance;
    const navigationDistance = 1000;

    const [distance, setDistance] = useState<{ value: number, isSet: boolean, lng: number, lat: number }>({ value: 0, isSet: false, lat: 0, lng: 0 });
    const [isLoading, setIsLoading] = useState(false);
    const { isShowing, toggle } = useModal();

    const stage = props.stage;

    const startNavigation = (): string => {
        var stage = props.stage;
        var waypoints: IWaypoint[] = JSON.parse(stage.serializedWaypoints);

        var startLat = stage.startLocation.lat;
        var startLng = stage.startLocation.lng;

        var endLat = stage.endLocation.lat;
        var endLng = stage.endLocation.lng;

        let path = 'https://www.google.com/maps/dir/';
        let start = `${startLat},${startLng}/`;
        let end = `${endLat},${endLng}/`;

        let waypointsDir = '';
        for (let index = 0; index < waypoints.length; index++) {
            let waypoint = waypoints[index];
            waypointsDir += `${waypoint.lat},${waypoint.lng}/`;
        }

        let url = path + start + waypointsDir + end + 'data=!3m1!4b1!4m4!4m3!2m1!1b1!3e0';
        // url = path + start + waypointsDir + end + 'data=!3m1!4b1!4m4!4m3!2m1!1b1!3e0'
        console.log(url);

        return url;
        var win = window.open(url, '_blank');
        win?.focus();
        // ? 50.0697652,14.4546939&daddr=Bari,Italy+to:Foggia,Italy+to:Zapponeta,Italy&dirflg=d,h
        start = '';
        end = `&destination=${endLat},${endLng}`;
        waypointsDir = '&waypoints=';
        waypointsDir += `${startLat},${startLng}`;
        for (let index = 0; index < waypoints.length; index++) {
            let waypoint = waypoints[index];
            waypointsDir += `|${waypoint.lat},${waypoint.lng}`;
            if (index + 1 !== waypoints.length) {
                // waypointsDir += '|';
            }
        }
    };

    useEffect(() => {
        const updatePos = (gps: any) => {
            setDistance({
                value: google.maps.geometry.spherical.computeDistanceBetween(
                    new google.maps.LatLng(gps.lat, gps.lng),
                    new google.maps.LatLng(stage.startLocation.lat, stage.startLocation.lng)
                ), isSet: true, lat: gps.lat, lng: gps.lng
            });
        }
        const invalidPos = () => {
            setDistance({
                value: 0, isSet: false, lat: 0, lng: 0
            });
        }
        const watchId = LocationHelper.watchPosition(updatePos, invalidPos);
        return () => {
            LocationHelper.clearWatchPosition(watchId)
        }
    }, [])

    const addProgress = async (point: IWaypoint) => {
        setIsLoading(true);
        let progres = await ApiClient.setStageProgress({ stageId: stage.id, point: point });

        if (progres.errored) {
            return;
        }

        props.addProgress(progres.data);
        setIsLoading(false);
    };

    const onStageStart = async () => {
        toggle();
        if (distance.lat) {
            await addProgress({ lat: distance.lat, lng: distance.lng });
        }
    };

    let color = '#d2d2d2';
    let display = 'none';
    if (props.selectedStage?.id === stage.id) {
        color = '#1c55ff';
        display = 'block';
    }
    console.log(distance);

    let buttonValue = <>{distance.isSet && <>{Math.floor(distance.value / 1000)}km od uživatele</>}</>

    if (distance.value < 1000) {
        buttonValue = <>{distance.isSet && <>{Math.floor(distance.value)}m od uživatele</>}</>
    }

    const StartNavigationButton = <><button className="stage-card__button" onClick={() => onStageStart()}
        disabled={distance.isSet && distance.value > navigationDistance}>Zahájit navigaci - {buttonValue}</button></>;

    const RepeatNavigationButton = <button className="stage-card__button" onClick={() => toggle()}>Projet etapu znovu</button>;

    const LocationText = !distance.isSet ? <>vaši polohu nelze určit, zapněte prosím určování polohy</> : <>aktuální vzdálenost {distance.isSet && <><b>{Math.floor(distance.value / 1000)}km</b></>}, v případě problémů <a href={startNavigation()} target="_blank" >klikněte sem</a></>
    const NavigationToFarText = <><p>Pro zahájení navigace musíte být blíže než <b>{Math.floor(navigationDistance / 1000)}km</b>, {LocationText}</p></>

    return <>
        <StageStartModal isShowing={isShowing} toggle={toggle} url={startNavigation()} />
        <div className="trip-tabs__box" onClick={() => props.setSelectedStage(stage)} style={{ borderColor: color, display: display }}>
            <h2 className="stage-card__name">{stage.order + 1}. {stage.name}</h2>
            <p className="stage-card__info">Délka etapy: {(stage.distanceMeters / 1000).toFixed(2)} km</p>

            {!user ? <><p>Pro účast v sezóně musíš být přihlášen <LoginButton /></p></> :

                isLoading ? <LoadingComponent /> :
                    stage.userCompleted ? RepeatNavigationButton :
                        distance.isSet && distance.value < navigationDistance ? StartNavigationButton : NavigationToFarText
            }
            <hr></hr>
            <div className="stage-details__text">
                <div dangerouslySetInnerHTML={{ __html: stage.pageData }} />
            </div>
        </div>
    </>;
};
