import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";

import SeasonFeatures from './SeasonFeatures';
import HomeFeatures from './HomeFeatures';
import HomeCommunity from './HomeCommunity';

class HomePage extends React.Component {

  onSmoothScrollClick = (e) => {
    e.preventDefault();
    let anchor = document.getElementById('season');
    anchor.scrollIntoView({ behavior: 'smooth', block: "start", inline: "nearest" });
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>Objevujte nejlepší silnice - Drivers’ Roads</title>
          <meta name="description" content="Objevujte a sdílejte nejlepší silnice a nejkrásnější místa pro řidiče (jako auto vyhlídky nebo místa na focení aut). To vše v aplikaci Drivers’ Roads." />
        </Helmet>
        <div className="map-wrapper">
          <div id="home-page-cover">
            <div className='landing-wrapper'>
              <h1 className='display-4'>
                Drivers‘ Roads letní sezóna 2023 je tady!</h1>
              <div className='d-md-block'>
                <p className="lead pt-2">
                  <a className="btn btn-lg btn-primary" onClick={this.onSmoothScrollClick} href="#season">
                    Zjistit více
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div >

        <SeasonFeatures config={this.props.configuration} />
        <HomeFeatures />
        <HomeCommunity />
      </div >
    );
  }
}

const mapStateToProps = (state) => {
  return {
    configuration: state.configuration
  };
};

export default connect(mapStateToProps, null)(HomePage);
