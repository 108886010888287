class SeasonUrlHelper {

    static getSeasonHomeUrl(seasonId: number | string, seasonUrlKey: string) {
        return `/season/${seasonId}/${seasonUrlKey}`;
    }

    static getSeasonAboutPageUrl(seasonId: number | string, seasonUrlKey: string) {
        return `/season/${seasonId}/${seasonUrlKey}/about`;
    }

    static getSeasonTripPageUrl(seasonId: number | string, seasonUrlKey: string, tripId: number | string, tripUrlKey: string) {
        return `/season/${seasonId}/${seasonUrlKey}/trip/${tripId}/${tripUrlKey}`;
    }
}

export default SeasonUrlHelper;