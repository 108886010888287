import React from 'react';

const RecordTitle = ({ title, recordType, distance }) => {

    return (<div className='text-center'>
        <h1>
            <span className='record-type'>
                {recordType === 0 ?
                    <img alt='Road' src="/img/road-marker.png" /> :
                    <img alt='Place' src="/img/place-marker.png" />}
            </span>
            &nbsp;{title}
            {recordType === 0 && <span className='lead'>&nbsp;{Math.round(distance / 100.0) / 10 + " km"}</span>}
        </h1>
    </div>
    );
};

export default RecordTitle;