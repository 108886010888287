import React from 'react';
import Slider from "react-slick";

import LoadingComponent from '../common/LoadingComponent';
import LightboxModal from './LightboxModal';

class GallerySlider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      canOpenLightbox: true,
      openLightbox: false,
      currentImage: 0
    };

    this.onImgClick = this.onImgClick.bind(this);
    this.closeLightbox = this.closeLightbox.bind(this);
    this.beforeChange = this.beforeChange.bind(this);
    this.afterChange = this.afterChange.bind(this);
  }

  onImgClick(index) {
    if (!this.state.canOpenLightbox) {
      return;
    }

    this.setState({
      currentImage: index,
      openLightbox: true
    });
  }

  closeLightbox() {
    this.setState({
      openLightbox: false
    });
  }

  beforeChange(index) {
    this.setState({
      canOpenLightbox: false
    });
  }

  afterChange(oldIndex, newIndex) {
    this.setState({
      canOpenLightbox: true
    });
  }

  render() {
    if (this.props.photoIds && this.props.photoIds.length !== 0) {
      const listItems = this.props.photoIds.map((i, index) => {
        if (i.loadingPhotoId) {
          return (
            <div key={i.toString()} className='carousel-item-loading'>
              <LoadingComponent />
            </div>)
        }

        return (<div key={i.toString()} className='carousel-item'>
          {this.props.isOwner && (this.props.canDeleteFirst || index !== 0) &&
            <span onClick={() => this.props.onDeleteClick(i)} title='Smazat fotku'
              className='carousel-item-delete' />
          }
          <img alt={i.toString()}
            src={this.props.getThumb(i, this.props.id)}
            onClick={() => this.onImgClick(index)}
          />
        </div >)
      });

      let settings = {
        dots: true,
        infinite: false,
        speed: 500,
        initialSlide: 0,
        responsive: this.props.responsive,
        beforeChange: this.beforeChange,
        afterChange: this.afterChange
      };

      let images = [];
      this.props.photoIds.map((i) => {
        if (!i.loadingPhotoId) {
          images.push({ src: this.props.getOriginal(i, this.props.id) })
        }
      });

      return (
        <div>
          <LightboxModal
            images={images}
            onClose={this.closeLightbox}
            openLightbox={this.state.openLightbox}
            currentImage={this.state.currentImage} />
          <Slider {...settings}>
            {listItems}
          </Slider>
        </div>
      );
    }

    return (<div></div >);
  }
}

GallerySlider.propTypes = {
};

export default GallerySlider;
