import React from 'react';
import toastr from 'toastr';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';

class AddPhotoButton extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.onFileChange = this.onFileChange.bind(this);
    }

    onFileChange(e) {
        var _URL = window.URL || window.webkitURL;
        let img = null;
        let file = e.target.files[0];

        let type = file.type.split('/').pop().toLowerCase();
        if (type != "jpeg" && type != "jpg" && type != "png") {
            toastr.error('Soubor ' + file.name + ' nelze nahrát.');
            return;
        }

        if (file) {
            img = new Image();
            img.onload = () => {
                this.props.addPhoto(file);
            };
            img.onerror = () => {
                toastr.error('Soubor ' + file.name + ' nelze nahrát.');
            };
            img.src = _URL.createObjectURL(file);
        }
    }

    render() {

        let canAddPhoto = this.props.currentPhotos < this.props.maxPhotos;
        let title = canAddPhoto ? '' : 'Nelze nahrát další fotky';
        let isDisable = canAddPhoto ? '' : 'disabled';

        return (
            <div title={title}>
                <div className={"image-upload " + isDisable}>
                    <label className='mb-0 pointer' htmlFor="file-input">
                        <div>
                            <FontAwesomeIcon className='pointer' icon={faCamera} />
                        </div>
                        <div>
                            Nahrát fotku
                        </div>
                    </label >
                    <input id="file-input" onChange={this.onFileChange} type="file" accept="image/jpeg, image/png" />
                </div>
            </div>
        );
    }
}

export default AddPhotoButton;