import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';

const ToAtlasItems = () => {

    return (
        <div id='scroll-map-button' className="d-lg-none">
            <button className="btn btn-outline-primary map-button text-white m-2" onClick={(e) => {
                e.preventDefault();
                let anchor = document.getElementById('atlas-records-panel');
                anchor.scrollIntoView({ behavior: 'smooth', block: "start", inline: "nearest" });
            }}>
                <FontAwesomeIcon icon={faArrowDown} />
            </button>
        </div>
    )
}

export default ToAtlasItems;