import React from 'react';
import { connect } from 'react-redux';
import { withRouter, matchPath } from 'react-router-dom';
import { Helmet } from "react-helmet";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment } from '@fortawesome/free-solid-svg-icons';

import { fetchRecordPreview, addCommentToRecord } from '../../actions/comment/commentAddActionCreators';

import LoadingComponent from '../common/LoadingComponent';

import ErrorComponent from '../common/ErrorComponent';
import RecordLightItem from '../record/lightList/RecordLightItem';

class AddCommentPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            adding: false,
            text: '',
            id: ''
        };

        this.onEditClick = this.onEditClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        if (this.props.commment === undefined) {

            let match = matchPath(this.props.history.location.pathname, {
                path: '/add-comment/:id',
                exact: false,
                strict: false
            });

            let id = match.params['id'];
            this.setState({
                id: id
            });
            this.props.fetchRecordPreview(id);
        }
    }

    onEditClick() {
        this.setState({
            editing: true,
            text: this.props.comment.text
        });
    }

    handleChange(event) {
        this.setState({ text: event.target.value });
    }

    render() {
        if (this.props.recordErrored) {
            return (<div className="container content-wrapper"><ErrorComponent message='Záznam se nepodařilo načíst' /></div>);
        }

        // if ( && this.props.comment.constructor === Object) {
        if (this.props.recordLoading || Object.keys(this.props.record.record).length === 0) {
            return (<div className="container content-wrapper"><LoadingComponent /></div>);
        }

        return (
            <div className="container content-wrapper">
                <Helmet>
                    <title>Přidání komentáře - Drivers’ Roads</title>
                </Helmet>
                <h3 className='text-center mb-2'>
                    <FontAwesomeIcon icon={faComment} className='primary' /> &nbsp;Přidání komentáře
                </h3>
                <div className="row justify-content-center my-2">
                    <div className='col-lg-8'>
                        <div className='card'>
                            <div className='card-header'>
                                <table className="w-100">
                                    <tbody>
                                        <RecordLightItem {...this.props.record.record} />
                                    </tbody>
                                </table>
                            </div>
                            <div className='card-body'>
                                <textarea className='form-control lead' required value={this.state.text} onChange={this.handleChange} maxLength={500} />
                                <button className="btn btn-primary my-2"
                                    type="submit"
                                    onClick={() => this.props.addCommentToRecord(this.state.id, this.state.text)}>
                                    Uložit {this.props.isLoading && <LoadingComponent sizeClass='d-inline-block' iconClass='white' />}
                                </button>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchRecordPreview: (recordId) => dispatch(fetchRecordPreview(recordId)),
        addCommentToRecord: (recordId, text) => dispatch(addCommentToRecord(recordId, text))
    };
};

const mapStateToProps = (state) => {
    return {
        record: state.addComment.record,
        hasErrored: state.addComment.errored,
        isLoading: state.addComment.isLoading,
        recordLoading: state.addComment.record.isLoading,
        recordErrored: state.addComment.record.errored
    };
};

// addComment: combineReducers({
//     record: combineReducers({
//         record: commentRecordPreviewLoaded,
//         isLoading: commentRecordPreviewIsLoading,
//         errored: commentRecordPreviewErrored
//     }),
//     isLoading: commentAddLoading,
//     errored: commentAddErrored
// }),

const connected = connect(mapStateToProps, mapDispatchToProps)(AddCommentPage);
export default withRouter(connected);