import React from 'react'
import { NavLink } from 'react-router-dom';

import PublicHeaderLink from './header/PublicHeaderLink';
import { IClientConfiguration } from '../../types/types';

interface SeasonMenuItemProps {
    config: IClientConfiguration
}

const SeasonMenuItem = (configuration: SeasonMenuItemProps) => {

    if (!configuration.config.currentSeason && !configuration.config.seasons) {
        return <></>;
    }

    const seasonId = configuration.config.currentSeason.seasonId;
    const seasonName = configuration.config.currentSeason.seasonName;
    const seasonUrlKey = configuration.config.currentSeason.seasonUrlKey;

    return <li className="nav-item" data-toggle="collapse" data-target="#main-navbar.show">
        <div className="btn-group d-table nav-link w-100 current-season-item">
            {seasonId > 0 &&
                <NavLink activeClassName="nav-active-item" to={`/season/${seasonId}/${seasonUrlKey}`}>
                    {seasonName}
                </NavLink>}
            <a className="dropdown-toggle dropdown-toggle-split w-100 pt-0 season-dropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span className="sr-only">Toggle Dropdown</span>
            </a>
            <ul className="dropdown-menu">
                {configuration.config.seasons.map(o =>
                    <li>
                        <NavLink activeClassName="nav-active-item" className="dropdown-item" to={`/season/${o.seasonId}/${o.seasonUrlKey}`}>
                            {o.seasonName}
                        </NavLink>
                    </li>
                )}
            </ul>
        </div>
    </li >

    return <li data-toggle="collapse" data-target="#main-navbar.show">
        <div className="btn-group">
            <NavLink activeClassName="nav-active-item" className="nav-link float-left" to={'/test'}>
                Sezona yo
            </NavLink>
            <a className="nav-link dropdown-toggle dropdown-toggle-split px-2" id="navbarLinkDropdown2" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span className="sr-only">Toggle Dropdown</span>
            </a>
        </div>
        <div>
            <ul className="dropdown-menu">
                <li><a className="dropdown-item" href="#">Action</a></li>
                <li><div className="dropdown-divider"></div></li>
                <li><a className="dropdown-item" href="#">Separated link</a></li>
            </ul>
        </div>
    </li>

    return <li className="nav-item dropdown">
        <NavLink activeClassName="nav-active-item" className="nav-link" to={'/'}>
            Sezona yo
        </NavLink>
        <a className="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-expanded="false">        </a>
        <div className="dropdown-menu">
            <a className="dropdown-item" href="#">Action</a>
            <a className="dropdown-item" href="#">Another action</a>
            <div className="dropdown-divider"></div>
            <a className="dropdown-item" href="#">Something else here</a>
        </div>
    </li>

    // return <li className="nav-item" data-toggle="collapse" data-target="#main-navbar.show">
    // <NavLink activeClassName="nav-active-item" className="nav-link" to={location}>
    //     {text}
    // </NavLink>
    // </li>
}

export default SeasonMenuItem