import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as selectedStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as unselectedStar } from '@fortawesome/free-regular-svg-icons';

import LoadingComponent from '../../common/LoadingComponent';

const UserRating = ({ enabled, recordId, loading, userRating, onRatingClick }) => {
    if (loading) {
        return (<LoadingComponent />);
    }

    var userStars = [];
    for (let i = 1; i < 6; i++) {
        if (!enabled) {
            userStars.push(<FontAwesomeIcon style={{ color: 'gray' }} key={i} icon={unselectedStar} />);
            continue;
        }
        if (!userRating) {
            userStars.push(<FontAwesomeIcon style={{ cursor: 'pointer' }} key={i} icon={unselectedStar} onClick={() => onRatingClick(recordId, i)} />);
            continue;
        }

        if (userRating >= i) {
            userStars.push(<FontAwesomeIcon key={i} icon={selectedStar} onClick={() => onRatingClick(recordId, i)} />);
        }
        else {
            userStars.push(<FontAwesomeIcon key={i} icon={unselectedStar} onClick={() => onRatingClick(recordId, i)} />);
        }
    }
    return (<div className='user-interaction'>{userStars}</div>);
}

export default UserRating;