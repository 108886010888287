import {
    DIARY_VISITS_FETCH_SUCCESS,
    DIARY_VISITS_FETCH_LOADING,
    DIARY_VISITS_FETCH_ERRORED
} from './visitsActionTypes';

import { DriversRoadsApi, jsonAPIAuthorizedRequest, onApiError } from '../../api/apiHelper';

export function visitsFetchSuccess(visits, pagination) {
    return {
        type: DIARY_VISITS_FETCH_SUCCESS,
        visits,
        pagination
    };
}

export function visitsFetchLoading() {
    return {
        type: DIARY_VISITS_FETCH_LOADING
    };
}

export function visitsFetchErrored() {
    return {
        type: DIARY_VISITS_FETCH_ERRORED
    };
}

export function visitsFetch(query) {
    return (dispatch) => {
        dispatch(visitsFetchLoading());
        let options = jsonAPIAuthorizedRequest("GET", undefined);

        fetch(DriversRoadsApi + 'api/diary/visits?' + query, options)
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => response.json())
            .then((response) => {
                dispatch(visitsFetchSuccess(response.items, response.pagination));
            })
            .catch((e) => {
                onApiError(e);
                dispatch(visitsFetchErrored());
            });
    };
}