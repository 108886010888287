import {
    RECORD_VISIT_TOGGLE_LOADING,
    RECORD_VISIT_TOGGLE_SUCCESS,
    RECORD_VISIT_TOGGLE_ERRORED,
} from './recordVisitActionTypes';

import { DriversRoadsApi, jsonAPIAuthorizedRequest, onApiError } from '../../api/apiHelper';

function recordVisitSuccess(recordId, numberOfVisits, userVisit) {
    return {
        type: RECORD_VISIT_TOGGLE_SUCCESS,
        recordId,
        numberOfVisits,
        userVisit
    };
}

function recordVisitError(recordId) {
    return {
        type: RECORD_VISIT_TOGGLE_ERRORED,
        recordId
    };
}

function recordVisitProcessing(recordId) {
    return {
        type: RECORD_VISIT_TOGGLE_LOADING,
        recordId
    };
}

export function toggleRecordVisit(recordId, visited) {
    return (dispatch) => {
        dispatch(recordVisitProcessing(recordId));
        let options = jsonAPIAuthorizedRequest("POST");
        var url = '';
        if (visited) {
            url = DriversRoadsApi + 'api/record/visit/remove/' + recordId;
        } else {
            url = DriversRoadsApi + 'api/record/visit/add/' + recordId;
        }

        fetch(url, options)
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => response.json())
            .then((result) => {
                dispatch(recordVisitSuccess(recordId, result.count, result.user));
            })
            .catch((e) => {
                onApiError(e);
                dispatch(recordVisitError(recordId));
            });
    };
}
