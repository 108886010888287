import React from 'react';
import toastr from 'toastr';
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import userManager from '../../../utils/userManager';
import LoadingComponent from '../LoadingComponent';

class LoginHeaderLink extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false
        }

        this.onLoginClick = this.onLoginClick.bind(this);
    }

    onLoginClick() {
        this.setState({ loading: true });

        let redirectUrl = window.location.pathname;
        if (redirectUrl === '/user') {
            redirectUrl = '/diary';
        }

        userManager.signinRedirect({
            data: { redirectUrl: redirectUrl }
        }).then(o => {
            console.log('signing user ' + JSON.stringify(o))
        }).catch(o => {
            this.setState({ loading: false });
            console.error('error signing user ' + JSON.stringify(o))
            userManager.clearStaleState();
            toastr.error('Přihlášení se nezdařilo, zkuste to prosím za chvíli.')
        });
    }

    render() {

        let isLoggedIn = this.props.user != null && !this.props.user.expired;
        let userText = isLoggedIn ? this.props.user.profile.name : 'Přihlásit';

        return (
            <li className="nav-item" data-toggle="collapse" data-target="#main-navbar.show">
                <NavLink to='' className="nav-link" onClick={this.onLoginClick}>
                    {userText} {this.state.loading && <LoadingComponent sizeClass='d-inline-block'  />}
                </NavLink>
            </li>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.oidc.user,
    };
};

export default withRouter(connect(mapStateToProps, null)(LoginHeaderLink));
