import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
// import { ConnectedRouter } from 'connected-react-router';
import * as Sentry from '@sentry/browser';
import jwt_decode from "jwt-decode";

import { loadUser } from "redux-oidc";

import { fetchConfiguration } from '../actions/configurationActionCreators';
import history from '../store/configureHistory';

import Header from './common/Header';
import Footer from './common/Footer';
import NotFoundPage from './common/NotFoundPage';
import PrivateRoute from './common/PrivateRoute';

import Home from './home/HomePage';
import AtlasPage from './atlas/AtlasPage';
import DatabasePage from './database/DatabasePage';

import CommentPage from './comment/CommentPage';
import AddCommentPage from './comment/AddCommentPage';

import PlacePage from './place/PlacePage';
import RoadPage from './road/RoadPage';

import AddPlacePage from './place/AddPlacePage';
import AddRoadPage from './road/AddRoadPage';

import UserComponent from "./user/UserComponent";
import LoginPage from './user/LoginPage';
import DiaryComponent from './diary/DiaryComponent';

import CallbackPage from './common/CallbackPage';
import SignoutComponent from './user/SignoutComponent';

import ErrorComponent from './common/ErrorComponent';
import EnvironmentText from './common/EnvironmentText';

import userManager from "../utils/userManager";

import store from '../store/configureStore';
import LoadingComponent from './common/LoadingComponent';
import SeasonRouter from './season/SeasonRouter';

class App extends React.Component {

  componentDidMount() {
    this.props.fetchConfiguration();

    console.log('Application is starting - loading user');
    loadUser(store, userManager).then(o => {
      if (!o) {
        console.log('User was not found - starting app without user');
        return;
      }

      let decoded = jwt_decode(o.access_token);
      if (decoded.iss === 'https://www.autozka.com' || o.refresh_token === undefined) {
        userManager.removeUser();
        userManager.clearStaleState();
        return;
      }

      if (!o.expired) {
        console.log('User was found and is ready - not expired');
      }
    }).catch(e => {
      console.log('user was not loaded - starting application without user');
      console.error(e);
      throw (e)
    });
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      Sentry.captureException(error);
    });
  }

  render() {
    if (this.props.configuration.errored) {
      return (<div className='vertical-center-div'>
        <ErrorComponent message="Aplikaci nelze spustit, zkuste to prosím za chvíli" />
      </div>);
    }

    let isLoading = this.props.oidc.isLoadingUser || this.props.configuration.isLoading;
    if (isLoading) {
      return <div className='h-100 w-100'>
        <LoadingComponent sizeClass={'fs-3 vertical-center-div'} />
      </div>
    }

    return (
      // <ConnectedRouter history={history}>
      <BrowserRouter>
        <div className="d-flex flex-column h-100">
          <Header />
          <div className={'menu-offset'}>
            <Switch>
              <Route exact={true} path='/' component={Home} />

              <Route path='/season/:seasonId/:seasonUrlKey' component={SeasonRouter} />

              <Route path='/atlas' component={AtlasPage} />
              <Route path='/database' component={DatabasePage} />

              <Route path="/road/:id" component={RoadPage} />
              <Route path="/place/:id" component={PlacePage} />

              <PrivateRoute path="/add-road"><AddRoadPage /></PrivateRoute>
              <PrivateRoute path="/add-place"><AddPlacePage /></PrivateRoute>

              <Route path="/comment/:id" component={CommentPage} />
              <PrivateRoute path="/add-comment/:id"><AddCommentPage /></PrivateRoute>

              <PrivateRoute path="/diary"><DiaryComponent /></PrivateRoute>

              <Route path="/login" component={LoginPage} />
              <PrivateRoute path="/user"><UserComponent /></PrivateRoute>
              <PrivateRoute path="/sign-out"><SignoutComponent /></PrivateRoute>
              <Route path="/callback" component={CallbackPage} />

              <Route component={NotFoundPage} />
            </Switch>
          </div>
          <Footer />
        </div>
      </BrowserRouter>
      // </ConnectedRouter >
    )
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchConfiguration: () => dispatch(fetchConfiguration()),
  };
};

const mapStateToProps = (state) => {
  return {
    oidc: state.oidc,
    configuration: state.configuration
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
