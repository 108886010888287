class MapWeb {
    constructor(bounds) {
        this.step = 1;
        this.web = {};

        this.allowedBounds = bounds;

        // this.worldBounds = {
        //     maxLat: 72,
        //     minLat: -57,
        //     maxLng: 180,
        //     minLng: -180
        // };
        // this.allowedBounds = {
        //     maxLat: 52,
        //     minLat: 47,
        //     maxLng: 23,
        //     minLng: 12
        // }

        this.rebuildWeb();
    }

    isInAllowedBounds(map) {
        return map.minLongitude <= this.allowedBounds.maxLng &&
            map.maxLongitude >= this.allowedBounds.minLng &&
            map.minLatitude <= this.allowedBounds.maxLat &&
            map.maxLatitude >= this.allowedBounds.minLat
    }

    rebuildWeb() {
        this.web = {};

        for (let lat = this.allowedBounds.minLat; lat <= this.allowedBounds.maxLat; lat = lat + this.step) {
            this.web[lat] = [];

            for (let lng = this.allowedBounds.minLng; lng <= this.allowedBounds.maxLng; lng = lng + this.step) {
                this.web[lat][lng] = { loaded: false };
            }
        }
    }

    getWebIndex(value) {
        return value - value % this.step;
    }

    getIndexesFromMap(map) {
        let minLatIndex = Math.max(this.getWebIndex(map.minLatitude), this.allowedBounds.minLat);
        let minLngIndex = Math.max(this.getWebIndex(map.minLongitude), this.allowedBounds.minLng);
        let maxLatIndex = Math.min(this.getWebIndex(map.maxLatitude) + this.step, this.allowedBounds.maxLat);
        let maxLngIndex = Math.min(this.getWebIndex(map.maxLongitude) + this.step, this.allowedBounds.maxLng);

        return {
            minLatIndex,
            minLngIndex,
            maxLatIndex,
            maxLngIndex
        }
    }

    setLoadedBounds(map) {
        let indexes = this.getIndexesFromMap(map);

        for (let lat = indexes.minLatIndex; lat <= indexes.maxLatIndex; lat = lat + this.step) {
            for (let lng = indexes.minLngIndex; lng <= indexes.maxLngIndex; lng = lng + this.step) {
                let tile = this.web[lat][lng];
                if (!tile) {
                    debugger;
                }
                tile.loaded = true;
            }
        }
    }

    isLoaded(map) {
        let indexes = this.getIndexesFromMap(map);

        let isLoaded = true;
        let minLatitude = -85;
        let maxLatitude = 85;
        let minLongitude = -180;
        let maxLongitude = 180;

        for (let lat = indexes.minLatIndex; lat <= indexes.maxLatIndex; lat = lat + this.step) {
            for (let lng = indexes.minLngIndex; lng <= indexes.maxLngIndex; lng = lng + this.step) {
                let tile = this.web[lat][lng];
                if (tile.loaded) {
                    continue;
                }

                isLoaded = false;
                minLatitude = Math.min(minLatitude, lat);
                maxLatitude = Math.max(maxLatitude, lat + this.step);
                minLongitude = Math.min(minLongitude, lng);
                maxLongitude = Math.max(maxLongitude, lng + this.step);
            }
        }

        let result = {
            minLatitude: Math.max(minLatitude, this.allowedBounds.minLat),
            minLongitude: Math.max(minLongitude, this.allowedBounds.minLng),
            maxLatitude: Math.min(maxLatitude, this.allowedBounds.maxLat),
            maxLongitude: Math.min(maxLongitude, this.allowedBounds.maxLng),
            isLoaded
        };

        return result;
    }
}

export default MapWeb;