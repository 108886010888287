export function getPagination(page, itemsOnPage, totalItems) {
    let totalPages = Math.ceil(totalItems / itemsOnPage);
    let startPage = 1;
    let endPage = totalPages;

    if (totalPages <= 9) {
        return {
            previousActive: startPage !== page,
            nextActive: endPage !== page,
            activePage: page,
            start: startPage,
            end: endPage
        };
    }

    if (page <= 5) {
        startPage = 1;
        endPage = 9;
    }
    else if (page + 5 >= endPage) {
        startPage = endPage - 9;
        endPage = endPage;
    }
    else {
        startPage = page - 4;
        endPage = page + 4;
    }

    return {
        previousActive: startPage !== page,
        nextActive: endPage !== page,
        activePage: page,
        start: startPage,
        end: endPage
    };
}

export function getPaginationSummary(page, itemsOnPage, totalItems) {
    let totalPages = Math.ceil(totalItems / itemsOnPage);
    let start = itemsOnPage * (page - 1) + 1;
    let end = (page) * itemsOnPage;

    if (totalPages === page) {
        end = totalItems;
    }

    return {
        start,
        end
    };
}