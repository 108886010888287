import {
    RECORD_FETCH_SUCCESS,
    RECORD_FETCH_LOADING,
    RECORD_FETCH_ERRORED
} from '../actions/record/recordActionTypes';

import {
    RECORD_FAVOURITE_TOGGLE_SUCCESS,
    RECORD_FAVOURITE_TOGGLE_ERRORED,
    RECORD_FAVOURITE_TOGGLE_LOADING
} from '../actions/record/recordFavouriteActionTypes';

import {
    RECORD_VISIT_TOGGLE_SUCCESS,
    RECORD_VISIT_TOGGLE_ERRORED,
    RECORD_VISIT_TOGGLE_LOADING
} from '../actions/record/recordVisitActionTypes';

import {
    RECORD_RATING_LOADING,
    RECORD_RATING_SUCCESS,
    RECORD_RATING_ERRORED
} from '../actions/record/recordRatingActionTypes';

import {
    RECORD_PHOTO_ADD_SUCCESS,
    RECORD_PHOTO_ADD_LOADING
} from '../actions/record/recordPhotoActionTypes';

import {
    RECORD_PHOTO_DELETE_SUCCESS
} from '../actions/record/recordPhotoActionTypes';

import {
    RECORD_MAP_EDIT_IS_LOADING,
    RECORD_MAP_EDIT_ERRORED,

    PLACE_MAP_EDIT_SUCCESS,
    ROAD_MAP_EDIT_SUCCESS
} from '../actions/record/recordEditActionTypes'

import {
    RECORD_EDIT_LOADING,
    RECORD_EDIT_SUCCESS,
    // RECORD_EDIT_ERRROED
} from '../actions/record/recordEditActionTypes';

import {
    DELETE_RECORD_SUCCESS
} from '../actions/record/recordOwnerActionTypes';

import { generateChars } from '../utils/randomHelper';

export function recordLoaded(state = {}, action) {
    switch (action.type) {
        case RECORD_FETCH_SUCCESS:
            return action.record;
        case DELETE_RECORD_SUCCESS:
            if (state.id === action.recordId) {
                return Object.assign({}, state, {
                    isDeleted: true
                });
            }
            return state;
        case RECORD_EDIT_LOADING:
            if (state.id === action.recordId) {
                return Object.assign({}, state, {
                    descriptionEditLoading: true
                });
            }
            return state;
        case RECORD_EDIT_SUCCESS:
            if (state.id === action.recordId) {
                return Object.assign({}, state, {
                    descriptionEditLoading: false,
                    description: action.text
                });
            }
            return state;

        case RECORD_MAP_EDIT_IS_LOADING:
            if (state.id === action.recordId) {
                return Object.assign({}, state, {
                    mapIsEditing: true
                });
            }
            return state;
        case RECORD_MAP_EDIT_ERRORED:
            if (state.id === action.recordId) {
                return Object.assign({}, state, {
                    mapIsEditing: false
                });
            }
            return state;
        case PLACE_MAP_EDIT_SUCCESS:
            if (state.id === action.recordId) {
                let reloadId = generateChars(3);
                return Object.assign({}, state, {
                    photoIds: state.photoIds.map(
                        (photo, i) => i === 0 ? photo + "?" + reloadId : photo
                    ),
                    locationName: action.data.startLocationTitle,
                    startPoint: {
                        lat: action.data.startLocation.lat,
                        lng: action.data.startLocation.lng
                    },

                    mapIsEditing: false
                });
            }
            return state;
        case ROAD_MAP_EDIT_SUCCESS:
            if (state.id === action.recordId) {
                let waypoints = action.data.waypoints;
                let reloadId = generateChars(3);
                return Object.assign({}, state, {
                    photoIds: state.photoIds.map(
                        (photo, i) => i === 0 ? photo + "?" + reloadId : photo
                    ),
                    locationName: action.data.startLocationTitle,
                    destinationName: action.data.destLocationTitle,
                    distance: action.data.distanceMeters,
                    mapData: {
                        start: {
                            lat: waypoints[0].lat,
                            lng: waypoints[0].lng
                        },
                        end: {
                            lat: waypoints[waypoints.length - 1].lat,
                            lng: waypoints[waypoints.length - 1].lng
                        },
                        waypoints: waypoints.slice(1, waypoints.length - 1)
                    },
                    mapIsEditing: false
                });
            }
            return state;
        case RECORD_FAVOURITE_TOGGLE_LOADING:
            if (state.id === action.recordId) {
                return Object.assign({}, state, {
                    favouriteLoading: true
                });
            }
            return state;
        case RECORD_FAVOURITE_TOGGLE_ERRORED:
            if (state.id === action.recordId) {
                return Object.assign({}, state, {
                    favouriteLoading: false
                });
            }
            return state;
        case RECORD_FAVOURITE_TOGGLE_SUCCESS:
            if (state.id === action.recordId) {
                return Object.assign({}, state, {
                    userFavourite: action.userFavourite,
                    numberOfFavourites: action.numberOfFavourites,
                    favouriteLoading: false
                });
            }
            return state;

        case RECORD_RATING_LOADING:
            if (state.id === action.recordId) {
                return Object.assign({}, state, {
                    ratingLoading: true
                })
            }
            return state;
        case RECORD_RATING_ERRORED:
            if (state.id === action.recordId) {
                return Object.assign({}, state, {
                    ratingLoading: false
                })
            }
            return state;
        case RECORD_RATING_SUCCESS:
            if (state.id === action.recordId) {
                return Object.assign({}, state, {
                    rating: action.rating,
                    numberOfRatings: action.numberOfRatings,
                    userRating: action.userRating,
                    ratingLoading: false
                })
            }
            return state;

        case RECORD_VISIT_TOGGLE_LOADING:
            if (state.id === action.recordId) {
                return Object.assign({}, state, {
                    visitLoading: true
                })
            }
            return state;
        case RECORD_VISIT_TOGGLE_ERRORED:
            if (state.id === action.recordId) {
                return Object.assign({}, state, {
                    visitLoading: false
                });
            }
            return state;
        case RECORD_VISIT_TOGGLE_SUCCESS:
            if (state.id === action.recordId) {
                return Object.assign({}, state, {
                    userVisit: action.userVisit,
                    numberOfVisits: action.numberOfVisits,
                    visitLoading: false
                })
            }
            return state;

        case RECORD_PHOTO_DELETE_SUCCESS:
            if (state.id === action.recordId) {
                return Object.assign({}, state, {
                    photoIds: state.photoIds.filter((item) => item !== action.photoId)
                });
            }
            return state;

        case RECORD_PHOTO_ADD_SUCCESS:
            if (state.id === action.recordId) {
                return Object.assign({}, state, {
                    photoIds: state.photoIds.map((item) => {
                        if (item.loadingPhotoId !== action.loadingPhotoId) {
                            return item;
                        }

                        return action.photoId;
                    })
                });
            }
            return state;
        case RECORD_PHOTO_ADD_LOADING:
            if (state.id === action.recordId) {
                return Object.assign({}, state, {
                    photoIds: [...state.photoIds, { loadingPhotoId: action.loadingPhotoId }]
                });
            }
        default:
            return state;
    }
}

export function recordIsLoading(state = false, action) {
    switch (action.type) {
        case RECORD_FETCH_LOADING:
            return true;
        case RECORD_FETCH_ERRORED:
        case RECORD_FETCH_SUCCESS:
            return false;
        default:
            return state;
    }
}

export function recordErrored(state = false, action) {
    switch (action.type) {
        case RECORD_FETCH_ERRORED:
            return true;
        case RECORD_FETCH_LOADING:
        case RECORD_RATING_SUCCESS:
            return false;
        default:
            return state;
    }
}