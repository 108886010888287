import IpApiHelper from './IpApiHelper';
import GoogleApiHelper from './GoogleApiHelper';

class LocationHelper {

    static locateAddress(onSuccess, onError) {
        if (navigator.geolocation) {
            this.locate()
                .then(location => GoogleApiHelper.geocodeLocation(location.lng, location.lat))
                .then(address => onSuccess(address))
                .catch(e => {
                    IpApiHelper.locate()
                        .then(location => GoogleApiHelper.geocodeLocation(location.lng, location.lat))
                        .then(address => onSuccess(address))
                        .catch(onError);
                });

        }
        else {
            IpApiHelper.locate()
                .then(location => GoogleApiHelper.geocodeLocation(location.lng, location.lat))
                .then(address => onSuccess(address))
                .catch(onError);
        }
    }

    static locateGPS(onSuccess, onError) {
        if (navigator.geolocation) {
            this.locate()
                .then(location => onSuccess({ lat: location.lat, lng: location.lng }))
                .catch(e => {
                    IpApiHelper.locate()
                        .then(result => onSuccess({ lat: result.lat, lng: result.lng }))
                        .catch(onError);
                });
        }
        else {
            IpApiHelper.locate()
                .then(result => onSuccess({ lat: result.lat, lng: result.lng }))
                .catch(onError);
        }
    }

    static watchPosition(onSuccess, onError) {
        if (navigator.geolocation) {
            return navigator.geolocation.watchPosition((result) => {
                let lng = result.coords.longitude;
                let lat = result.coords.latitude;
                console.log(`watch me pos ${result.coords.longitude} ${result.coords.latitude}`);
                onSuccess({ lng, lat });
            }, (e) => {
                console.error(e);
                onError(e);
            }, { timeout: 8000 });
        }
    }

    static clearWatchPosition(watchId) {
        if (navigator.geolocation) {
            return navigator.geolocation.clearWatch(watchId);
        }
    }

    static locate() {
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition((result) => {
                let lng = result.coords.longitude;
                let lat = result.coords.latitude;
                resolve({ lng, lat });
            }, (e) => {
                console.error(e);
                reject(e);
            }, { enableHighAccuracy: true, maximumAge: Infinity, timeout: 60000 });
        });
    }
}

export default LocationHelper;