import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const ErrorComponent = ({ message, onRetryClick }) => (
    <section className="text-center" >
        <h1>
            <FontAwesomeIcon icon={faExclamationTriangle} />
        </h1>
        <p className="lead text-muted my-2">
            {message}
        </p>
        {onRetryClick ? <button className="action-icon text-purple">
            <FontAwesomeIcon icon={faSync} onClick={onRetryClick} />
        </button> : ""}
    </section >);

export default ErrorComponent;