import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import combinedReducers from '../reducers/combinedReducers';

// import history from './configureHistory';
// import { routerMiddleware } from 'connected-react-router';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const initialState = {};

const createStoreWithMiddleware = composeEnhancers(
    applyMiddleware(thunk)
    // , applyMiddleware(routerMiddleware(history))
)(createStore);

const store = createStoreWithMiddleware(combinedReducers, initialState);

export default store;