import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faBook, faComment } from '@fortawesome/free-solid-svg-icons';

import HomeAccount from './HomeAccount';

class HomeCommunity extends React.Component {
    render() {
        return (
            <div className="home-community d-flex flex-column">
                <div className="cover-bottom">
                    <div className='container p-3 py-5'>
                        <h2 className='homepage-h2'>Zapojte se do komunity</h2>
                        <div className="row justify-content-cente py-3 text-center">
                            <div className="col-md-4">
                                <FontAwesomeIcon icon={faPlusCircle} size={'2x'} className={'my-2'} />
                                <h3>Přidávejte</h3>
                                <p>Silnice</p>
                                <p>Místa</p>
                            </div>
                            <div className="col-md-4">
                                <FontAwesomeIcon icon={faComment} size={'2x'} className={'my-2'} />
                                <h3>Recenzujte</h3>
                                <p>Hodnocení</p>
                                <p>Komentáře</p>
                            </div>
                            <div className="col-md-4">
                                <FontAwesomeIcon icon={faBook} size={'2x'} className={'my-2'} />
                                <h3>Veďte si deník</h3>
                                <p>Oblíbené</p>
                                <p>Navštívené</p>
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <HomeAccount />
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

export default HomeCommunity;