import {
    DIARY_RECORDS_FETCH_SUCCESS,
    DIARY_RECORDS_FETCH_LOADING,
    DIARY_RECORDS_FETCH_ERRORED
} from './recordsActionTypes';

import { DriversRoadsApi, jsonAPIAuthorizedRequest, onApiError } from '../../api/apiHelper';

function recordsFetchSuccess(records, pagination) {
    return {
        type: DIARY_RECORDS_FETCH_SUCCESS,
        records,
        pagination
    };
}

function recordsFetchLoading() {
    return {
        type: DIARY_RECORDS_FETCH_LOADING
    };
}

function recordsFetchErrored() {
    return {
        type: DIARY_RECORDS_FETCH_ERRORED
    };
}

export function recordsFetch(query) {
    return (dispatch) => {
        dispatch(recordsFetchLoading());
        let options = jsonAPIAuthorizedRequest("GET", undefined);

        fetch(DriversRoadsApi + 'api/diary/records?' + query, options)
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => response.json())
            .then((response) => {
                dispatch(recordsFetchSuccess(response.items, response.pagination));
            })
            .catch((e) => {
                onApiError(e);
                dispatch(recordsFetchErrored());
            });
    };
}