var prefix = 'fas';
var iconName = 'place';
var width = 512;
var height = 512;
var ligatures = [];
var unicode = 'fffc';
var svgPathData = 'M390.1,135.4c0-74.1-60-134.1-134.1-134.1s-134.1,60-134.1,134.1c0,106.1,134.1,263.2,134.1,263.2S390.1,241,390.1,135.4z M189.8,127.4c0-36.5,29.6-66.2,66.2-66.2s66.2,29.6,66.2,66.2s-29.6,66.2-66.2,66.2S189.8,163.9,189.8,127.4z M256,94.9c18,0,32.5,14.6,32.5,32.5s-14.6,32.5-32.5,32.5s-32.5-14.6-32.5-32.5S238,94.9,256,94.9zM331.8,351.9c-10.9,15.8-21.2,29.6-29.7,40.6c84.7,5.1,140.3,23.4,160.9,37c-24.2,16-96.1,38.4-207,38.4c-110.9,0-182.8-22.4-207-38.4c20.7-13.6,76.2-31.9,160.8-37c-8.6-11-18.8-24.8-29.8-40.6C75.7,362.2,0,393,0,429.5c0,44.9,114.6,81.2,256,81.2c141.4,0,256-36.4,256-81.2C512,393,436.2,362.2,331.8,351.9z';

export const faPlace = {
    // Use a prefix like 'fac' that doesn't conflict with a prefix in the standard Font Awesome styles
    // (So avoid fab, fal, fas, far, fa)
    prefix: prefix,
    iconName: iconName,
    icon: [
        width,
        height,
        ligatures,
        unicode,
        svgPathData
    ]
};