import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Helmet } from "react-helmet";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart, faComment, faStar } from '@fortawesome/free-solid-svg-icons';
import { faWheel } from '../../icons/wheel';
import { faPlace } from '../../icons/place';
import { faRoad } from '../../icons/road';

import { diaryFetch } from '../../actions/diary/diaryActionCreators';
import LoadingComponent from '../common/LoadingComponent';

import FavouriteList from '../favourite/FavouriteList';
import VisitList from '../visits/VisitList';
import RatingList from '../ratings/RatingList';
import CommentLightList from '../comment/lightList/CommentLightList';
import RecordLightList from '../record/lightList/RecordLightList';

class DiaryOverViewPage extends React.Component {

    componentDidMount() {
        this.props.fetchDiary();
    }

    render() {
        if (this.props.isLoading) {
            return (<div className='container content-wrapper'><LoadingComponent /></div>);
        }

        return (
            <div className='content-wrapper'>
                <Helmet>
                    <title>Deník - Drivers’ Roads</title>
                </Helmet>
                <div className='container'>
                    <div className="row justify-content-center">
                        <div className='col-lg-8'>
                            <table className='w-100'>
                                <tbody>
                                    <tr>
                                        <td>
                                            <h5 className="d-inline-block">
                                                Moje cesty a místa&nbsp;
                                                </h5>
                                            <NavLink to={'/diary/records'}>vše</NavLink>
                                        </td>
                                        <td className='text-right'>
                                            <NavLink className="btn btn-primary map-button m-1" to='/add-road' title='Přidat silnici'>
                                                <FontAwesomeIcon icon={faRoad} color={'white'} /> Přidat silnici
                                            </NavLink>
                                            <NavLink className="btn btn-primary map-button m-1" to='/add-place' title='Přidat místo'>
                                                <FontAwesomeIcon icon={faPlace} color={'white'} /> Přidat místo
                                            </NavLink>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="table-responsive-lg">
                                <RecordLightList records={this.props.diary.records} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='bg-light my-3 py-3'>
                    <div className='container'>
                        <div className="row justify-content-center">
                            <div className='col-md-6'>
                                <h5 className="d-inline-block"><FontAwesomeIcon icon={faHeart} className='primary' /> Oblíbené&nbsp;</h5><NavLink to={'/diary/favourites'}>vše</NavLink>
                                <FavouriteList favourites={this.props.diary.favourites} />
                            </div>
                            <div className='col-md-6'>
                                <h5 className="d-inline-block"><FontAwesomeIcon icon={faWheel} className='primary' /> Projeto&nbsp;</h5><NavLink to={'/diary/visits'}>vše</NavLink>
                                <VisitList visits={this.props.diary.visits} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='container'>
                    <div className="row justify-content-center">
                        <div className='col-md-6'>
                            <h5 className="d-inline-block"><FontAwesomeIcon icon={faComment} className='primary' /> Komentáře&nbsp;</h5><NavLink to={'/diary/comments'}>vše</NavLink>
                            <CommentLightList comments={this.props.diary.comments} />
                        </div>
                        <div className='col-md-6'>
                            <h5 className="d-inline-block"><FontAwesomeIcon icon={faStar} className='primary' /> Hodnocení&nbsp;</h5><NavLink to={'/diary/ratings'}>vše</NavLink>
                            <RatingList ratings={this.props.diary.ratings} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchDiary: () => dispatch(diaryFetch())
    };
}

const mapStateToProps = (state) => {
    return {
        diary: state.diary.diary,
        errored: state.diary.errored,
        isLoading: state.diary.isLoading
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DiaryOverViewPage);