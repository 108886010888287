import React from 'react';
import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment } from '@fortawesome/free-solid-svg-icons';

import { toggleRecordFavourite } from '../../actions/record/recordFavouriteActionCreators';
import { toggleRecordVisit } from '../../actions/record/recordVisitActionCreator';
import { updateRecordRating } from '../../actions/record/recordRatingActionCreators';

import Favourites from './helper/Favourites';
import Visits from './helper/Visits';
import UserRating from './helper/UserRating';
import TotalRating from './helper/TotalRating';

import LoadingComponent from '../common/LoadingComponent';

class RecordHeader extends React.Component {

    render() {
        let record = this.props.record;
        let startArray = record.locationName.split(",");

        let destArray = ["", ""];
        if (record.destinationName) {
            destArray = record.destinationName.split(",");
        }

        return (<div className="row">
            <div className="col-sm-3 text-center">
                {this.props.editing ?
                    <LoadingComponent /> :
                    record.destinationName ? <div className='no-margin'>
                        <p>{startArray[0]}, {startArray[1]}</p>
                        <p>{destArray[0]}, {destArray[1]}</p>
                    </div> :
                        <div className='no-margin'>
                            <p>{startArray[0]}, {startArray[1]}</p>
                        </div>
                }
            </div>
            <div className="col-sm-1 text-center">
                <Favourites recordId={record.id}
                    loading={record.favouriteLoading}
                    userFavourite={record.userFavourite}
                    numberOfFavourites={record.numberOfFavourites}
                    enabled={this.props.logged}
                    onClick={this.props.toggleRecordFavourite} />
            </div>
            <div className="col-sm-1 text-center">
                <Visits recordId={record.id}
                    loading={record.visitLoading}
                    userVisited={record.userVisit}
                    numberOfVisits={record.numberOfVisits}
                    enabled={this.props.logged}
                    onClick={this.props.toggleRecordVisit} />
            </div>
            <div className="col-sm-1 text-center">
                <FontAwesomeIcon icon={faComment} />
                <div>{record.numberOfComments}</div>
            </div>
            <div className="col-sm-3 text-center">
                <UserRating recordId={record.id}
                    loading={record.ratingLoading}
                    userRating={record.userRating}
                    enabled={this.props.logged}
                    onRatingClick={this.props.updateRecordRating} />
                Tvoje hodnocení {record.userRating}
            </div>
            <div className="col-sm-3 text-center">
                <TotalRating totalRating={record.rating} />
                Celkově {Math.round(record.rating * 100) / 100} {record.numberOfRatings}x
            </div>
        </div>);
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleRecordFavourite: (id, isFavourite) => dispatch(toggleRecordFavourite(id, isFavourite)),
        toggleRecordVisit: (id, visited) => dispatch(toggleRecordVisit(id, visited)),
        updateRecordRating: (id, rating) => dispatch(updateRecordRating(id, rating))
    };
};


export default connect(null, mapDispatchToProps)(RecordHeader);