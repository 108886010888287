import React from 'react';
import { NavLink } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock } from '@fortawesome/free-solid-svg-icons';

const PrivateHeaderLink = ({ location, text, disabled }) => {

    if (disabled) {
        return (
            <li className="nav-item">
                <p className="nav-link disabled">{text} <FontAwesomeIcon icon={faLock} /></p>
            </li>
        );
    }

    return (
        <li className="nav-item" data-toggle="collapse" data-target="#main-navbar.show">
            <NavLink activeClassName="nav-active-item" className="nav-link" to={location}>{text}</NavLink>
        </li>
    );
};

export default PrivateHeaderLink;