import React from 'react';
import Oidc from 'oidc-client';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { OidcProvider } from "redux-oidc";

import '../node_modules/bootstrap/dist/js/bootstrap';

// import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import "../node_modules/toastr/build/toastr.min.css";
import '../node_modules/@fortawesome/fontawesome-svg-core/styles.css';

import "../node_modules/slick-carousel/slick/slick.css";
import "../node_modules/slick-carousel/slick/slick-theme.css";

import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';

import './styles/styles.css';
import './styles/css/season.css';

import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

import App from './components/App';
import { unregister } from './registerServiceWorker';

import store from './store/configureStore';
import userManager from "./utils/userManager";
import detectIE from "./utils/BrowserHelper";
import { configureSentry } from './configuration'

if (detectIE() !== false) {
  var div = document.getElementById('app');
  div.innerHTML += '<p>Internet Explorer není podporovaný, využijte prosím jiný prohlížeč, například Google Chrome či Firefox</p>';
} else {
  Oidc.Log.logger = console;
  configureSentry();

  render(<Provider store={store}>
    <OidcProvider store={store} userManager={userManager}>
      <App />
    </OidcProvider>
  </Provider>, document.getElementById('app'));

  unregister();
}