import {
    DIARY_FAVOUTRITES_FETCH_SUCCESS,
    DIARY_FAVOUTRITES_FETCH_LOADING,
    DIARY_FAVOUTRITES_FETCH_ERRORED
} from '../../actions/diary/favouritesActionTypes';

export function favouritesLoaded(state = [], action) {
    switch (action.type) {
        case DIARY_FAVOUTRITES_FETCH_SUCCESS:
            return action.favourites;
        case DIARY_FAVOUTRITES_FETCH_ERRORED:
            return [];
        default:
            return state;
    }
}

const defaultPagination = {
    items: 5,
    page: 1
};

export function favouritesPagination(state = defaultPagination, action) {
    switch (action.type) {
        case DIARY_FAVOUTRITES_FETCH_SUCCESS:
            return action.pagination;
        default:
            return state;
    }
}

export function favouritesLoading(state = false, action) {
    switch (action.type) {
        case DIARY_FAVOUTRITES_FETCH_LOADING:
            return true;
        case DIARY_FAVOUTRITES_FETCH_ERRORED:
        case DIARY_FAVOUTRITES_FETCH_SUCCESS:
            return false;
        default:
            return state;
    }
}

export function favouritesErrored(state = false, action) {
    switch (action.type) {
        case DIARY_FAVOUTRITES_FETCH_ERRORED:
            return true;
        case DIARY_FAVOUTRITES_FETCH_LOADING:
        case DIARY_FAVOUTRITES_FETCH_SUCCESS:
            return false;
        default:
            return state;
    }
}