import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import { saveRoad } from '../../actions/record/recordAddActionCreators';

import RoadSetupForm from './RoadSetupForm';
import RoadEditMap from '../map/RoadEditMap';
import EditRoadFields from './EditRoadFields';
import LoadingComponent from '../common/LoadingComponent';
import AddRoadGuidePanel from './AddRoadGuidePanel';

const GetInitState = () => {
  return {
    setupData: {
      avoidHighways: false,
      isPrivate: false,
      description: '',
      name: '',

      canSave: false,

      countryCode: '',
      distanceKm: 0,
      startLocation: '',
      startLocationGPS: '',
      startLocationLatLng: '',
      destLocation: '',
      destLocationGPS: '',
      destLocationLatLng: '',
      previewUrl: ''
    },
    flowData: {
      selectingStart: false,
      selectingFinish: false
    },
    roadData: {
      waypoints: []
    }
  };
}

class AddRoadPage extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = GetInitState();

    this.handleChange = this.handleChange.bind(this);
    this.selectStart = this.selectStart.bind(this);
    this.selectFinish = this.selectFinish.bind(this);
    this.startSelected = this.startSelected.bind(this);
    this.finishSelected = this.finishSelected.bind(this);
    this.reset = this.reset.bind(this);
    this.setWaypoints = this.setWaypoints.bind(this);
    this.setPreviewUrl = this.setPreviewUrl.bind(this);
    this.saveOnServer = this.saveOnServer.bind(this);
  }

  setPreviewUrl(url) {
    let setupData = this.state.setupData;
    setupData.previewUrl = url;

    this.setState({ setupData: setupData });
  }

  saveOnServer() {
    let waypoints = []
    let start = {
      lat: this.state.setupData.startLocationGPS.lat(),
      lng: this.state.setupData.startLocationGPS.lng()
    }
    waypoints.push(start);
    for (let i = 0; i < this.state.roadData.waypoints.length; i++) {
      let point = {
        lat: this.state.roadData.waypoints[i].lat(),
        lng: this.state.roadData.waypoints[i].lng()
      }
      waypoints.push(point);
    }

    let end = {
      lat: this.state.setupData.destLocationGPS.lat(),
      lng: this.state.setupData.destLocationGPS.lng()
    }
    waypoints.push(end);

    let result = JSON.stringify(this.state.roadData.result);
    let newRoadDto = {
      startLocationTitle: this.state.setupData.startLocation,
      destLocationTitle: this.state.setupData.destLocation,
      waypoints: waypoints,
      countryCode: this.state.setupData.countryCode,
      title: this.state.setupData.name,
      description: this.state.setupData.description,
      distanceMeters: this.state.setupData.distanceKm,
      avoidHighways: this.state.setupData.avoidHighways,
      isPrivate: this.state.setupData.isPrivate,
      mapUrl: this.state.setupData.previewUrl,
      directionsResult: result
    };
    //validation

    this.props.saveRecord(newRoadDto);
    //CALL ON PROPS
  }

  setWaypoints(result, start, startLocation, end, endLocation, waypoints, distance, countryCode) {

    var setupData = this.state.setupData;
    setupData.startLocation = start;
    setupData.destLocation = end;
    setupData.countryCode = countryCode;
    setupData.startLocationGPS = startLocation;
    setupData.destLocationGPS = endLocation;
    setupData.startLocationLatLng = startLocation.toString();
    setupData.destLocationLatLng = endLocation.toString();
    setupData.distanceKm = distance;

    setupData.canSave =
      setupData.startLocation !== '' &&
      setupData.startLocationLatLng !== '' &&
      setupData.countryCode !== '' &&
      setupData.destLocation !== '' &&
      setupData.destLocationLatLng !== '' &&
      setupData.name !== '';

    var roadData = this.state.roadData;
    roadData.waypoints = waypoints;
    roadData.result = result;

    this.setState({
      setupData: setupData,
      roadData: roadData
    });
  }

  reset(e) {
    e.preventDefault();
    let initState = GetInitState();
    this.setState(initState);
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    var setupData = this.state.setupData;
    setupData[name] = value;
    setupData.canSave =
      setupData.startLocation !== '' &&
      setupData.startLocationLatLng !== '' &&
      setupData.countryCode !== '' &&
      setupData.destLocation !== '' &&
      setupData.destLocationLatLng !== '' &&
      setupData.name !== '';

    this.setState({ setupData });
  }

  selectStart() {
    this.setState({
      flowData: {
        selectingStart: true,
        selectingFinish: false
      }
    });
  }

  selectFinish() {
    this.setState({
      flowData: {
        selectingFinish: true,
        selectingStart: false
      }
    });
  }

  startSelected(location, countryCode) {
    var setupData = this.state.setupData;
    setupData.startLocation = location;
    setupData.countryCode = countryCode;
    var flowData = this.state.flowData;
    flowData.selectingStart = false;
    flowData.selectingFinish = false;
    this.setState({ setupData, flowData });
  }

  finishSelected(location) {
    var setupData = this.state.setupData;
    setupData.destLocation = location;
    var flowData = this.state.flowData;
    flowData.selectingStart = false;
    flowData.selectingFinish = false;
    this.setState({ setupData, flowData });
  }

  render() {
    return (
      <div className='container content-wrapper'>
        <Helmet>
          <title>Přidání nové cesty - Drivers’ Roads</title>
        </Helmet>
        <h3 className='text-center'>
          <span className='record-type'>
            <img alt='Road' src="/img/road-marker.png" />
          </span>
          &nbsp;Přidání nové cesty
        </h3>
        <AddRoadGuidePanel
          selectingStart={this.state.flowData.selectingStart}
          startLocation={this.state.setupData.startLocation}
          selectingFinish={this.state.flowData.selectingFinish}
          destLocation={this.state.setupData.destLocation}
          name={this.state.setupData.name}
          description={this.state.setupData.description} />
        <RoadSetupForm
          avoidHighways={this.state.setupData.avoidHighways}

          startLocation={this.state.setupData.startLocation}
          selectingStart={this.state.flowData.selectingStart}
          selectStart={this.selectStart}

          selectingFinish={this.state.flowData.selectingFinish}
          destLocation={this.state.setupData.destLocation}
          selectFinish={this.selectFinish}

          handleChange={this.handleChange}
          reset={this.reset} />
        <RoadEditMap
          mapId={"edit-page-map"}
          {...this.state.flowData}
          setPreviewUrl={this.setPreviewUrl}
          avoidHighways={this.state.setupData.avoidHighways}
          startLocation={this.state.setupData.startLocation}
          destLocation={this.state.setupData.destLocation}
          startSelected={this.startSelected}
          finishSelected={this.finishSelected}
          allowedCountries={this.props.configuration.allowedCountryCodes}
          waypoints={this.state.roadData.waypoints}
          setWaypoints={this.setWaypoints} />
        <EditRoadFields
          name={this.state.setupData.name}
          description={this.state.setupData.description}
          isPrivate={this.state.setupData.isPrivate}
          distance={this.state.setupData.distanceKm}
          startLocation={this.state.setupData.startLocationLatLng}
          destLocation={this.state.setupData.destLocationLatLng}
          handleChange={this.handleChange}
        />
        <div className="row justify-content-center">
          {this.props.isLoading ?
            <LoadingComponent /> :
            <button className="btn btn-primary"
              type="submit"
              disabled={!this.state.setupData.canSave}
              onClick={() => this.saveOnServer()}>
              Uložit
            </button>
          }
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    saveRecord: (o) => dispatch(saveRoad(o))
  };
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.addRecord.isLoading,
    configuration: state.configuration.configuration
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddRoadPage);
