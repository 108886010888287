import React from 'react';
import { connect } from 'react-redux';
import { savePlace } from '../../actions/record/recordAddActionCreators';
import { Helmet } from "react-helmet";

import PlaceSetupForm from './PlaceSetupForm';
import PlaceEditMap from '../map/PlaceEditMap';
import EditPlaceFields from './EditPlaceFields';
import LoadingComponent from '../common/LoadingComponent';
import AddPlaceGuidePanel from './AddPlaceGuidePanel';

const GetInitState = () => {
    return {
        setupData: {
            name: "",
            description: "",
            isPrivate: false,

            countryCode: "",
            startLocation: "",
            startLocationLatLng: '',
            canSave: false
        },
        flowData: {
            selectingStart: false
        }
    };
}

class AddPlacePage extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = GetInitState();

        this.handleChange = this.handleChange.bind(this);
        this.selectStart = this.selectStart.bind(this);
        this.startSelected = this.startSelected.bind(this);
        this.reset = this.reset.bind(this);
    }

    savePlaceLocal() {

        var newRoadDto = {
            startLocationTitle: this.state.setupData.startLocation,
            title: this.state.setupData.name,
            description: this.state.setupData.description,
            isPrivate: this.state.setupData.isPrivate,
            latitude: this.state.setupData.startLocationLatLng.lat(),
            longtitude: this.state.setupData.startLocationLatLng.lng(),
            countryCode: this.state.setupData.countryCode
        };

        this.props.savePlace(newRoadDto);
    }

    reset(e) {
        e.preventDefault();
        let initState = GetInitState();
        this.setState(initState);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        var setupData = this.state.setupData;
        setupData[name] = value;
        setupData.canSave =
            setupData.startLocation !== '' &&
            setupData.startLocationLatLng !== '' &&
            setupData.countryCode !== '' &&
            setupData.name !== '';

        this.setState({ setupData });
    }

    selectStart(selecting) {
        this.setState({
            flowData: {
                selectingStart: selecting
            }
        });
    }

    startSelected(title, location, countryCode) {
        let setupData = this.state.setupData;
        setupData.countryCode = countryCode;
        setupData.startLocation = title;
        setupData.startLocationLatLng = location;
        setupData.canSave = title !== '' && location !== '' && setupData.name !== '';

        let flowData = this.state.flowData;
        flowData.selectingStart = false;

        this.setState({ setupData, flowData });
    }

    render() {
        return (
            <div className='container content-wrapper'>
                <Helmet>
                    <title>Přidání nového místa - Drivers’ Roads</title>
                </Helmet>
                <h3 className='text-center'>
                    <span className='record-type'>
                        <img alt='Place' src="/img/place-marker.png" />
                    </span>
                    &nbsp;Přidání nového místa
                </h3>
                <AddPlaceGuidePanel
                    selectingStart={this.state.flowData.selectingStart}
                    startLocation={this.state.setupData.startLocation}
                    name={this.state.setupData.name}
                    description={this.state.setupData.description} />
                <PlaceSetupForm
                    startLocation={this.state.setupData.startLocation}
                    selectingStart={this.state.flowData.selectingStart}
                    selectStart={this.selectStart}
                    reset={this.reset} />
                <PlaceEditMap
                    mapId={"edit-page-map"}
                    allowedCountries={this.props.configuration.allowedCountryCodes}
                    startLocation={this.state.setupData.startLocation}
                    selectingStart={this.state.flowData.selectingStart}
                    startSelected={this.startSelected} />
                <EditPlaceFields
                    name={this.state.setupData.name}
                    description={this.state.setupData.description}
                    isPrivate={this.state.setupData.isPrivate}
                    startLocation={this.state.setupData.startLocationLatLng}
                    handleChange={this.handleChange}
                />
                <div className="row justify-content-center">
                    {this.props.isLoading ?
                        <LoadingComponent /> :
                        <button className="btn btn-primary"
                            type="submit"
                            disabled={!this.state.setupData.canSave}
                            onClick={() => this.savePlaceLocal()}>
                            Uložit
                        </button>
                    }
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        savePlace: (o) => dispatch(savePlace(o)),
    };
};

const mapStateToProps = (state) => {
    return {
        isLoading: state.addRecord.isLoading,
        configuration: state.configuration.configuration
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPlacePage);