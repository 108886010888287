import React from 'react';
import LightboxModal from './LightboxModal';

class SingleImageGallery extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            canOpenLightbox: true,
            openLightbox: false,
            currentImage: 0
        };

        this.onImgClick = this.onImgClick.bind(this);
        this.closeLightbox = this.closeLightbox.bind(this);
    }

    onImgClick(index) {
        if (!this.state.canOpenLightbox) {
            return;
        }

        this.setState({
            currentImage: index,
            openLightbox: true
        });
    }

    closeLightbox() {
        this.setState({
            openLightbox: false
        });
    }

    render() {
        if (this.props.photoIds && this.props.photoIds.length !== 0) {

            let images = [];
            this.props.photoIds.map((i) =>
                images.push({ src: this.props.getOriginal(i, this.props.id) })
            );

            return (
                <div>
                    <LightboxModal
                        images={images}
                        onClose={this.closeLightbox}
                        openLightbox={this.state.openLightbox}
                        currentImage={this.state.currentImage} />
                    <div className='position-relative pointer w-100 h-100 text-center' onClick={() => this.onImgClick(0)}>
                        <img src={this.props.getThumb(this.props.photoIds[0], this.props.id)} />
                        <span className='vertical-center-div single-gallery-span'>+{this.props.photoIds.length}</span>
                    </div>
                </div>
            );
        }

        return (<div></div >);
    }
}

export default SingleImageGallery;