import React from 'react';
import toastr from 'toastr';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment } from '@fortawesome/free-solid-svg-icons';

import { commentFetchById } from '../../actions/comment/commentActionCreators';
import { editComment, deleteComment } from '../../actions/comment/commentOwnerActionCreators';
import { toggleCommentLike } from '../../actions/comment/commentLikeActionCreators';
import { addCommentPhoto } from '../../actions/comment/commentPhotoActionCreators';

import LoadingComponent from '../common/LoadingComponent';
import CommentPanel from './helper/CommentPanel';
import CommentGallery from '../gallery/CommentGallery';
import ErrorComponent from '../common/ErrorComponent';
import RecordLightItem from '../record/lightList/RecordLightItem';

class CommentPage extends React.Component {
    constructor(props) {
        super(props);

        this.deleteComment = this.deleteComment.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.comment.isDeleted &&
            this.props.comment.isDeleted !== prevProps.comment.isDeleted) {
            toastr.info('Komentář byl smazán');
            this.props.history.push('/diary');
        }
    }

    componentDidMount() {
        if (this.props.commment === undefined) {
            let id = this.props.match.params['id'];
            this.props.commentFetchById(id);
        }
    }

    deleteComment() {
        this.props.deleteComment(this.props.comment.id);
    }

    render() {
        if (this.props.hasErrored) {
            return (<div className="container content-wrapper"><ErrorComponent message='Komentář se nepodařilo načíst' /></div>);
        }

        // if ( && this.props.comment.constructor === Object) {
        if (this.props.isLoading || Object.keys(this.props.comment).length === 0) {
            return (<div className="container content-wrapper"><LoadingComponent /></div>);
        }

        let isLoggedIn = this.props.user != null && !this.props.user.expired;
        let isOwner = this.props.user && this.props.comment.ownerId.toUpperCase() === this.props.user.profile.sub.toUpperCase();

        return (
            <div className="container content-wrapper">
                <Helmet>
                    <title>Detail komentáře - Drivers’ Roads</title>
                </Helmet>
                <h3 className='text-center mb-2'>
                    <FontAwesomeIcon icon={faComment} className='primary' /> &nbsp;Detail komentáře
                </h3>
                <div className="row justify-content-center my-2">
                    <div className='col-lg-8'>
                        <div className='card'>
                            <div className='card-header'>
                                <table className="w-100">
                                    <tbody>
                                        <RecordLightItem {...this.props.comment.record} />
                                    </tbody>
                                </table>
                            </div>
                            <CommentPanel
                                comment={this.props.comment}
                                isLoggedIn={isLoggedIn}
                                isOwner={isOwner}
                                toggleCommentLike={this.props.toggleCommentLike}
                                deleteComment={this.props.deleteComment}
                                editComment={this.props.editComment}
                                addCommentPhoto={this.props.addCommentPhoto} />
                            {this.props.comment.photoIds.length !== 0 &&
                                <div className='card-footer gallery-lr-wrapping'>
                                    <div className='comment-gallery-slide-panel'>
                                        <CommentGallery
                                            id={this.props.comment.id}
                                            isOwner={isOwner}
                                            photoIds={this.props.comment.photoIds}
                                            isPrivate={this.props.comment.record.isPrivate} />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        commentFetchById: (commentId) => dispatch(commentFetchById(commentId)),
        toggleCommentLike: (id, likes) => dispatch(toggleCommentLike(id, likes)),
        deleteComment: (commentId) => dispatch(deleteComment(commentId)),
        editComment: (commentId, text) => dispatch(editComment(commentId, text)),
        addCommentPhoto: (commentId, file) => dispatch(addCommentPhoto(commentId, file))
    };
};

const mapStateToProps = (state) => {
    return {
        comment: state.comment.comment,
        hasErrored: state.comment.errored,
        isLoading: state.comment.isLoading,
        user: state.oidc.user
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CommentPage);