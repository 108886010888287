import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarker } from '@fortawesome/free-solid-svg-icons';

const RoadSetupForm = ({ startLocation, destLocation, selectingStart, selectingFinish, avoidHighways,
  handleChange, selectStart, selectFinish, reset }) => {

  let startButtonClass = null;
  if (selectingStart) {
    startButtonClass = "btn btn-primary";
  }
  else {
    startButtonClass = "btn btn-outline-primary";
  }

  let destButtonClass = null;
  if (selectingFinish) {
    destButtonClass = "btn btn-primary";
  }
  else {
    destButtonClass = "btn btn-outline-primary";
  }

  return (
    <form className="form-inline">
      <label htmlFor="fieldStart">Start&nbsp;</label>
      <div className="input-group my-1 pr-2">
        <div className="input-group-prepend">
          <button className={startButtonClass}
            onClick={() => selectStart()}
            type="button"
            data-toggle="tooltip"
            data-placement="top"
            title="Klikněte zde a následně do mapy">
            <FontAwesomeIcon icon={faMapMarker} />
          </button>
        </div>
        <input readOnly value={startLocation} name="startLocation"
          type="text" className="form-control" id="fieldStart" />
      </div>
      <label htmlFor="fieldStart">Cíl&nbsp;</label>
      <div className="input-group my-1 pr-2">
        <div className="input-group-prepend">
          <button className={destButtonClass}
            onClick={() => selectFinish()}
            type="button">
            <FontAwesomeIcon icon={faMapMarker} /></button>
        </div>
        <input readOnly value={destLocation} name="destLocation"
          type="text" className="form-control" id="fieldFinish" />
      </div>
      <div className="form-check form-check-inline">
        <input className="form-check-input" type="checkbox" checked={avoidHighways} onChange={handleChange} name="avoidHighways" id="fieldHighways" />
        <label className="form-check-label" htmlFor="fieldHighways">Vyhnout se dalnicim</label>
      </div>
      <div className="my-1 pr-2">
        <button className="btn btn-light" onClick={reset}>Reset</button>
      </div>
    </form>
  );
};

export default RoadSetupForm;
