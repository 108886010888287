import React from 'react';
import toastr from 'toastr';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import LoginButton from '../user/LoginButton';

import userManager from '../../utils/userManager';

class HomeAccount extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false
        }

        this.onLoginClick = this.onLoginClick.bind(this);
    }

    onLoginClick() {
        this.setState({ loading: true });

        let redirectUrl = window.location.pathname;
        if (redirectUrl === '/user') {
            redirectUrl = '/diary';
        }

        userManager.signinRedirect({
            data: { redirectUrl: redirectUrl }
        }).then(o => {
            console.log('signing user ' + JSON.stringify(o))
        }).catch(o => {
            this.setState({ loading: false });
            console.error('error signing user ' + JSON.stringify(o))
            userManager.clearStaleState();
            toastr.error('Přihlášení se nezdařilo, zkuste to prosím za chvíli.')
        });
    }

    render() {
        let isLoggedIn = this.props.user != null && !this.props.user.expired;

        return (<div>
            <div className="text-center">
                <h2 className="featurette-heading">
                    <span>Využijte účet <span className='red'>Autozka</span></span>
                </h2>
                <p>Jeden účet pro všechny aplikace od týmu Autozka.</p>
                <div>
                    {isLoggedIn ?
                        <NavLink className="btn btn-secondary min-x-3 my-2 mx-1" to={"/user"}>{this.props.user.profile.name}</NavLink>
                        :
                        <LoginButton />
                    }
                </div>
            </div>
        </div>);
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.oidc.user,
    };
};

export default connect(mapStateToProps, null)(HomeAccount);