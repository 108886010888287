import {
    RECORD_ADD_SUCCESS,
    RECORD_ADD_LOADING,
    RECORD_ADD_ERRORED,
} from './recordAddActionTypes';

import { DriversRoadsApi, jsonAPIAuthorizedRequest, onApiError } from '../../api/apiHelper';
import history from '../../store/configureHistory';

function recordAddSuccess(record) {
    return {
        type: RECORD_ADD_SUCCESS,
        record
    };
}

function recordAddLoading() {
    return {
        type: RECORD_ADD_LOADING
    };
}

function recordAddErrored() {
    return {
        type: RECORD_ADD_ERRORED
    };
}

export function saveRoad(body) {
    return (dispatch) => {
        dispatch(recordAddLoading());
        let options = jsonAPIAuthorizedRequest('POST', body);
        let url = DriversRoadsApi + 'api/road/add';

        fetch(url, options)
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => response.json())
            .then((id) => {
                dispatch(recordAddSuccess(id));
                history.push('/road/' + id);
            })
            .catch((e) => {
                onApiError(e);
                dispatch(recordAddErrored());
            });
    };
}

export function savePlace(body) {
    return (dispatch) => {
        dispatch(recordAddLoading());
        let options = jsonAPIAuthorizedRequest('POST', body);
        let url = DriversRoadsApi + 'api/place/add';

        fetch(url, options)
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => response.json())
            .then((id) => {
                dispatch(recordAddSuccess(id));
                history.push('/place/' + id);
            })
            .catch((e) => {
                onApiError(e);
                dispatch(recordAddErrored());
            });
    };
}
