import {
    DIARY_VISITS_FETCH_SUCCESS ,
    DIARY_VISITS_FETCH_LOADING,
    DIARY_VISITS_FETCH_ERRORED
} from '../../actions/diary/visitsActionTypes';

export function visitsLoaded(state = [], action) {
    switch (action.type) {
        case DIARY_VISITS_FETCH_SUCCESS :
            return action.visits;
        case DIARY_VISITS_FETCH_ERRORED:
            return [];
        default:
            return state;
    }
}

const defaultPagination = {
    items: 5,
    page: 1
};

export function visitsPagination(state = defaultPagination, action) {
    switch (action.type) {
        case DIARY_VISITS_FETCH_SUCCESS:
            return action.pagination;
        default:
            return state;
    }
}

export function visitsIsLoading(state = false, action) {
    switch (action.type) {
        case DIARY_VISITS_FETCH_LOADING:
            return true;
        case DIARY_VISITS_FETCH_ERRORED:
        case DIARY_VISITS_FETCH_SUCCESS :
            return false;
        default:
            return state;
    }
}

export function visitsErrored(state = false, action) {
    switch (action.type) {
        case DIARY_VISITS_FETCH_ERRORED:
            return true;
        case DIARY_VISITS_FETCH_LOADING:
        case DIARY_VISITS_FETCH_SUCCESS :
            return false;
        default:
            return state;
    }
}