import React from 'react';
import toastr from 'toastr';

import userManager from '../../utils/userManager';
import LoadingComponent from '../common/LoadingComponent';

class LoginButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false
        }

        this.onLoginClick = this.onLoginClick.bind(this);
    }

    onLoginClick() {
        this.setState({ loading: true });

        let redirectUrl = window.location.pathname;
        if (redirectUrl === '/user') {
            redirectUrl = '/diary';
        }

        userManager.signinRedirect({
            data: { redirectUrl: redirectUrl }
        }).then(o => {
            console.log('signing user ' + JSON.stringify(o))
        }).catch(o => {
            this.setState({ loading: false });
            console.error('error signing user ' + JSON.stringify(o))
            userManager.clearStaleState();
            toastr.error('Přihlášení se nezdařilo, zkuste to prosím za chvíli.')
        });
    }

    render() {
        return (
            <button className={'btn btn-outline-primary min-width-120 my-2 ' + (this.state.loading ? 'active' : '')} onClick={this.onLoginClick}>Přihlásit {this.state.loading && <LoadingComponent sizeClass='d-inline-block' iconClass='white' />}</button>
        );
    }
}

export default LoginButton;
