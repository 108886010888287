import React from 'react';
import { Redirect } from 'react-router-dom';

import userManager from '../../utils/userManager';

class SignoutComponent extends React.Component {

    componentWillMount() {
        userManager.removeUser();
        userManager.clearStaleState();
    }

    render() {
        return (<Redirect to={"/"} />);
    }

}

export default SignoutComponent;
