import React from 'react';
import Slider from 'rc-slider';

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);
const wrapperStyle = { width: '100%', margin: '20px 0' };

class RecordRangeList extends React.Component {

    constructor(props) {
        super(props);
    }

    onChange(e, item) {
        this.props.updateFilter(item, e[0], e[1]);
    }

    render() {

        return (<div>
            <div style={wrapperStyle}>
                <p>Hodnocení</p>
                <Range min={0} max={5} step={1} dots 
                    value={[this.props.minRating, this.props.maxRating]}
                    onChange={(e) => this.onChange(e, 'Rating')}
                    tipFormatter={value => `${value}`} />
            </div>
            <div style={wrapperStyle}>
                <p>Počet hodnocení</p>
                <Range min={0} max={this.props.configuration.maxRatings}
                    value={[this.props.minNumRating, this.props.maxNumRating]}
                    onChange={(e) => this.onChange(e, 'NumRating')}
                    tipFormatter={value => `${value}`} />
            </div>
            <div style={wrapperStyle}>
                <p>Počet návštěv</p>
                <Range min={0} max={this.props.configuration.maxVisits}
                    value={[this.props.minVisits, this.props.maxVisits]}
                    onChange={(e) => this.onChange(e, 'Visits')}
                    tipFormatter={value => `${value}`} />
            </div>
            <div style={wrapperStyle}>
                <p>Počet komentářů</p>
                <Range min={0} max={this.props.configuration.maxComments}
                    value={[this.props.minComments, this.props.maxComments]}
                    onChange={(e) => this.onChange(e, 'Comments')}
                    tipFormatter={value => `${value}`} />
            </div>
            <div style={wrapperStyle}>
                <p>Počet oblíbených</p>
                <Range min={0} max={this.props.configuration.maxFavourites}
                    value={[this.props.minFavourites, this.props.maxFavourites]}
                    onChange={(e) => this.onChange(e, 'Favourites')}
                    tipFormatter={value => `${value}`} />
            </div>
            {this.props.recordType === 'roads' &&
                <div style={wrapperStyle}>
                    <p>Délka</p>
                    <Range min={0} max={500}
                        value={[this.props.minDistance, this.props.maxDistance]}
                        onChange={(e) => this.onChange(e, 'Distance')}
                        tipFormatter={value => `${value} km`} />
                </div>}
        </div>);
    }
}

export default RecordRangeList;