import React from 'react';
import { connect } from 'react-redux';
// import { push } from 'react-router-redux';
import { CallbackComponent } from 'redux-oidc';

import userManager from '../../utils/userManager'
import { loadUser } from '../../actions/userActionCreators';
import LoadingComponent from './LoadingComponent';

class CallbackPage extends React.Component {
    render() {
        return (
            <CallbackComponent
                userManager={userManager}
                successCallback={(user) => {
                    this.props.dispatch(loadUser());
                    this.props.history.push(user.state.redirectUrl);
                }}
                errorCallback={error => {
                    debugger;
                    // this.props.dispatch(push("/error"));
                    console.error(error);
                }}>
                <div className='container content-wrapper'>
                    <h2>Probíhá přihlašování <LoadingComponent sizeClass='d-inline-block' /></h2>
                </div>
            </CallbackComponent>
        );
    }
}

export default connect()(CallbackPage);