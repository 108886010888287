import {
    ATLAS_POINTS_FETCH_LOADING,
    ATLAS_POINTS_FETCH_SUCCESS,
    ATLAS_POINTS_FETCH_ERRORED,

    ATLAS_DETAILS_FETCH_LOADING,
    ATLAS_DETAILS_FETCH_SUCCESS,
    ATLAS_DETAILS_FETCH_ERRORED,

    ATLAS_DETAILS_FETCH_PAGE_SUCCESS
} from './atlasActionTypes';

import { DriversRoadsApi, getPlaintJsonOptions, onApiError } from '../api/apiHelper';

function atlasPointsFetchSuccess(points, reset) {
    return {
        type: ATLAS_POINTS_FETCH_SUCCESS,
        points,
        reset
    };
}

function atlasPointsFetchLoading() {
    return {
        type: ATLAS_POINTS_FETCH_LOADING
    };
}

function atlasPointsFetchErrored() {
    return {
        type: ATLAS_POINTS_FETCH_ERRORED
    };
}

export function fetchAtlasPointsByFilter(query, reset) {
    return (dispatch) => {
        dispatch(atlasPointsFetchLoading());

        let options = getPlaintJsonOptions("GET");
        fetch(DriversRoadsApi + 'api/atlas/points?' + query, options)
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => response.json())
            .then((points) => dispatch(atlasPointsFetchSuccess(points, reset)))
            .catch((e) => {
                onApiError(e);
                dispatch(atlasPointsFetchErrored());
            });
    };
}

export function atlasDetailsFetchSuccess(details) {
    return {
        type: ATLAS_DETAILS_FETCH_SUCCESS,
        details
    };
}

function atlasDetailsPageFetchSuccess(details) {
    return {
        type: ATLAS_DETAILS_FETCH_PAGE_SUCCESS,
        details
    };
}

export function atlasDetailsFetchLoading() {
    return {
        type: ATLAS_DETAILS_FETCH_LOADING
    };
}

function atlasDetailsFetchErrored() {
    return {
        type: ATLAS_DETAILS_FETCH_ERRORED
    };
}

export function fetchAtlasDetailsPageByFilter(query) {
    return (dispatch) => {
        let options = getPlaintJsonOptions("GET");
        fetch(DriversRoadsApi + 'api/atlas/details?' + query, options)
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => response.json())
            .then((result) => dispatch(atlasDetailsPageFetchSuccess(result)))
            .catch((e) => {
                onApiError(e);
            });
    };
}

export function fetchAtlasDetailsByFilter(query) {
    return (dispatch) => {
        dispatch(atlasDetailsFetchLoading());

        let options = getPlaintJsonOptions("GET");
        fetch(DriversRoadsApi + 'api/atlas/details?' + query, options)
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => response.json())
            .then((result) => dispatch(atlasDetailsFetchSuccess(result)))
            .catch((e) => {
                onApiError(e);
                dispatch(atlasDetailsFetchErrored());
            });
    };
}