import {
    RECORD_FETCH_LOADING,
    RECORD_FETCH_SUCCESS,
    RECORD_FETCH_ERRORED,
} from './recordActionTypes';

import { DriversRoadsApi, jsonAPIAuthorizedRequest, onApiError } from '../../api/apiHelper';

function recordFetchSuccess(record) {
    return {
        type: RECORD_FETCH_SUCCESS,
        record
    };
}

function recordFetchLoading() {
    return {
        type: RECORD_FETCH_LOADING,
    };
}

function recordFetchErrored() {
    return {
        type: RECORD_FETCH_ERRORED,
    };
}

export function recordByIdFetch(recordId, type) {
    return (dispatch) => {
        dispatch(recordFetchLoading());

        let options = jsonAPIAuthorizedRequest("GET", undefined);

        fetch(DriversRoadsApi + 'api/' + type + '/' + recordId, options)
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => response.json())
            .then((record) => dispatch(recordFetchSuccess(record)))
            .catch((e) => {
                onApiError(e);
                dispatch(recordFetchErrored());
            });
    };
}