import React from 'react';
import { connect } from 'react-redux';

import { deleteRecordPhoto } from '../../actions/record/recordPhotoActionCreators';

import GallerySlider from './GallerySlider';
import PhotoUrlPathHelper from '../../api/PhotoUrlPathHelper';

const RecordGalleryResponsive = [
    {
        breakpoint: 10000,
        settings: {
            slidesToShow: 4,
            slidesToScroll: 4
        }
    },
    {
        breakpoint: 1200,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 3
        }
    },
    {
        breakpoint: 900,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 2
        }
    },
    {
        breakpoint: 500,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1
        }
    }
];

class RecordGallery extends React.Component {

    constructor(props) {
        super(props);

        this.onPhotoDeleteClick = this.onPhotoDeleteClick.bind(this);
    }

    onPhotoDeleteClick(photoId) {
        this.props.deleteRecordPhoto(this.props.id, photoId, this.props.isPrivate);
    }

    render() {
        let getOriginal = !this.props.isPrivate ?
            PhotoUrlPathHelper.getRecordOrig :
            PhotoUrlPathHelper.getPrivateRecordOrig;
        let getThumb = !this.props.isPrivate ?
            PhotoUrlPathHelper.getRecordGalThumb :
            PhotoUrlPathHelper.getPrivateRecordGalThumb;

        return (
            <GallerySlider
                id={this.props.id}
                canDeleteFirst={false}
                isOwner={this.props.isOwner}
                responsive={RecordGalleryResponsive}
                photoIds={this.props.photoIds}
                onDeleteClick={this.onPhotoDeleteClick}
                getOriginal={getOriginal}
                getThumb={getThumb} />
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        deleteRecordPhoto: (recordId, photoId, isPrivate) => dispatch(deleteRecordPhoto(recordId, photoId, isPrivate)),
    };
};

export default connect(null, mapDispatchToProps)(RecordGallery);