import React from 'react';
import OptionSelect from '../form/OptionSelect';
import LoadingComponent from '../common/LoadingComponent';

class AtlasSortPanel extends React.Component {

    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.props.sortChange(value);
    }

    render() {
        const sortOptions = [
            { value: '0', text: 'Nejnovější' },
            { value: '1', text: 'Nejstarší' },
            // { value: '10', text: 'Zajímavost' },
            { value: '2', text: 'Hodnocení' },
            { value: '6', text: 'Návštěvy' },
            { value: '7', text: 'Oblíbenost' }];

        return (
            <div className='atlas-sort'>
                <table className="table table-borderless atlas-sort-table">
                    <tbody>
                        <tr>
                            <td><OptionSelect className='float-left' name={'sort'} value={this.props.sort} options={sortOptions} onChange={this.handleChange} /></td>
                            <td className='text-center'>Počet výsledků: {this.props.loading ?
                                <LoadingComponent sizeClass={'fs-1 d-inline-block'} /> :
                                <span>{this.props.total}</span>}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default AtlasSortPanel;