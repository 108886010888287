import {
    DIARY_OVERVIEW_FETCH_SUCCESS,
    DIARY_OVERVIEW_FETCH_LOADING,
    DIARY_OVERVIEW_FETCH_ERRORED
} from './diaryActionTypes';

import { DriversRoadsApi, jsonAPIAuthorizedRequest, onApiError } from '../../api/apiHelper';

function diaryFetchSuccess(records) {
    return {
        type: DIARY_OVERVIEW_FETCH_SUCCESS,
        records
    };
}

function diaryFetchLoading() {
    return {
        type: DIARY_OVERVIEW_FETCH_LOADING
    };
}

function diaryFetchErrored() {
    return {
        type: DIARY_OVERVIEW_FETCH_ERRORED
    };
}


export function diaryFetch() {
    return (dispatch) => {
        dispatch(diaryFetchLoading());
        let options = jsonAPIAuthorizedRequest("GET", undefined);

        fetch(DriversRoadsApi + 'api/diary/overview', options)
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => response.json())
            .then((response) => {
                dispatch(diaryFetchSuccess(response));
            })
            .catch((e) => {
                onApiError(e);
                dispatch(diaryFetchErrored());
            });
    };
}