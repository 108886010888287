import React from 'react';
import DatabaseRecordItem from './DatabaseRecordItem';

class DatabaseRecordList extends React.Component {
  render() {
    if (this.props.items.length === 0) {
      return (<div className='text-center'>
        <p>Zadaným parametrům neodpovídá žádný záznam</p>
      </div>);
    };

    return (<div className='table-responsive-lg'>
      <table className='table w-100'>
        <tbody>
          {this.props.items.map((item) =>
            <DatabaseRecordItem {...item} key={item.id}
              logged={this.props.logged}
              onFavouriteClick={this.props.onFavouriteClick}
              onVisitClick={this.props.onVisitClick}
              onRatingClick={this.props.onRatingClick}
              selectRoad={this.props.selectRoad} />
          )}
        </tbody>
      </table>
    </div>
    );
  }
}

export default DatabaseRecordList;
