/*global google*/
class GoogleApiHelper {

    static geocodeLocation(lng, lat) {
        return new Promise((resolve, reject) => {

            var geocoder = new google.maps.Geocoder();
            geocoder.geocode({ 'location': { lat: lat, lng: lng } }, (result, status) => {
                if (status !== 'OK') {
                    reject(status);
                } else {
                    let address = this.parseGeocodeResult(result);
                    resolve({ ...address, lng, lat });
                }
            })
        });
    }

    static parseAutocompleteResult(result) {
        let components = result.address_components;
        let country = components.find((o) => o.types.includes('country'));
        let address = result.formatted_address;
        let lng = result.geometry.location.lng();
        let lat = result.geometry.location.lat();

        return {
            address,
            lng,
            lat,
            country: country.long_name,
            countryCode: country.short_name
        };
    }

    static parseGeocodeResult(result) {
        let components = result[0].address_components;
        let country = components.find((o) => o.types.includes('country'));
        let address = result[0].formatted_address;
        return {
            address,
            country: country.long_name,
            countryCode: country.short_name
        };
    }

    static toDirectionsRequest(start, end, waypoints, avoidHighways) {
        var startLatLng = new google.maps.LatLng(start.lat, start.lng);
        var endLatLng = new google.maps.LatLng(end.lat, end.lng);
    
        var waypointsLatLng = [];
        for (var i = 0; i < waypoints.length; i++) {
            waypointsLatLng.push({
            location: new google.maps.LatLng(waypoints[i].lat, waypoints[i].lng),
            stopover: false
          });
        }

        return {
            origin: startLatLng,
            destination: endLatLng,
            waypoints: waypointsLatLng,
            optimizeWaypoints: false,
            travelMode: 'DRIVING',
            avoidFerries: false,
            avoidHighways: avoidHighways,
            avoidTolls: false
        }
    }
}

export default GoogleApiHelper;