import {
    RECORD_MAP_EDIT_IS_LOADING,
    RECORD_MAP_EDIT_ERRORED,

    ROAD_MAP_EDIT_SUCCESS,
    PLACE_MAP_EDIT_SUCCESS,

    RECORD_EDIT_LOADING,
    RECORD_EDIT_SUCCESS,
    RECORD_EDIT_ERRROED
} from './recordEditActionTypes';

import { DriversRoadsApi, jsonAPIAuthorizedRequest, onApiError } from '../../api/apiHelper';

function recordEditMapDataSuccess(recordId, type, data) {
    return {
        type,
        recordId,
        data
    };
}

function recordEditMapDataErrored(recordId) {
    return {
        type: RECORD_MAP_EDIT_ERRORED,
        recordId
    };
}

function recordEditMapDataIsEditing(recordId) {
    return {
        type: RECORD_MAP_EDIT_IS_LOADING,
        recordId
    };
}

export function editRecordMapData(recordId, type, data) {
    return (dispatch) => {

        dispatch(recordEditMapDataIsEditing(recordId));
        let options = jsonAPIAuthorizedRequest("POST", data);

        fetch(DriversRoadsApi + 'api/' + type + '/edit/' + recordId, options)
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then(() => {
                if (type === 'road') {
                    dispatch(recordEditMapDataSuccess(recordId, ROAD_MAP_EDIT_SUCCESS, data))
                } else {
                    dispatch(recordEditMapDataSuccess(recordId, PLACE_MAP_EDIT_SUCCESS, data))
                }
            })
            .catch((e) => {
                onApiError(e);
                dispatch(recordEditMapDataErrored(recordId));
            });
    };
}

function recordEditSuccess(recordId, text) {
    return {
        type: RECORD_EDIT_SUCCESS,
        recordId,
        text
    };
}

function recordEditLoading(recordId) {
    return {
        type: RECORD_EDIT_LOADING,
        recordId
    };
}

function recordEditErrored(recordId) {
    return {
        type: RECORD_EDIT_ERRROED,
        recordId
    };
}

export function editRecordDescription(recordId, text) {
    return (dispatch) => {

        dispatch(recordEditLoading(recordId));
        let options = jsonAPIAuthorizedRequest("POST", { text });

        fetch(DriversRoadsApi + 'api/record/edit/' + recordId, options)
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then(() => dispatch(recordEditSuccess(recordId, text)))
            .catch((e) => {
                onApiError(e);
                dispatch(recordEditErrored(recordId));
            });
    };
}