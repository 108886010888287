/*global google*/
import React from 'react';
import PropTypes from 'prop-types';
import toastr from 'toastr';
import { debounce } from 'lodash';

import { CreateMap } from './MapConfiguration';
import withGoogleMap from '../hoc/withGoogleMap';
import LocationHelper from '../../utils/LocationHelper';
import GoogleApiHelper from '../../utils/GoogleApiHelper';
import LocalePanel from './panels/LocalePanel';
import MapUserPositionHelper from './PositionHelper';

class PlaceEditMap extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.map = null;
        this.geocoder = null;
        this.lastMarkerPosition = null;
        this.positionHelper = null;

        this.mapMarker = this.mapMarker.bind(this);
        this.onMapClick = this.onMapClick.bind(this);

        this.getLocationName = this.getLocationName.bind(this);
        this.debounceDetails = debounce(this.getLocationName, 500);

        this.onInitLoad = this.onInitLoad.bind(this);
        this.onLocateMeError = this.onLocateMeError.bind(this);
        this.onLocateMeSuccess = this.onLocateMeSuccess.bind(this);

        this.onLocationChange = this.onLocationChange.bind(this);
    }

    getLocationName(adresses, latLng) {
        if (adresses === null) {
            if (this.lastMarkerPosition) {
                this.marker.setPosition(this.lastMarkerPosition);
            }
            toastr.warning('Místo se nepodařilo lokalizovat');
            return;
        }

        if (adresses.length === 0) {
            if (this.lastMarkerPosition) {
                this.marker.setPosition(this.lastMarkerPosition);
            }
            toastr.warning('Neplatné místo na mapě');
            return;
        }

        //find country code
        let location = GoogleApiHelper.parseAutocompleteResult(adresses[0]);
        if (this.props.allowedCountries.includes(location.countryCode.toLowerCase())) {
            this.props.startSelected(adresses[0].formatted_address, latLng, location.countryCode);

            this.lastMarkerPosition = latLng;
            this.marker.setPosition(latLng);
            this.marker.setVisible(true);
        }
        else {
            toastr.warning(location.country + ' není podporováno');
            if (this.lastMarkerPosition) {
                this.marker.setPosition(this.lastMarkerPosition);
            }
        }
    }

    onMapClick(e) {
        let location = e.latLng;
        let request = { 'location': location };
        this.geocoder.geocode(request, (result) => this.debounceDetails(result, location));
    }

    mapMarker() {
        this.marker = new google.maps.Marker({
            map: this.map,
            draggable: true,
            visible: false,
            icon: { url: '/img/place-marker.png' }
        });

        this.marker.addListener("dragend", (e) => this.onMapClick(e));
    }

    componentDidUpdate() {
        if (this.props.startLocation === '') {
            if (this.marker) {
                this.marker.setVisible(false);
            }
        }
    }

    onLocateMeSuccess(gps) {
        this.map.setCenter({ lat: gps.lat, lng: gps.lng });
    }

    onLocateMeError() {
        this.map.setCenter({ lat: 50, lng: 14 });
    }

    onInitLoad() {
        LocationHelper.locateGPS(this.onLocateMeSuccess, this.onLocateMeError);
        this.positionHelper = new MapUserPositionHelper(this.map);
        this.positionHelper.startWatching();
    }

    onLocationChange(location) {
        this.map.panTo({ lat: location.lat, lng: location.lng });
    }

    componentDidMount() {
        this.geocoder = new google.maps.Geocoder();

        this.map = CreateMap(this.props.mapId);
        google.maps.event.addListenerOnce(this.map, 'idle', this.onInitLoad);

        this.mapMarker();
        this.map.addListener('click', (e) => this.props.selectingStart && this.onMapClick(e));

        var localeMapButton = document.getElementById('locale-map-button');
        localeMapButton.index = 1;
        this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(localeMapButton);
    }

    componentWillUnmount() {
        this.positionHelper.clearWatch();
    }

    render() {
        return (<div id="wrapper">
            <div style={{ visibility: 'collapse', position: 'absolute' }}>
                <div><LocalePanel onLocationChange={this.onLocationChange} /></div>
            </div>
            <div id={this.props.mapId}></div>
        </div >);
    }
}

PlaceEditMap.propTypes = {
    mapId: PropTypes.string.isRequired
};

export default withGoogleMap(PlaceEditMap);