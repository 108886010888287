import React from 'react';
import RecordLightItem from './RecordLightItem';

const RecordLightList = ({ records }) => {
    return (<table className="table w-100">
        <tbody>
            {records.map((record, i) => <RecordLightItem {...record} key={i} />)}
        </tbody>
    </table>);
};

export default RecordLightList;