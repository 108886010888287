import React from 'react'
import withGoogleMap from '../hoc/withGoogleMap';
import { ISeasonTripDto, IStageDetailDto, IUserStageProgressDto } from '../../types/types';

interface SeasonMapState {
    isMapLoading: boolean,
    initFit: boolean,
    displays: { id: Number, display: google.maps.DirectionsRenderer }[]
}

interface SeasonMapProps {
    items: ISeasonTripDto[]
    // onMapClick: (waypoint: IWaypoint) => void
}

export let map = {} as google.maps.Map;

class SeasonMap extends React.Component<SeasonMapProps, SeasonMapState> {

    strokes: { stageId: Number, stroke: google.maps.Polyline }[] = [];

    constructor(props: any) {
        super(props);

        this.state = {
            initFit: true,
            isMapLoading: true,
            displays: []
        }

        this.strokes = [];
        this.onMapClick = this.onMapClick.bind(this);
        this.redrawStroke = this.redrawStroke.bind(this);
    }

    onMapClick() {

    }

    redrawStroke(tripId: number, stage: IStageDetailDto) {
        let strokeItem = this.strokes.find(o => o.stageId === stage.id);
        let stroke: google.maps.Polyline = {} as google.maps.Polyline;

        //Add stroke if not yet exists
        if (strokeItem === undefined) {
            stroke = new google.maps.Polyline({ path: [], geodesic: true, strokeColor: stage.userCompleted ? 'green' : 'blue', strokeOpacity: 0.8, strokeWeight: 5 });
            stroke.setMap(map);
            stroke.addListener('click', () => {
                const form = document.getElementById(`trip-scroll-${tripId}`);
                form?.scrollIntoView({ behavior: 'smooth', block: 'start' });
            })
            this.strokes.push({ stageId: stage.id, stroke });
        } else {
            stroke = strokeItem.stroke;
        }

        if (stage.serializedPolylines) {
            let stagePolylines: string[] = JSON.parse(stage.serializedPolylines);
            let pathPoints: google.maps.LatLng[] = [];

            for (let j = 0; j < stagePolylines.length; j++) {
                const element = stagePolylines[j];
                let points = google.maps.geometry.encoding.decodePath(element);
                pathPoints.push(...points);
            }

            stroke.setPath(pathPoints);
        }
    }

    componentDidUpdate(prevProps: SeasonMapProps, prevState: SeasonMapState) {
        let forceDraw = false;
        if (this.state.isMapLoading === false && prevState.isMapLoading === true) {
            forceDraw = true;
        }

        if (this.props.items?.length > 0) {
            for (let i = 0; i < this.props.items.length; i++) {
                const currentItem = this.props.items[i];
                const prevItem = prevProps.items?.find(o => o.id === currentItem.id);

                if (forceDraw || (currentItem.serializedPolylines !== prevItem?.serializedPolylines)) {
                    currentItem.stages.forEach(stage => {
                        this.redrawStroke(currentItem.id, stage);
                    });
                }
            }

            if (this.state.initFit) {
                let tripBounds = new google.maps.LatLngBounds();
                this.props.items.forEach((o) => {
                    let parsed = JSON.parse(o.serializedBounds);
                    tripBounds.union(parsed);
                })
                map?.fitBounds(tripBounds);
                this.setState({ initFit: false })
            }
        }
    }

    componentDidMount() {
        this.strokes = [];

        map = new google.maps.Map(document.getElementById("map") as HTMLElement, {
            center: { lat: 50.048321, lng: 14.396210 },
            zoom: 8
        });

        // map.addListener('click', (e) => this.props.onMapClick({ lat: e.latLng.lat(), lng: e.latLng.lng() }));
        google.maps.event.addListenerOnce(map, 'idle', () => this.setState({ isMapLoading: false }));
    }

    render() {

        return <>
            <div id="wrapper" style={{ position: 'relative', height: '500px' }}>
                <div id="map" style={{ height: '100%' }}></div>
            </div>
        </>
    }
}

export default withGoogleMap(SeasonMap); 