import React from 'react';

const RecordTypeItem = ({ text, name, value, checked, onChange }) => {
    let className = 'btn btn-outline-primary';
    if (checked) {
        className += ' active';
    }

    return (
        <label className={className}>
            <input
                type="radio"
                name={name}
                value={value}
                checked={checked}
                onChange={onChange} />
            {text}
        </label>
    );
}

export default RecordTypeItem;