import React from 'react';
import { connect } from 'react-redux';

import LoginPage from '../user/LoginPage';

class PrivateRoute extends React.Component {
    render() {
        if (this.props.oidc.isLoadingUser) {
            return (<div><h1>Loading</h1></div>);
        }

        if (!this.props.oidc.user || this.props.oidc.user.expired) {
            return (<LoginPage />);
        }

        return (<div>{this.props.children}</div>);
    }
}

const mapStateToProps = (state) => {
    return {
        oidc: state.oidc
    }
}

export default connect(mapStateToProps)(PrivateRoute);