import {
    DATABASE_FETCH_LOADING,
    DATABASE_FETCH_SUCCESS,
    DATABASE_FETCH_ERRORED,
} from './databaseActionTypes';

import { DriversRoadsApi, jsonAPIAuthorizedRequest, onApiError } from '../api/apiHelper';

function databaseRecordsFetchSuccess(records, pagination, filter) {
    return {
        type: DATABASE_FETCH_SUCCESS,
        records,
        pagination,
        filter
    };
}

function databaseRecordsFetchLoading() {
    return {
        type: DATABASE_FETCH_LOADING
    };
}

function databaseRecordsFetchErrored() {
    return {
        type: DATABASE_FETCH_ERRORED
    };
}

export function databaseFetchByFilter(query, filter) {
    return (dispatch) => {

        dispatch(databaseRecordsFetchLoading());
        let options = jsonAPIAuthorizedRequest("GET");

        fetch(DriversRoadsApi + 'api/database/byfilter?' + query, options)
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => response.json())
            .then((response) =>
                dispatch(databaseRecordsFetchSuccess(response.items, response.pagination, filter)))
            .catch((e) => {
                onApiError(e);
                dispatch(databaseRecordsFetchErrored());
            });
    };
}