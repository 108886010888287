/*global google*/
import LocationHelper from '../../utils/LocationHelper';

class MapUserPositionHelper {

    constructor(map) {
        this.map = map;
        this.watchId = null;
        this.userMarker = null;

        this.startWatching = this.startWatching.bind(this);
        this.invalidateUserPosition = this.invalidateUserPosition.bind(this);
        this.updateUserPosition = this.updateUserPosition.bind(this);
        this.clearWatch = this.clearWatch.bind(this);
    }

    startWatching() {
        this.watchId = LocationHelper.watchPosition(this.updateUserPosition, this.invalidateUserPosition);
    }

    invalidateUserPosition() {
        if (this.userMarker) {
            this.userMarker.setIcon({ url: '/img/user-pos-invalid.png' });
        }
    }

    updateUserPosition(gps) {
        if (this.userMarker) {
            this.userMarker.setPosition({ lat: gps.lat, lng: gps.lng })
            this.userMarker.setIcon({ url: '/img/user-pos-active.png' });
        } else {
            this.userMarker = new google.maps.Marker({
                position: {
                    lat: gps.lat,
                    lng: gps.lng
                },
                map: this.map,
                name: 'user-post'
            });
            this.userMarker.setIcon({ url: '/img/user-pos-active.png' });
        }
    }

    clearWatch() {
        LocationHelper.clearWatchPosition(this.watchId)
    }
}

export default MapUserPositionHelper;