import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as selectedStar, faStarHalfAlt } from '@fortawesome/free-solid-svg-icons';
import { faStar as unselectedStar } from '@fortawesome/free-regular-svg-icons';

const TotalRating = ({ totalRating, className }) => {
    var rating = totalRating;
    var stars = [];
    for (let i = 1; i < 6; i++) {
        if (rating >= i) {
            stars.push(<FontAwesomeIcon key={i} icon={selectedStar} />);
        }
        else {
            if (i - rating >= 0.75) {
                stars.push(<FontAwesomeIcon key={i} icon={unselectedStar} />);
            }
            else {
                stars.push(<FontAwesomeIcon key={i} icon={faStarHalfAlt} />);
            }
        }
    }
    return (<div className={className}>{stars}</div>);
}

export default TotalRating;