import React from 'react';
import { NavLink } from 'react-router-dom';

class HomeFeatures extends React.Component {
    render() {
        return (
            <div className="features d-flex flex-column py-3" id='features'>
                <div className="m-auto">
                    <div className='container p-3 py-5'>
                        <h2 className='homepage-h2'>Hledáte zajímavé autařské lokace?</h2>

                        <p className="text-justify" style={{ fontSize: '20px' }}>
                            V aplikaci Drivers’ Roads najdete vše. Nejlepší silnice v ČR, nejkrásnější horské silnice, tipy kam autem na vyhlídku a místa pro focení aut.
                        </p>

                        <div className="row justify-content-center p-4 text-center ">
                            <div className="col-md-4">
                                <h3>Nejlepší silnice</h3>
                                <NavLink to={'/atlas'}>
                                    <img className='w-100 my-1' src='/img/best-roads.jpg' alt='Nejlepší silnice' />
                                </NavLink>
                            </div>
                            <div className="col-md-4">
                                <h3>Nejkrásnější vyhlídky</h3>
                                <NavLink to={'/atlas'}>
                                    <img className='w-100 my-1' src='/img/mountain-road.jpg' alt='Horské průsmyky' />
                                </NavLink>
                            </div>
                            <div className="col-md-4">
                                <h3>Místa na autofotku</h3>
                                <NavLink to={'/atlas'}>
                                    <img className='w-100 my-1' src='/img/place-photo.jpg' alt='Místa na autofotku' />
                                </NavLink>
                            </div>
                        </div>
                        <p className="text-justify" style={{ fontSize: '20px' }}>
                            Všechno si můžete přehledně zobrazit v mapě na záložce <NavLink to={'/atlas'}>
                                <span className='bold'>Atlas</span>
                            </NavLink>,
                            nebo detailně vyhledat na stránce <NavLink to={'/database'}>
                                <span className='bold'>Databáze</span>
                            </NavLink>
                        </p>
                    </div>
                </div>
            </div >
        )
    }
}

export default HomeFeatures;