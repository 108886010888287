import React from 'react'
import { IStageDetailDto, IUserStageProgressDto } from '../../../types/types';

interface TripMapState {
    isMapLoading: boolean,
    initFit: boolean,
    displays: { id: Number, display: google.maps.DirectionsRenderer }[]
}

interface TripMapProps {
    items: IStageDetailDto[],
    selected: IStageDetailDto | null,
    stageSelect: (item: IStageDetailDto) => void
}

export let map = {} as google.maps.Map;

const selectedColor: string = '#1751ff';
const notSelectedColor: string = '#21c0ff';

const completedColour: string = '#ff51ff';
const notCompletedColor: string = '#ffc0ff';

class TripMap extends React.Component<TripMapProps, TripMapState> {

    strokes: { id: Number, stroke: google.maps.Polyline }[] = [];

    constructor(props: any) {
        super(props);

        this.state = {
            initFit: true,
            isMapLoading: true,
            displays: []
        }

        this.strokes = [];
        this.onMapClick = this.onMapClick.bind(this);
        this.redrawStroke = this.redrawStroke.bind(this);
        this.fitTripBounds = this.fitTripBounds.bind(this);
    }

    onMapClick() {
    }

    redrawStroke(item: IStageDetailDto) {
        let strokeItem = this.strokes.find(o => o.id === item.id);
        let stroke: google.maps.Polyline = {} as google.maps.Polyline;

        // var tripBounds = new google.maps.LatLngBounds(); 

        //Add stroke if not yet exists
        if (strokeItem === undefined) {
            let color = notSelectedColor;
            if (item.userCompleted) {
                color = 'green';
            } else {

            }

            stroke = new google.maps.Polyline({ path: [], geodesic: true, strokeColor: color, strokeOpacity: 1, strokeWeight: 5 });
            stroke.setMap(map);
            stroke.addListener('click', () => {
                this.props.stageSelect(item);
            })
            this.strokes.push({ id: item.id, stroke });
        } else {
            stroke = strokeItem.stroke;
        }

        if (item.serializedPolylines) {
            let allPolylines: string[] = JSON.parse(item.serializedPolylines);
            let pathPoints: google.maps.LatLng[] = [];

            for (let i = 0; i < allPolylines.length; i++) {
                let stagePolylines = allPolylines[i];
                let points = google.maps.geometry.encoding.decodePath(stagePolylines);
                pathPoints.push(...points);
            }

            stroke.setPath(pathPoints);
        }
    }

    componentDidUpdate(prevProps: TripMapProps, prevState: TripMapState) {
        let forceDraw = false;
        if (this.state.isMapLoading === false && prevState.isMapLoading === true) {
            forceDraw = true;
        }

        if (!this.props.selected?.id) {
            this.strokes.forEach(s => {
                s.stroke.setOptions({ strokeColor: notSelectedColor, strokeOpacity: 1 });
            });
        }

        if (this.props.selected?.id && this.props.selected?.id !== prevProps.selected?.id) {
            this.strokes.forEach(s => {
                if (s.id === this.props.selected?.id) {
                    s.stroke.setOptions({ strokeColor: selectedColor, strokeOpacity: 1 });

                    let tripBounds = new google.maps.LatLngBounds();
                    let parsed = JSON.parse(this.props.selected.serializedBounds);
                    tripBounds.union(parsed);
                    map.fitBounds(tripBounds);
                } else {
                    s.stroke.setOptions({ strokeColor: notSelectedColor, strokeOpacity: 1 });
                }
            });
        }

        if (this.props.selected === null && prevProps.selected !== null) {
            this.fitTripBounds();
        }

        if (this.props.items?.length > 0 && prevProps.items?.length) {
            console.log('redrawing')
            for (let i = 0; i < this.props.items.length; i++) {
                const currentItem = this.props.items[i];
                const prevItem = prevProps.items?.find(o => o.id === currentItem.id);

                if (forceDraw || (currentItem.serializedPolylines !== prevItem?.serializedPolylines)) {
                    this.redrawStroke(currentItem);
                }
            }

            if (this.state.initFit) {
                this.fitTripBounds();
            }
        }
    }

    fitTripBounds() {
        let tripBounds = new google.maps.LatLngBounds();
        this.props.items.forEach((o) => {
            let parsed = JSON.parse(o.serializedBounds);
            tripBounds.union(parsed);
        });
        map?.fitBounds(tripBounds);
        this.setState({ initFit: false });
    }

    componentDidMount() {
        this.strokes = [];
        map = new google.maps.Map(document.getElementById("map") as HTMLElement, {
            center: { lat: 50.048321, lng: 14.396210 },
            zoom: 8
        });
        // map.addListener('click', (e) => this.props.onMapClick({ lat: e.latLng.lat(), lng: e.latLng.lng() }));
        google.maps.event.addListenerOnce(map, 'idle', () => {
            this.setState({ isMapLoading: false });
        });
    }

    render() {

        return <>
            <div id="wrapper" style={{ position: 'relative', height: '500px' }}>
                <div id="map" style={{ height: '100%' }}></div>
            </div>
        </>
    }
}

export default TripMap; 