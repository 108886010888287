import React from 'react';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons';

import { AutozkaUrl } from '../../configuration';

import { loadUser } from '../../actions/userActionCreators';
import LoadingComponent from '../common/LoadingComponent';

class UserComponent extends React.Component {

    componentDidMount() {
        this.props.fetchUser();
    }

    render() {
        if (this.props.isLoading) {
            return (<div className="container content-wrapper">
                <Helmet>
                    <title>Uživatel - Drivers’ Roads</title>
                </Helmet>
                <LoadingComponent />
            </div>);
        }

        return (<div className="container content-wrapper">
            <Helmet>
                <title>{`${this.props.user.name} - Drivers’ Roads`}</title>
            </Helmet>
            <div className="row justify-content-center">
                <div className="text-center px-3 mb-4">
                    <ul className="list-unstyled mb-4">
                        <li className="lead">{this.props.user.name}</li>
                        <li>{this.props.user.email}</li>
                        <li>Účet vytvořen - {new Date(this.props.user.created).toLocaleDateString()}</li>
                    </ul>
                    <ul className="list-unstyled mb-4">
                        <li>Změnu údajů můžete provést na centrálním webu Autozka </li>
                        <li className='mt-1 mb-3 primary fs-0'>Pro promítnutí změn je potřeba se odhlásit a přihlásit</li>
                    </ul>
                    <a target='_blank' className="btn btn-outline-primary mx-2" href={AutozkaUrl + 'Manage/Detail'} title='Spravovat účet'>Spravovat účet <FontAwesomeIcon icon={faPen} /></a>
                    <Link className="btn btn-primary mx-2" to={"/sign-out"} > Odhlásit</Link>
                </div>
            </div>
        </div >)
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchUser: () => dispatch(loadUser())
    };
}

const mapStateToProps = (state) => {
    return {
        user: state.user.user,
        errored: state.user.errored,
        isLoading: state.user.isLoading
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserComponent);