import React from 'react';
import AsyncScriptLoader from 'react-async-script';

import { googleMapsKey } from '../../configuration';

import LoadingComponent from '../common/LoadingComponent';
import ErrorComponent from '../common/ErrorComponent';

const withGoogleMap = (WrappedComponent) => {

    const Enhanced = AsyncScriptLoader('https://maps.googleapis.com/maps/api/js?libraries=places,geometry&key=' + googleMapsKey)(LoadingComponent);

    class HOC extends React.Component {
        constructor(props) {
            super(props);

            this.state = {
                loaded: false,
                errored: false,
            };
            this.onLoad = this.onLoad.bind(this);
        }

        onLoad(a) {
            this.setState({
                loaded: a.loaded,
                errored: a.errored
            })
        }

        render() {
            if (this.state.errored) {
                return <ErrorComponent />;
            }

            if (!this.state.loaded) {
                return <div id={this.props.mapId}>
                    <Enhanced asyncScriptOnLoad={this.onLoad} />
                </div>;
            }

            return <WrappedComponent {...this.props} />
        }
    }

    return HOC;
};

export default withGoogleMap;