import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarker } from '@fortawesome/free-solid-svg-icons';

const PlaceSetupForm = ({ startLocation, selectingStart, selectStart, reset }) => {

    let buttonClass = null;
    let action = null;

    if (selectingStart) {
        buttonClass = "btn btn-primary";
        action = () => selectStart(false);
    }
    else {
        buttonClass = "btn btn-outline-primary";
        action = () => selectStart(true);
    }

    return (
        <form className="form-inline">
            <label htmlFor="fieldStart">Místo&nbsp;</label>
            <div className="input-group my-1 pr-2">
                <div className="input-group-prepend">
                    <button className={buttonClass}
                        onClick={() => action()}
                        type="button"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Klikněte zde a následně do mapy">
                        <FontAwesomeIcon icon={faMapMarker} />
                    </button>
                </div>
                <input readOnly value={startLocation} name="startLocation"
                    type="text" className="form-control" id="fieldStart" />
            </div>
            <button className="btn btn-light" onClick={reset}>Reset</button>
        </form>
    );
};

export default PlaceSetupForm;
