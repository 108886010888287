import React from 'react';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

import { faPlace } from '../../../icons/place';
import { faRoad } from '../../../icons/road';

const AddRecordPanel = () => {
    return (
        <div id='add-map-button'>
            <a title='Přidat' className="btn btn-primary map-button m-2" data-toggle="collapse" href="#collapseAddRecord" role="button" aria-expanded="false" aria-controls="collapseExample">
                <FontAwesomeIcon icon={faPlusCircle} color={'white'} />
            </a>
            <div className="collapse" id="collapseAddRecord">

                {/* Add road button */}
                <div className='map-button-link-wrapper'>
                    <Link title='Cesta' className="btn btn-primary map-button m-2" to='/add-road'>
                        <FontAwesomeIcon icon={faRoad} color={'white'} />
                    </Link>
                </div>

                {/* Add place button */}
                <div className='map-button-link-wrapper'>
                    <Link title='Místo' className="btn btn-primary map-button m-2" to='/add-place'>
                        <FontAwesomeIcon icon={faPlace} color={'white'} />
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default AddRecordPanel;
