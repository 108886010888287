import React from 'react';
import VisitItem from './VisitItem';

const VisitList = ({ visits }) => {
    return (<table className="table">
        <tbody>
            {visits.map((Visit, i) => <VisitItem {...Visit} key={i} />)}
        </tbody>
    </table>);
};

export default VisitList;