import toastr from 'toastr';

import store from "../store/configureStore";
import { DriversRoadsApi as APIUrl } from "../configuration";

export const DriversRoadsApi = APIUrl;

export function getPlaintJsonOptions(method = "GET", data) {
    const headers = new Headers();
    headers.append("Accept", "application/json");
    headers.append("content-type", "application/json");
    const body = JSON.stringify(data);

    const options = {
        method,
        headers,
        body
    };

    return options;
}

export function jsonAPIAuthorizedRequest(method = "GET", data) {
    const headers = new Headers();
    headers.append("content-type", "application/json");
    headers.append("Accept", "application/json");
    let user = store.getState().oidc.user;
    if (user) {
        let token = user.access_token;
        headers.append("Authorization", `Bearer ${token}`);
    }

    const body = JSON.stringify(data);
    const options = {
        method,
        headers,
        body,
    };

    return options;
}

export function formAPIAuthorizedRequest(method = "GET", data) {
    const headers = new Headers();
    headers.append("Accept", "application/json");

    let user = store.getState().oidc.user;
    if (user) {
        let token = user.access_token;
        headers.append("Authorization", `Bearer ${token}`);
    }

    const options = {
        method,
        headers,
        body: data,
    };

    return options;
}

export function onApiError(e) {
    debugger;
    console.error(e)
    toastr.error('Chyba při zpracovávání požadavku');
}

export function onApiUnauthorized(e){
    debugger;
    console.warn(e)
    toastr.error('Chyba při zpracovávání požadavku');
}