// export const MARE_RECORD_PUBLIC_LOADING = 'MARE_RECORD_PUBLIC_LOADING';
// export const MARE_RECORD_PUBLIC_SUCCESS = 'MARE_RECORD_PUBLIC_SUCCESS';
// export const MARE_RECORD_PUBLIC_ERRORED = 'MARE_RECORD_PUBLIC_ERRORED';

// export const MARE_RECORD_PRIVATE_LOADING = 'MARE_RECORD_PRIVATE_LOADING';
// export const MARE_RECORD_PRIVATE_SUCCESS = 'MARE_RECORD_PRIVATE_SUCCESS';
// export const MARE_RECORD_PRIVATE_ERRORED = 'MARE_RECORD_PRIVATE_ERRORED';

export const DELETE_RECORD_LOADING = 'DELETE_RECORD_LOADING';
export const DELETE_RECORD_SUCCESS = 'DELETE_RECORD_SUCCESS';
export const DELETE_RECORD_ERRORED = 'DELETE_RECORD_ERRORED';