/*global google*/
import React from 'react';
import toastr from 'toastr';
import { throttle } from 'lodash';

import { CreateMap } from './MapConfiguration';
import MapToUrlHelper from './MapToUrlHelper';
import withGoogleMap from '../hoc/withGoogleMap';
import MapEditButton from './MapEditButton';
import GoogleApiHelper from '../../utils/GoogleApiHelper';
import NavigationPanel from './panels/NavigationPanel';
import MapUserPositionHelper from './PositionHelper';

const GetInitState = () => {
  return {
    editing: false,
    isDirty: false,
    countryCode: '',
    startLocation: "",
    startLocationLatLng: '',
    distanceMeters: 0,
    destLocationTitle: ''
  };
}

class RoadPreviewMap extends React.Component {
  constructor(props) {
    super(props);

    this.state = GetInitState();
    this.tempDirections = null;
    this.savedDirections = null;
    this.positionHelper = null;

    this.directionsDisplay = null;

    this.callDirections = this.callDirections.bind(this);
    this.setRouteDirections = this.setRouteDirections.bind(this);

    this.edit = this.edit.bind(this);
    this.throttleEdit = throttle(this.edit, 1000);

    this.done = this.done.bind(this);
    this.cancel = this.cancel.bind(this);

    this.onStartLocationChanged = this.onStartLocationChanged.bind(this);
    this.onDirectionsChanged = this.onDirectionsChanged.bind(this);
    this.onInitLoad = this.onInitLoad.bind(this);
  }

  onStartLocationChanged(address) {
    var directions = this.directionsDisplay.getDirections();
    let country = address.countryCode.toLowerCase();
    console.log('start changed');
    if (this.props.allowedCountries.includes(country)) {
      this.tempDirections = directions;
      this.setState({
        isDirty: true,
        countryCode: country
      });
    }
    else {
      toastr.warning(address.country + ' není podporováno');
      if (this.tempDirections) {
        this.directionsDisplay.setDirections(this.tempDirections);
      }
    }
  }

  onDirectionsChanged() {
    if (!this.state.editing) {
      return;
    }

    var directions = this.directionsDisplay.getDirections();
    //ditance validation
    let distance = directions.routes["0"].legs["0"].distance.value;

    if (distance > 500000) {
      toastr.warning('Maximální délka trasy 500km překročena - ' + (Math.round(distance / 1000 * 10) / 10) + 'km');
      if (this.tempDirections) {
        this.directionsDisplay.setDirections(this.tempDirections);
      }
      return;
    }
    //validate start address
    var startLocation = directions.routes["0"].legs["0"].start_location;
    GoogleApiHelper.geocodeLocation(startLocation.lng(), startLocation.lat()).then(this.onStartLocationChanged);
  }

  edit() {
    this.setState({
      editing: true
    });

    this.callDirections(this.props.record.mapData);
    this.directionsDisplay.setOptions({ draggable: true, preserveViewport: true });
  }

  cancel() {
    let initState = GetInitState();
    this.setState(initState);

    //RESET route
    this.directionsDisplay.setOptions({ draggable: false, preserveViewport: false });
    this.directionsDisplay.setDirections(this.savedDirections);
  }

  done() {
    if (!this.state.isDirty) {
      this.cancel();
      return;
    }

    this.setState({
      editing: false,
      isDirty: false
    });

    this.directionsDisplay.setOptions({ draggable: false, preserveViewport: false });

    var directions = this.directionsDisplay.getDirections();
    var startLocationTitle = directions.routes["0"].legs["0"].start_address;
    var destLocationTitle = directions.routes["0"].legs["0"].end_address;
    var distanceMeters = directions.routes["0"].legs["0"].distance.value

    var via_waypoints = directions.routes["0"].legs["0"].via_waypoints;
    var startLocation = directions.routes["0"].legs["0"].start_location;
    var endLocation = directions.routes["0"].legs["0"].end_location;

    let waypoints = [];
    var start = {
      lat: startLocation.lat(),
      lng: startLocation.lng()
    }
    waypoints.push(start);
    for (var i = 0; i < via_waypoints.length; i++) {
      var point = {
        lat: via_waypoints[i].lat(),
        lng: via_waypoints[i].lng()
      }
      waypoints.push(point);
    }

    var end = {
      lat: endLocation.lat(),
      lng: endLocation.lng()
    };
    waypoints.push(end);
    var previewUrl = MapToUrlHelper.convertToUrl(directions, 650, 650);
    var result = JSON.stringify(MapToUrlHelper.toDirectionsResult(directions));

    //call 
    this.props.editRoad(this.props.record.id,
      {
        countryCode: this.state.countryCode,
        startLocationTitle,
        destLocationTitle,
        distanceMeters,
        waypoints,
        mapUrl: previewUrl,
        directionsResult: result
      }
    );

    this.savedDirections = directions;
    this.tempDirections = null;
    this.drawResult(result)
  }

  drawResult(directionsResult) {
    let directions = JSON.parse(directionsResult);
    directions.request = { travelMode: 'DRIVING' };

    directions.routes[0].legs[0].steps = [];
    for (let index = 0; index < directions.path.length; index++) {
      let polyline = directions.path[index];
      let points = google.maps.geometry.encoding.decodePath(polyline);
      let step = {
        travel_mode: 'DRIVING',
        polyline: { points: polyline },
        path: points,
        lat_lngs: points
      };

      directions.routes[0].legs[0].steps.push(step);
    }

    let start = directions.start;
    directions.routes[0].legs[0].start_location = new google.maps.LatLng({ lat: start.lat, lng: start.lng });

    this.savedDirections = directions;
    this.directionsDisplay.setDirections(directions);
  }

  callDirections(mapData) {
    let reuqest = GoogleApiHelper.toDirectionsRequest(
      mapData.start,
      mapData.end,
      mapData.waypoints,
      this.props.record.avoidHighways);

    this.directionsService.route(reuqest, (resp, status) => this.setRouteDirections(resp, status));
  }

  setRouteDirections(response, status) {
    if (status === 'OK') {
      this.tempDirections = response;
      this.directionsDisplay.setDirections(response);
    } else {
      window.alert('Directions request failed due to ' + status);
    }
  }

  componentDidMount() {
    this.directionsService = new google.maps.DirectionsService();
    this.directionsDisplay = new google.maps.DirectionsRenderer(
      {
        draggable: false,
        preserveViewport: false
      });

    this.map = CreateMap(this.props.mapId);
    this.directionsDisplay.directions_changed = this.onDirectionsChanged;
    this.directionsDisplay.setMap(this.map);
    google.maps.event.addListenerOnce(this.map, 'idle', this.onInitLoad);

    let navigationMapButton = document.getElementById('navigation-map-button');
    navigationMapButton.index = 1;
    this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(navigationMapButton);

    if (this.props.isOwner) {
      let editPlaceButton = document.getElementById('edit-map-button');
      editPlaceButton.index = 1;

      this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(editPlaceButton);
    }

    this.drawResult(this.props.record.directionsResult);
  }

  onInitLoad() {
    this.positionHelper = new MapUserPositionHelper(this.map);
    this.positionHelper.startWatching();
    var panorama = this.map.getStreetView();
    panorama.setOptions({
      disableDefaultUI: true,
      fullscreenControl: true,
      showRoadLabels: false
    });
  }

  componentWillUnmount() {
    this.positionHelper.clearWatch();
  }

  render() {
    return (<div id="wrapper">
      <div style={{ visibility: 'collapse', position: 'absolute' }}>
        {this.props.isOwner && <MapEditButton
          editing={this.state.editing}
          onEditClick={this.throttleEdit}
          onCancelClick={this.cancel}
          onDoneClick={this.done} />}
        <NavigationPanel record={this.props.record} />
      </div>
      <div id={this.props.mapId}></div>
    </div>);
  }
}

export default withGoogleMap(RoadPreviewMap);