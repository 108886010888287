/*global google*/
import React from 'react';
import toastr from 'toastr';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationArrow } from '@fortawesome/free-solid-svg-icons';

import LocationHelper from '../../../utils/LocationHelper';
import GoogleApiHelper from '../../../utils/GoogleApiHelper';

class LocalePanel extends React.Component {
    constructor(props) {
        super(props);

        let addressField = '';
        if (this.props.address) {
            addressField = this.props.address;
        }

        this.state = { addressField };

        this.autocomplete = null;
        this.onAutocompleteChanged = this.onAutocompleteChanged.bind(this);

        this.onLocateMeClick = this.onLocateMeClick.bind(this);
        this.onLocateSuccess = this.onLocateSuccess.bind(this);
        this.onLocateError = this.onLocateError.bind(this);

        this.handleChange = this.handleChange.bind(this);
    }

    onAutocompleteChanged() {
        let place = this.autocomplete.getPlace();
        if (!place.geometry) {
            // User entered the name of a Place that was not suggested and
            // pressed the Enter key, or the Place Details request failed.
            toastr.error('Nepovedlo se najít adresu ' + place.name);
            return;
        }

        let location = GoogleApiHelper.parseAutocompleteResult(place);

        this.setState({ addressField: location.address });
        this.props.onLocationChange(location);
    }

    componentDidMount() {
        var input = document.getElementById('address-input');
        this.autocomplete = new google.maps.places.Autocomplete(input);
        this.autocomplete.setFields(['geometry.location', 'address_components', 'formatted_address']);
        this.autocomplete.addListener('place_changed', this.onAutocompleteChanged);
    }

    onLocateSuccess(location) {
        this.props.onLocationChange(location);
    }

    onLocateError(e) {
        toastr.error('Nepovedlo se lokalizovat uzivatele' + e);
    }

    onLocateMeClick() {
        LocationHelper.locateGPS(this.onLocateSuccess, this.onLocateError);
    }


    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    render() {
        return (
            <div className="input-group m-2" style={{ width: '200px' }} id='locale-map-button'>
                <div className="input-group-prepend">
                    <button
                        title="Moje poloha"
                        onClick={this.onLocateMeClick}
                        className="btn btn-primary map-button"
                        type="button">
                        <FontAwesomeIcon icon={faLocationArrow} color={'white'} />
                    </button>
                </div>
                <input type="text"
                    id="address-input"
                    name="addressField"
                    value={this.state.addressField}
                    onChange={this.handleChange}
                    className="form-control"
                    placeholder="Vyhledat místo" />
            </div>
        );
    }
}

export default LocalePanel;
