import {
    USER_SUCCESS,
    USER_IS_LOADING,
    USER_ERRORED
} from '../actions/userActionTypes';

export function userLoaded(state = {}, action) {
    switch (action.type) {
        case USER_SUCCESS:
            return action.user;
        case USER_ERRORED:
            return state;
        default:
            return state;
    }
}

export function userIsLoading(state = false, action) {
    switch (action.type) {
        case USER_IS_LOADING:
            return true;
        case USER_ERRORED:
        case USER_SUCCESS:
            return false;
        default:
            return state;
    }
}

export function userErrored(state = false, action) {
    switch (action.type) {
        case USER_ERRORED:
            return true;
        case USER_IS_LOADING:
        case USER_SUCCESS:
            return false;
        default:
            return state;
    }
}