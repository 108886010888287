import React, { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Helmet } from "react-helmet";
import SeasonTripItem from './SeasonTripItem';
import SeasonMap from './SeasonMap';
import SeasonUserProgress from './SeasonUserProgress';
import ApiClient from '../../api/ApiClient';
import { ISeasonDetailDto } from '../../types/types';
import LoadingComponent from '../common/LoadingComponent';
import { UserStats } from '../../types/seasonTypes';
import PartnerList from './PartnerList';
import SeasonUrlHelper from './SeasonUrlHelper';
import { SeasonContext } from './SeasonContext';

const SeasonPage = () => {

    let seasonConfig = useContext(SeasonContext);
    const [isLoading, setIsLoading] = useState(true);
    const [errored, setErrored] = useState(false);
    const [season, setSeason] = useState<ISeasonDetailDto>({} as ISeasonDetailDto);
    const [userProgressOverview, setUserProgressOverview] = useState<UserStats>({} as UserStats);

    const fetchSeason = async (id: number) => {
        console.log('fetching');
        setIsLoading(true);
        let apiSeason = await ApiClient.getSeason(id);

        if (apiSeason.errored) {
            setErrored(true);
            setIsLoading(false);
            return;
        }

        let fetchedSeason = apiSeason.data.season;
        setSeason(fetchedSeason);

        let metersCovered = 0;
        let stagesCovered = 0;
        let tripsCovered = 0;
        let completedTripsIds = [];

        for (let t = 0; t < fetchedSeason.trips.length; t++) {
            const trip = fetchedSeason.trips[t];
            for (let s = 0; s < trip.stages.length; s++) {
                const stage = trip.stages[s];
                if (stage.userCompleted) {
                    stagesCovered++;
                    metersCovered += stage.distanceMeters;
                }
            }

            if (trip.userCompleted) {
                tripsCovered++;
                completedTripsIds.push(trip.id);
            }
        }
        setUserProgressOverview({ traveledMeters: metersCovered, traveledTrips: tripsCovered, traveledStages: stagesCovered, completedTripsIds });

        setIsLoading(false);
        setErrored(false);
    }

    useEffect(() => {
        fetchSeason(seasonConfig.id);
    }, [seasonConfig.id])

    if (isLoading) {
        return <LoadingComponent />
    }

    return (
        <>
            <Helmet>
                <title>{seasonConfig.name ? seasonConfig.name : 'Loading'} - Drivers’ Roads</title>
            </Helmet>
            <div className="seasons-grid">
                <div className="seasons-grid__container">
                    <h1 className="seasons-grid__caption">{seasonConfig.name}</h1>

                    {
                        !isLoading &&
                        <>
                            {/* <p>{season.shortDescription}</p> */}
                            <div className="seasons-grid__desc">
                                <NavLink className="seasons-grid__link" to={SeasonUrlHelper.getSeasonAboutPageUrl(seasonConfig.id, season.urlKey)}>Číst více o sezóně</NavLink>
                            </div>
                            {/* <div className='py-4'>
                                <PartnerList seasonId={seasonConfig.id} partners={seasonConfig.partners} />
                            </div> */}
                            <SeasonUserProgress
                                season={{ totalTrips: season.trips.length, totalStages: season.trips.flatMap(o => o.stages).length, totalMeters: season.trips.map(o => o.totalMeters).reduce((a, b) => a + b, 0) }}
                                user={userProgressOverview} />
                        </>
                    }

                    {isLoading ? <LoadingComponent /> :
                        <div className='table-responsive-lg'>
                            {season.trips.sort((a, b) => a.order - b.order).map((item) =>
                                <SeasonTripItem seasonId={seasonConfig.id} seasonUrlKey={seasonConfig.urlKey} item={item} key={item.id} user={userProgressOverview} />
                            )}
                        </div>
                    }
                </div>
                <div className="seasons-grid__map">
                    <SeasonMap items={season.trips} />
                </div>
            </div>
        </>
    );
}

export default SeasonPage;