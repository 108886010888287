import {
    COMMENT_LIKE_TOGGLE_LOADING,
    COMMENT_LIKE_TOGGLE_SUCCESS,
    COMMENT_LIKE_TOGGLE_ERRORED,
} from './commentLikeActionTypes';

import { DriversRoadsApi, jsonAPIAuthorizedRequest, onApiError } from '../../api/apiHelper';

export function commentLikeSuccess(commentId, numberOfLikes, userLike) {
    return {
        type: COMMENT_LIKE_TOGGLE_SUCCESS,
        commentId,
        numberOfLikes,
        userLike
    };
}

export function commentLikeError(commentId) {
    return {
        type: COMMENT_LIKE_TOGGLE_ERRORED,
        commentId
    };
}

export function commentLikeProcessing(commentId) {
    return {
        type: COMMENT_LIKE_TOGGLE_LOADING,
        commentId
    };
}

export function toggleCommentLike(commentId, liked) {
    return (dispatch) => {
        dispatch(commentLikeProcessing(commentId));
        let options = jsonAPIAuthorizedRequest("POST");
        var url = '';

        if (liked) {
            url = DriversRoadsApi + 'api/comment/like/remove/' + commentId;
        } else {
            url = DriversRoadsApi + 'api/comment/like/add/' + commentId;
        }

        fetch(url, options)
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => response.json())
            .then((result) => {
                dispatch(commentLikeSuccess(commentId, result.count, result.user));
            })
            .catch((e) => {
                dispatch(commentLikeError(commentId));
                onApiError(e);
            });
    };
}
