import React from 'react';
import LoadingComponent from '../common/LoadingComponent';

const FetchMoreButton = ({ canFetch, loading, fetch }) => {
    if (loading) {
        return <LoadingComponent />
    }

    if (canFetch) {
        return (
            <p className={'pointer text-center text-primary'} onClick={fetch}>
                Načíst další
            </p>);
    }

    return (<div></div>);
}

export default FetchMoreButton;