import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart as selectedHeart } from '@fortawesome/free-solid-svg-icons';
import { faHeart as unselectedHeart } from '@fortawesome/free-regular-svg-icons';

import LoadingComponent from '../../common/LoadingComponent';

const Favourites = ({ enabled, recordId, loading, userFavourite, numberOfFavourites, onClick }) => {
    if (loading) {
        return (<LoadingComponent />);
    }

    if (!enabled) {
        return (
            <div> <FontAwesomeIcon icon={unselectedHeart} title='Oblíbené' />
                <div> {numberOfFavourites}</div>
            </div>
        );
    }

    return (
        <div className='user-interaction' onClick={() => onClick(recordId, userFavourite)}>
            {userFavourite ?
                <FontAwesomeIcon icon={selectedHeart} title='Oblíbené' /> :
                <FontAwesomeIcon icon={unselectedHeart} title='Oblíbené' />}
            <div> {numberOfFavourites}</div>
        </div>
    );
}

export default Favourites;