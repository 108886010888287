import React from 'react';
import { NavLink } from 'react-router-dom';
import PartnerList from '../season/PartnerList';

const SeasonFeatures = (props: { config: any }) => {

    let seasonId = props.config.configuration.currentSeason.seasonId;
    let seasonName = props.config.configuration.currentSeason.seasonName;
    let seasonUrlKey = props.config.configuration.currentSeason.seasonUrlKey;

    const partners = [
        {
            "id": 1,
            "name": "Toyota",
            "url": "https://www.toyota.cz/",
            "text": "Hlavní partner",
            "order": 0,
            "size": 200,
            "logoFileName": "toyota_logo.png"
        },
        {
            "id": 2,
            "name": "Autíčkář",
            "url": "https://www.autickar.cz/",
            "text": "Mediální partner",
            "order": 1,
            "size": 200,
            "logoFileName": "autickar-logo.png"
        }
    ]

    return (
        <div className="d-flex flex-column" id='season'>
            <div className="">
                <div className='container p-3 py-5'>
                    <h2 className='homepage-h2 py-2'>Užijte si letošní sezonu na 2 výletech s Drivers‘ Roads</h2>

                    <p className="text-justify py-4" style={{ fontSize: '20px' }}>Pro sezónu 2023 jsme pro Vás připravili 2 výlety. První výlet vás zavede až na vrchol Krušných hor, tedy Klínovec a na druhém si projedete rozmanité cesty v okolí Koryčan.</p>

                    <div className='text-center'>
                        <div className='d-inline p-3'><img className='m-2 rounded' style={{ maxHeight: 400, maxWidth: '80%' }} src={'/img/trip_krusne.jpg'} /></div>
                        <div className='d-inline p-3'><img className='m-2 rounded' style={{ maxHeight: 400, maxWidth: '80%' }} src={'/img/trip_hradiste.jpg'} /></div>
                    </div>
                    {/* <PartnerList seasonId={seasonId} partners={partners} /> */}

                    <p className="text-justify py-4" style={{ fontSize: '18px' }}>
                        Pro spuštění výletu je potřeba se přihlásit, aplikace následně bude zaznamenávat váš postup. Vše je samozřejmě zdarma, tak šťastnou cestu!
                    </p>
                    <p className="lead pt-2 text-center">
                        <NavLink className="btn btn-lg btn-primary" to={`/season/${seasonId}/${seasonUrlKey}`} >{seasonName}</NavLink>
                    </p>
                </div>
            </div>
        </div >
    )
}

export default SeasonFeatures;