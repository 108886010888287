import {
    COMMENT_DELETE_LOADING,
    COMMENT_DELETE_ERRROED,
    COMMENT_DELETE_SUCCESS,

    COMMENT_EDIT_LOADING,
    COMMENT_EDIT_SUCCESS,
    COMMENT_EDIT_ERRROED
} from './commentOwnerActionTypes';

import { DriversRoadsApi, jsonAPIAuthorizedRequest, onApiError } from '../../api/apiHelper';

function commentEditSuccess(commentId, text) {
    return {
        type: COMMENT_EDIT_SUCCESS,
        commentId,
        text
    };
}

function commentEditLoading(commentId) {
    return {
        type: COMMENT_EDIT_LOADING,
        commentId
    };
}

function commentEditErrored(commentId) {
    return {
        type: COMMENT_EDIT_ERRROED,
        commentId
    };
}

export function editComment(commentId, text) {
    return (dispatch) => {

        dispatch(commentEditLoading(commentId));
        let options = jsonAPIAuthorizedRequest("POST", { text });

        fetch(DriversRoadsApi + 'api/comment/update/' + commentId, options)
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then(() => dispatch(commentEditSuccess(commentId, text)))
            .catch((e) => {
                onApiError(e);
                dispatch(commentEditErrored(commentId));
            });
    };
}

function commentDeleteSuccess(commentId) {
    return {
        type: COMMENT_DELETE_SUCCESS,
        commentId
    };
}

function commentDeleteLoading(commentId) {
    return {
        type: COMMENT_DELETE_LOADING,
        commentId
    };
}

function commentDeleteErrored(commentId) {
    return {
        type: COMMENT_DELETE_ERRROED,
        commentId
    };
}

export function deleteComment(commentId) {
    return (dispatch) => {

        dispatch(commentDeleteLoading(commentId));
        let options = jsonAPIAuthorizedRequest("POST");

        fetch(DriversRoadsApi + 'api/comment/remove/' + commentId, options)
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then(() => {
                dispatch(commentDeleteSuccess(commentId));
            })
            .catch((e) => {
                onApiError(e);
                dispatch(commentDeleteErrored(commentId));
            });
    };
}
