import {
    DIARY_COMMENTS_FETCH_SUCCESS,
    DIARY_COMMENTS_FETCH_LOADING,
    DIARY_COMMENTS_FETCH_ERRORED
} from '../../actions/diary/commentsActionTypes';

export function commentsLoaded(state = [], action) {
    switch (action.type) {
        case DIARY_COMMENTS_FETCH_SUCCESS:
            return action.comments;
        case DIARY_COMMENTS_FETCH_ERRORED:
            return [];
        default:
            return state;
    }
}

const defaultPagination = {
    items: 5,
    page: 1
};

export function commentsPagination(state = defaultPagination, action) {
    switch (action.type) {
        case DIARY_COMMENTS_FETCH_SUCCESS:
            return action.pagination;
        default:
            return state;
    }
}

export function commentsIsLoading(state = false, action) {
    switch (action.type) {
        case DIARY_COMMENTS_FETCH_LOADING:
            return true;
        case DIARY_COMMENTS_FETCH_ERRORED:
        case DIARY_COMMENTS_FETCH_SUCCESS:
            return false;
        default:
            return state;
    }
}

export function commentsErrored(state = false, action) {
    switch (action.type) {
        case DIARY_COMMENTS_FETCH_ERRORED:
            return true;
        case DIARY_COMMENTS_FETCH_LOADING:
        case DIARY_COMMENTS_FETCH_SUCCESS:
            return false;
        default:
            return state;
    }
}