import React from 'react';

const OptionSelect = ({ text, name, value, options, onChange, className = "form-group col-md-2" }) => {

    let items = options.map((o, i) => <option key={i} value={o.value}>{o.text}</option>);

    return (
        <div className={className}>
            {text && <label htmlFor={name}>{text}</label>}
            <select className="form-control" name={name} value={value} onChange={onChange}>
                {items}
            </select>
        </div>
    );
}

export default OptionSelect;