import React from 'react';

class EnvironmentText extends React.Component {

    render() {
        return (
            <div className="alert alert-primary mb-0">
                <div className="container">
                    <span>
                        Toto je testovací verze aplikace. Veškerá data či změny nebudou promítnuty do produkční verze a budou ztraceny.
                    </span>
                </div>
            </div>
        );
    }
}

export default EnvironmentText;