import {
    RECORD_RATING_LOADING,
    RECORD_RATING_SUCCESS,
    RECORD_RATING_ERRORED,
} from './recordRatingActionTypes';

import { DriversRoadsApi, jsonAPIAuthorizedRequest, onApiError } from '../../api/apiHelper';

function recordRatingSuccess(recordId, numberOfRatings, rating, userRating) {
    return {
        type: RECORD_RATING_SUCCESS,
        recordId,
        rating,
        numberOfRatings,
        userRating
    };
}

function recordRatingError(recordId) {
    return {
        type: RECORD_RATING_ERRORED,
        recordId
    };
}

function recordRatingProcessing(recordId) {
    return {
        type: RECORD_RATING_LOADING,
        recordId
    };
}

export function updateRecordRating(recordId, stars) {
    return (dispatch) => {
        dispatch(recordRatingProcessing(recordId));
        let options = jsonAPIAuthorizedRequest("POST", { stars: stars });
        var url = DriversRoadsApi + 'api/record/rating/add/' + recordId;
        fetch(url, options)
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => response.json())
            .then((result) => {
                dispatch(recordRatingSuccess(recordId, result.numberOfRatings, result.rating, stars));
            })
            .catch((e) => {
                onApiError(e);
                dispatch(recordRatingError(recordId));
            });
    };
}
