var prefix = 'fas';
var iconName = 'wheel-outline';
var width = 512;
var height = 512;
var ligatures = [];
var unicode = 'fffe';
var svgPathData = 'M256,0C114.8,0,0,114.8,0,256s114.8,256,256,256s256-114.8,256-256S397.2,0,256,0z M256,482 C131.4,482,30,380.6,30,256S131.4,30,256,30s226,101.4,226,226S380.6,482,256,482z M233.5,433c-0.8-91-66.2-169.1-155.7-185.5 l-18.3-3.4l0.6,18.6c1.5,43.8,17.9,86.4,46.3,119.9c28.1,33.1,66.7,56.3,108.9,65.2l18.3,3.9L233.5,433z M129.3,363.2 c-20-23.6-32.7-51.4-37.3-81.3c58.7,18.7,101.7,70,110,131.1C174,403.3,148.7,386,129.3,363.2z M442.4,195.4 c-12.7-39-37-72.6-70.2-97.1C338.3,73.2,298.1,60,256,60s-82.3,13.2-116.2,38.3c-33.2,24.5-57.5,58.1-70.2,97.1L63.2,215h385.6 L442.4,195.4z M106,185L106,185c11.7-24.6,29.6-46.4,51.6-62.6C186.3,101.2,220.3,90,256,90s69.7,11.2,98.4,32.4 c22,16.3,39.9,38,51.6,62.6H106z M434.2,247.5c-89.4,16.4-154.9,94.5-155.7,185.5l-0.2,18.7l18.3-3.9c42.2-8.9,80.9-32.1,108.9-65.2 c28.4-33.5,44.9-76.1,46.3-119.9l0.6-18.6L434.2,247.5z M382.6,363.2C363.3,386,338,403.3,310,413c8.3-61.1,51.3-112.4,110-131.1 C415.3,311.8,402.6,339.6,382.6,363.2z M297.5,265.6c-8.5-20.5-27.7-27.8-41.6-27.8c-5.8,0-11.4,1.1-17.1,3.5 c-14.2,5.9-21.1,16.4-24.3,24.3c-3.3,7.8-5.9,20.2,0,34.4c8.5,20.5,27.7,27.8,41.6,27.8c5.8,0,11.4-1.1,17.1-3.5 C296.1,314.8,307,288.5,297.5,265.6z M261.7,296.5c-2,0.8-3.7,1.2-5.6,1.2c-1,0-10.2-0.3-13.9-9.3c-1.6-3.8-1.6-7.6,0-11.4 c1.6-3.8,4.2-6.5,8.1-8.1c2-0.8,3.8-1.2,5.6-1.2c1,0,10.2,0.3,13.9,9.3C273,284.7,269.3,293.4,261.7,296.5z';

export const faWheelOutline = {
    // Use a prefix like 'fac' that doesn't conflict with a prefix in the standard Font Awesome styles
    // (So avoid fab, fal, fas, far, fa)
    prefix: prefix,
    iconName: iconName,
    icon: [
        width,
        height,
        ligatures,
        unicode,
        svgPathData
    ]
};