import {
    DELETE_RECORD_ERRORED,
    DELETE_RECORD_LOADING,
    DELETE_RECORD_SUCCESS,
} from './recordOwnerActionTypes';

import { DriversRoadsApi, jsonAPIAuthorizedRequest, onApiError } from '../../api/apiHelper';

function deleteRecordSuccess(recordId) {
    return {
        type: DELETE_RECORD_SUCCESS,
        recordId
    };
}

function deleteRecordError(recordId) {
    return {
        type: DELETE_RECORD_ERRORED,
        recordId
    };
}

function deleteRecordProcessing(recordId) {
    return {
        type: DELETE_RECORD_LOADING,
        recordId
    };
}

export function deleteRecord(recordId) {
    return (dispatch) => {
        dispatch(deleteRecordProcessing(recordId));
        let options = jsonAPIAuthorizedRequest("POST");
        debugger;
        var url = DriversRoadsApi + 'api/record/delete/' + recordId;

        fetch(url, options)
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((result) => {
                dispatch(deleteRecordSuccess(recordId));
            })
            .catch((e) => {
                onApiError(e);
                dispatch(deleteRecordError(recordId));
            });
    };
}

// function toPrivateRecordSuccess(recordId) {
//     return {
//         type: MARE_RECORD_PRIVATE_SUCCESS,
//         recordId
//     };
// }

// function toPrivateRecordError(recordId) {
//     return {
//         type: MARE_RECORD_PRIVATE_ERRORED,
//         recordId
//     };
// }

// function toPrivateRecordProcessing(recordId) {
//     return {
//         type: MARE_RECORD_PRIVATE_LOADING,
//         recordId
//     };
// }

// function toPublicRecordSuccess(recordId) {
//     return {
//         type: MARE_RECORD_PUBLIC_SUCCESS,
//         recordId
//     };
// }

// function toPublicRecordError(recordId) {
//     return {
//         type: MARE_RECORD_PUBLIC_ERRORED,
//         recordId
//     };
// }

// function toPublicRecordProcessing(recordId) {
//     return {
//         type: MARE_RECORD_PUBLIC_LOADING,
//         recordId
//     };
// }

// export function makeRecordPrivate(recordId) {
//     return (dispatch) => {
//         debugger;
//         dispatch(toPrivateRecordProcessing(recordId));
//         let options = jsonAPIAuthorizedRequest("POST");
//         var url = DriversRoadsApi + 'api/record/toprivate/' + recordId;

//         fetch(url, options)
//             .then((response) => {
//                 if (!response.ok) {
//                     throw Error(response.statusText);
//                 }
//                 return response;
//             })
//             .then(() => { dispatch(toPrivateRecordSuccess(recordId)); })
//             .catch((e) => {
//                 onApiError(e);
//                 dispatch(toPrivateRecordError(recordId));
//             });
//     };
// }

// export function makeRecordPublic(recordId) {
//     return (dispatch) => {
//         dispatch(toPublicRecordProcessing(recordId));
//         let options = jsonAPIAuthorizedRequest("POST");
//         var url = DriversRoadsApi + 'api/record/topublic/' + recordId;

//         fetch(url, options)
//             .then((response) => {
//                 if (!response.ok) {
//                     throw Error(response.statusText);
//                 }
//                 return response;
//             })
//             .then(() => { dispatch(toPublicRecordSuccess(recordId)); })
//             .catch((e) => {
//                 onApiError(e);
//                 dispatch(toPublicRecordError(recordId));
//             });
//     };
// }