var prefix = 'fas';
var iconName = 'road';
var width = 512;
var height = 512;
var ligatures = [];
var unicode = 'fffd';
var svgPathData = 'M240,296.7c0-51.8-42.2-94-94-94c-51.8,0-94,42.2-94,94c0,15.2,3.6,29.5,10,42.2L146,512l84-173.1 C236.4,326.2,240,311.8,240,296.7z M146,348.7c-28.7,0-52-23.3-52-52s23.3-52,52-52c28.7,0,52,23.3,52,52 C198,325.3,174.7,348.7,146,348.7z M412.4,0c-26.2,0-47.6,21.3-47.6,47.6c0,7.7,1.8,15,5.1,21.4l42.5,87.7L454.9,69 c3.2-6.4,5.1-13.7,5.1-21.4C459.9,21.3,438.6,0,412.4,0z M412.4,73.9c-14.5,0-26.3-11.8-26.3-26.3c0-14.5,11.8-26.3,26.3-26.3 c14.5,0,26.3,11.8,26.3,26.3C438.7,62.1,426.9,73.9,412.4,73.9z M370.9,308.8c-1.9-9.1-4.4-18-7.6-26.7c-4.2-11.7-9.3-23.1-13.2-35 c-7.3-22.1,5.3-40.6,7.6-44c4.1-5.9,9-11.3,14.1-16.3c9.8-9.7,20.6-18.4,31.9-26.4c-1.4-2.2-2.8-4.4-4.1-6.6 c-16.3,6.4-100,35.6-98,100.3c0.4,13,5.4,25.3,8.5,37.9c1.5,6.1,2.8,12.2,3.6,18.3c0.3,2.8,0.5,5.6,0.8,8.4c0,0,0,0,0,0 c0,0.1,0.2,8.9-0.2,12.7c-1.2,11.9-3,24-8,34.9c-4.7,10.2-11.7,19-20.4,26c-19.9,16-46.4,22.4-71.3,24.9c-1.2,0.1-2.4,0.2-3.6,0.3 l-44.5,91.8c22-1.4,43.9-4.4,65.2-9.6c25.2-6.2,49.7-15.5,71.6-29.4c21.9-13.8,40.8-32.6,53.4-55.4c13.1-23.9,18.4-51.2,17.3-78.3 C373.7,327.3,372.8,317.8,370.9,308.8z';

export const faRoad = {
    // Use a prefix like 'fac' that doesn't conflict with a prefix in the standard Font Awesome styles
    // (So avoid fab, fal, fas, far, fa)
    prefix: prefix,
    iconName: iconName,
    icon: [
        width,
        height,
        ligatures,
        unicode,
        svgPathData
    ]
};