/*global google*/
import { isMobile } from "react-device-detect";

export function CreateAtlasMap(mapId) {

    //ATLAS
    let mapOptions = {
        zoom: 9,
        minZoom: 6,
        center: { lat: 50.075538, lng: 14.437800 },
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
    };

    if (!isMobile) {
        mapOptions.mapTypeControl = true;
        mapOptions.mapTypeControlOptions = {
            mapTypeIds: [
                google.maps.MapTypeId.ROADMAP,
                google.maps.MapTypeId.SATELLITE
            ],
            style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
            position: google.maps.ControlPosition.LEFT_BOTTOM
        };
    } else {
        mapOptions.zoomControl = false;
        mapOptions.mapTypeControl = false;
        mapOptions.gestureHandling = 'greedy';
    }

    return new google.maps.Map(document.getElementById(mapId), mapOptions);
}

export function CreateMap(mapId) {

    let mapOptions = {
        zoom: 6,
        center: {
            lat: 50.85,
            lng: 14.65
        },
        gestureHandling: 'greedy',
        rotateControl: false,
        fullscreenControl: false,
    };

    if (!isMobile) {
        mapOptions.streetViewControl = true;
        mapOptions.mapTypeControl = true;
        mapOptions.mapTypeControlOptions = {
            mapTypeIds: [
                google.maps.MapTypeId.ROADMAP,
                google.maps.MapTypeId.SATELLITE
            ],
            style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
            position: google.maps.ControlPosition.LEFT_BOTTOM
        };
    } else {
        mapOptions.zoomControl = false;
        mapOptions.mapTypeControl = false;
        mapOptions.gestureHandling = 'greedy';
    }

    return new google.maps.Map(document.getElementById(mapId), mapOptions);
} 