import {
    RECORD_COMMENTS_FETCH_LOADING,
    RECORD_COMMENTS_FETCH_SUCCESS,
    RECORD_COMMENTS_FETCH_ERRORED,

    RECORD_COMMENTS_PAGINATION_CHANGED
} from './recordCommentsActionTypes';

import { DriversRoadsApi, jsonAPIAuthorizedRequest, onApiError } from '../../api/apiHelper';

function recordCommentsFetchSuccess(comments) {
    return {
        type: RECORD_COMMENTS_FETCH_SUCCESS,
        comments
    };
}

function recordCommentsPaginationChanged(pagination) {
    return {
        type: RECORD_COMMENTS_PAGINATION_CHANGED,
        pagination
    };
}

function recordCommentsFetchLoading() {
    return {
        type: RECORD_COMMENTS_FETCH_LOADING
    };
}

function recordCommentsFetchErrored() {
    return {
        type: RECORD_COMMENTS_FETCH_ERRORED
    };
}

export function recordCommentsFetch(recordId, pagination) {
    return (dispatch) => {

        dispatch(recordCommentsFetchLoading());

        let options = jsonAPIAuthorizedRequest("GET");
        fetch(DriversRoadsApi + 'api/comment/getbyrecord/' + recordId + '?' + pagination, options)
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => response.json())
            .then((response) => {
                dispatch(recordCommentsFetchSuccess(response.items));
                dispatch(recordCommentsPaginationChanged(response.pagination));
            })
            .catch((e) => {
                onApiError(e);
                dispatch(recordCommentsFetchErrored());
            });
    };
}