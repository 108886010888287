import React from 'react';
import { connect } from 'react-redux';
import { stringify } from 'qs';
import { Helmet } from "react-helmet";

import RequestHelper from '../../api/RequestHelper';

import { databaseFetchByFilter } from '../../actions/databaseActionCreators';
import { toggleRecordFavourite } from '../../actions/record/recordFavouriteActionCreators';
import { toggleRecordVisit } from '../../actions/record/recordVisitActionCreator';
import { updateRecordRating } from '../../actions/record/recordRatingActionCreators';

import DatabaListSummary from './DatabaListSummary'
import DatabaseRecordList from './DatabaseRecordList';
import DatabaseFilterPanel from './DatabaseFilterPanel';
import LoadingComponent from '../common/LoadingComponent';

import Pagination from '../common/Pagination';

const GetDefaultFilter = (configuration) => {
    return {
        recordType: 'all',
        minRating: 0,
        maxRating: 5,
        minNumRating: 0,
        maxNumRating: configuration.maxRatings,
        minVisits: 0,
        maxVisits: configuration.maxVisits,
        minComments: 0,
        maxComments: configuration.maxComments,
        minFavourites: 0,
        maxFavourites: configuration.maxFavourites,
        minDistance: 0,
        maxDistance: 500
        // hideVisited: false //not in first version
    }
}

class DatabasePage extends React.Component {

    constructor(props) {
        super(props);

        if (this.props.filter) {
            this.state = this.props.filter;
            this.state.defaultFilter = GetDefaultFilter(props.configuration)
            this.state.isInit = false;
        }
        else {
            this.state = {
                filter: GetDefaultFilter(props.configuration),
                defaultFilter: GetDefaultFilter(props.configuration),
                location: {},
                range: 100,
                items: 10,
                sort: 0,
                isInit: true
            };
        }

        this.changeToPage = this.changeToPage.bind(this);
        this.changeFilter = this.changeFilter.bind(this);
        this.fetch = this.fetch.bind(this);

        this.onLocationChange = this.onLocationChange.bind(this);
        this.changeOption = this.changeOption.bind(this);
    }

    changeOption(field, value) {
        let state = this.state;
        state[field] = value;
        this.setState({ ...state });

        let location = state.location;
        let filter = state.filter;
        let range = state.range;
        let items = state.items;
        let sort = state.sort;

        this.fetch(location, filter, range, 1, items, sort);
    }

    onLocationChange(location) {
        let filter = this.state.filter;
        let range = this.state.range;
        let items = this.state.items;
        let sort = this.state.sort;

        if (this.state.location.countryCode &&
            this.state.location.countryCode.toLowerCase() !== location.countryCode.toLowerCase()) {
            range = 100;
        }

        this.setState({ location: location, range: range });
        this.fetch(location, filter, range, 1, items, sort);
    }

    changeFilter(filter) {
        let location = this.state.location;
        let range = this.state.range;
        let items = this.state.items;
        let sort = this.state.sort;

        this.setState({ filter: filter, page: 1 });
        this.fetch(location, filter, range, 1, items, sort);
    }

    changeToPage(pageNumber) {
        let location = this.state.location;
        let filter = this.state.filter;
        let range = this.state.range;
        let items = this.state.items;
        let sort = this.state.sort;

        this.fetch(location, filter, range, pageNumber, items, sort);
    }

    fetch(location, filter, range, page, items, sort) {
        let rangeObj = RequestHelper.buildRangeQuery(location.countryCode, location.lng, location.lat, range);
        let filterObj = RequestHelper.buildFilter(filter, this.props.configuration);
        let paginationObj = RequestHelper.buildPaginationAndSort(page, items, sort);

        let rangeQuery = stringify(rangeObj)
        let fitlerQuery = stringify(filterObj)
        let paginationQuery = stringify(paginationObj);
        let query = fitlerQuery === '' ? paginationQuery : paginationQuery + '&' + fitlerQuery;

        this.props.history.push({
            pathname: this.props.history.location.pathname,
            search: '?' + query
        });

        let saveState = {
            location,
            filter,
            range,
            page,
            items,
            sort
        }
        this.props.fetchByFilter(rangeQuery + '&' + fitlerQuery + '&' + paginationQuery, saveState);
        this.setState({ isInit: false });
    }

    render() {
        let isLoggedIn = this.props.user != null && !this.props.user.expired;

        return (
            <div className='container content-wrapper'>
                <Helmet>
                    <title>Databáze nejlepších silnic - Drivers’ Roads</title>
                </Helmet>
                <DatabaseFilterPanel
                    onLocationChange={this.onLocationChange}
                    changeOption={this.changeOption}
                    changeFilter={this.changeFilter}
                    location={this.state.location}
                    defaultFilter={this.state.defaultFilter}
                    filter={this.state.filter}
                    range={this.state.range}
                    items={this.state.items}
                    sort={this.state.sort}
                    configuration={this.props.configuration} />
                {this.props.isLoading || this.state.isInit ? <LoadingComponent /> :
                    <div>
                        <DatabaListSummary pagination={this.props.pagination} />
                        <DatabaseRecordList
                            items={this.props.databaseRecords}
                            logged={isLoggedIn}
                            onFavouriteClick={this.props.toggleRecordFavourite}
                            onVisitClick={this.props.toggleRecordVisit}
                            onRatingClick={this.props.updateRecordRating} />
                        {this.props.pagination.totalItems > 0 && <Pagination
                            changeToPage={this.changeToPage}
                            pagination={this.props.pagination} />}
                    </div>
                }
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchByFilter: (query, filter) => dispatch(databaseFetchByFilter(query, filter)),
        toggleRecordFavourite: (id, isFavourite) => dispatch(toggleRecordFavourite(id, isFavourite)),
        toggleRecordVisit: (id, visited) => dispatch(toggleRecordVisit(id, visited)),
        updateRecordRating: (id, rating) => dispatch(updateRecordRating(id, rating))
    };
};

const mapStateToProps = (state) => {
    return {
        configuration: state.configuration.configuration,
        databaseRecords: state.database.records,
        pagination: state.database.pagination,
        filter: state.database.filter,
        errored: state.database.errored,
        isLoading: state.database.isLoading,
        user: state.oidc.user
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DatabasePage);