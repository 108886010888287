import React from 'react';
import CommentLightItem from './CommentLightItem';

const CommentLightList = ({ comments }) => {
    return (<table className="table">
        <tbody>
            {comments.map((comment, i) => <CommentLightItem {...comment} key={i} />)}
        </tbody>
    </table>);
};

export default CommentLightList;