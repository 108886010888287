const Url = 'https://ipapi.co/json';

class IpApiHelper {

    static locate() {
        return new Promise((resolve, reject) => {
            fetch(Url)
                .then((response) => {
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                    return response;
                })
                .then((response) => response.json())
                .then((result) => {
                    resolve({ lng: result.longitude, lat: result.latitude, countryCode: result.country, country: result.country_name });
                })
                .catch((e) => {
                    console.error(e);
                    reject(e);
                });
        });
    }
}

export default IpApiHelper;