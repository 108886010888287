/*global google*/
import React from 'react';
import toastr from 'toastr';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationArrow } from '@fortawesome/free-solid-svg-icons';

import withGoogleMap from '../hoc/withGoogleMap';

import LocationHelper from '../../utils/LocationHelper';
import GoogleApiHelper from '../../utils/GoogleApiHelper';

class LocationInputFieldWithButton extends React.Component {

    constructor(props) {
        super(props);

        let address = '';
        if (props.address) {
            address = props.address;
        }

        this.state = { address };

        this.autocomplete = null;
        this.onAutocompleteChanged = this.onAutocompleteChanged.bind(this);

        this.onLocateMeClick = this.onLocateMeClick.bind(this);

        this.onLocateSuccess = this.onLocateSuccess.bind(this);
        this.onLocateError = this.onLocateError.bind(this);

        this.handleChange = this.handleChange.bind(this);
    }

    onAutocompleteChanged() {
        let place = this.autocomplete.getPlace();
        if (!place.geometry) {
            // User entered the name of a Place that was not suggested and
            // pressed the Enter key, or the Place Details request failed.
            toastr.error('Nepovedlo se najít adresu ' + place.name);
            return;
        }

        let location = GoogleApiHelper.parseAutocompleteResult(place);

        this.setState({ address: location.address });
        this.props.onLocationChange(location);
    }

    componentDidMount() {
        var input = document.getElementById('address-input');
        this.autocomplete = new google.maps.places.Autocomplete(input);
        this.autocomplete.setFields(['geometry.location', 'address_components', 'formatted_address']);
        this.autocomplete.addListener('place_changed', this.onAutocompleteChanged);

        if (!this.state.address) {
            LocationHelper.locateAddress(this.onLocateSuccess, this.onLocateError);
        }
    }

    onLocateSuccess(location) {
        this.setState({ address: location.address });
        this.props.onLocationChange(location);
    }

    onLocateError(e) {
        toastr.error('Nepovedlo se lokalizovat uzivatele' + e);
    }

    onLocateMeClick() {
        LocationHelper.locateAddress(this.onLocateSuccess, this.onLocateError);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    render() {

        return (
            <div className="form-group col-md-3">
                <label htmlFor='name'>Start vyhledávání</label>
                <div className="input-group">
                    <div className="input-group-prepend">
                        <button
                            title='Moje poloha'
                            onClick={this.onLocateMeClick}
                            className="btn btn-outline-primary"
                            type="button">
                            <FontAwesomeIcon icon={faLocationArrow} />
                        </button>
                    </div>
                    <input type="text"
                        id="address-input"
                        name="address"
                        required
                        value={this.state.address}
                        onChange={this.handleChange}
                        className="form-control" />
                </div>
            </div>
        );
    }
}

export default withGoogleMap(LocationInputFieldWithButton);