import { init } from '@sentry/browser';
export const googleMapsKey = 'AIzaSyC7UHlNYtmUEnHuUDqU2As3ysY5VsD3wgg';

export const ClientId = 'drivers-roads-js-client';
export const Scope = 'drivers-roads-api';
export const AutozkaUrl = 'https://account.autozka.com/';
export const DriversRoadsApi = 'https://api.driversroads.com/';
export const PhotoUrl = 'https://drivers-roads-prod-gallery-seasons.s3.amazonaws.com/';

const sentryDsn = 'https://00b59a2c5f6046d1884cde15b3e8fc4b@sentry.io/1446344';

export function configureSentry() {

    if (!sentryDsn) {
        return;
    }

    init({
        dsn: sentryDsn
    });
};