import React, { useEffect, useState } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import { connect } from 'react-redux';

import ApiClient from '../../api/ApiClient';

import SeasonPage from './SeasonPage';
import SeasonInfo from './SeasonInfo';
import TripPage from './trip/TripPage';
import { ISeasonConfigurationDto } from '../../types/types';
import LoadingComponent from '../common/LoadingComponent';

import { UserContext } from './UserContext';
import { SeasonContext } from './SeasonContext';


export interface ISeasonParams {
    seasonId: string;
    seasonUrlKey: string
}

const SeasonRouter = (props: { user: any }) => {

    let { seasonId, seasonUrlKey } = useParams<ISeasonParams>();

    if (props.user) {
    }

    const [isLoading, setIsLoading] = useState(true);
    const [errored, setErrored] = useState(false);
    const [season, setConfiguration] = useState<ISeasonConfigurationDto>({} as ISeasonConfigurationDto);

    const fetchConfiguration = async () => {
        setIsLoading(true);
        const apiDevices = await ApiClient.getSeasonConfiguration(Number.parseInt(seasonId));

        if (apiDevices.errored) {
            setErrored(true);
            setIsLoading(false);
            return;
        }

        setConfiguration(apiDevices.data);
        setErrored(false);
        setIsLoading(false);
    };

    useEffect(() => {
        fetchConfiguration();
    }, [seasonId]);

    if (isLoading) {
        return <LoadingComponent />
    }

    return (
        <UserContext.Provider value={props.user} >
            <SeasonContext.Provider value={season} >
                <Switch>
                    <Route path='/season/:seasonId/:seasonUrlKey' exact={true} ><SeasonPage /></Route>
                    <Route path='/season/:seasonId/:seasonUrlKey/about' exact={true} ><SeasonInfo /></Route>
                    <Route path='/season/:seasonId/:seasonUrlKey/trip/:tripId/:tripUrlKey' ><TripPage /></Route>
                </Switch>
            </SeasonContext.Provider>
        </UserContext.Provider>
    );
}

const mapStateToProps = (state: any) => {
    return {
        user: state.oidc.user
    };
};

export default connect(mapStateToProps)(SeasonRouter);
