import React from 'react';
import { NavLink } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import PhotoUrlPathHelper from '../../api/PhotoUrlPathHelper';
import { UserStats } from '../../types/seasonTypes';

import { ISeasonTripDto } from '../../types/types';
import SeasonUrlHelper from './SeasonUrlHelper';

interface SeasonTripItemProps {
    item: ISeasonTripDto,
    seasonId: number,
    seasonUrlKey: string,
    user: UserStats
}

const SeasonTripItem = (props: SeasonTripItemProps) => {

    const isComplete = props.user.completedTripsIds.find((o) => o === props.item.id) !== undefined;
    const tripUrl = SeasonUrlHelper.getSeasonTripPageUrl(props.seasonId, props.seasonUrlKey, props.item.id, props.item.urlKey);

    return (
        <>
            <div className='season-card'  >
                <NavLink to={tripUrl} className='season-card__image'>
                    {props.item.photoFileName && <img src={PhotoUrlPathHelper.getStagePhotoThumbUrl(props.seasonId, props.item.id, props.item.photoStage, props.item.photoFileName)}></img>}
                </NavLink>
                <div className='season-card__content'>
                    <h2 className='season-card__name' id={`trip-scroll-${props.item.id}`}>
                        <NavLink to={tripUrl} >{props.item.order + 1} {props.item.name} {isComplete && <FontAwesomeIcon className='primary' icon={faCheckCircle} />}</NavLink>
                    </h2>
                    <div className='season-card__info'>Etapy: {JSON.parse(props.item.serializedPolylines).length} | Délka: {(props.item.totalMeters / 1000).toFixed(2)} km</div>
                    <p className='season-card__text'>
                        {props.item.description}
                    </p>
                </div>
            </div>
        </>
    );
}


export default SeasonTripItem;
