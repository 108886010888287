import { combineReducers } from 'redux';
// import { routerReducer } from 'react-router-redux';
import { reducer as oidcReducer } from 'redux-oidc';

import { userLoaded, userIsLoading, userErrored } from './userReducer';

import configurationReducer from './configurationReducer';

import atlasReducer from './atlasReducer';
import diaryReducer from './diary/diaryReducer';
import databaseReducer from './databaseReducer';

import { commentLoaded, commentIsLoading, commentErrored } from './commentReducer';

import {
    commentRecordPreviewLoaded, commentRecordPreviewIsLoading, commentRecordPreviewErrored,
    commentAddLoading, commentAddErrored
} from './commentAddReducer';

import { recordLoaded, recordIsLoading, recordErrored } from './recordReducer';
import recordCommentsReducer from './recordCommentsReducer';
import { recordAddLoading } from './recordAddReducer';


const combinedReducers = combineReducers({
    oidc: oidcReducer,
    // routing: routerReducer,
    configuration: configurationReducer,
    user: combineReducers({
        user: userLoaded,
        isLoading: userIsLoading,
        errored: userErrored
    }),
    atlas: atlasReducer,
    database: databaseReducer,
    addRecord: combineReducers({
        isLoading: recordAddLoading
    }),
    record: combineReducers({
        record: recordLoaded,
        isLoading: recordIsLoading,
        errored: recordErrored,
        comments: recordCommentsReducer,
    }),
    addComment: combineReducers({
        record: combineReducers({
            record: commentRecordPreviewLoaded,
            isLoading: commentRecordPreviewIsLoading,
            errored: commentRecordPreviewErrored
        }),
        isLoading: commentAddLoading,
        errored: commentAddErrored
    }),
    comment: combineReducers({
        comment: commentLoaded,
        isLoading: commentIsLoading,
        errored: commentErrored
    }),
    diary: diaryReducer
});

export default combinedReducers;