import React from 'react';

class EditPlaceFields extends React.Component {
  render() {
    let latitude = '';
    let longtitude = '';

    if (this.props.startLocation) {
      latitude = this.props.startLocation.lat();
      longtitude = this.props.startLocation.lng();
    }

    return (<div className="row justify-content-center mt-3">
      <div className="col-lg-8">
        <form>
          <div className="form-group">
            <label htmlFor="nameField">Název</label>
            <input type="text"
              required
              value={this.props.name}
              name="name"
              onChange={this.props.handleChange}
              className="form-control" id="nameField" />
          </div>
          <div className="form-group">
            <label htmlFor="descriptionField">Popis</label>
            <textarea value={this.props.description}
              placeholder="Popis místa, atmosféry, výhledů, zajímavostí. Kolik lidí sem jezdí, kdy je nejlepší čas sem zajet?"
              name="description"
              onChange={this.props.handleChange}
              className="form-control" id="descriptionField" rows="3"></textarea>
          </div>
          <div className="form-group row">
            <div className="col-sm-4">Soukromé</div>
            <div className="col-sm-8">
              <div className="form-check">
                <input checked={this.props.isPrivate}
                  name="isPrivate"
                  onChange={this.props.handleChange}
                  className="form-check-input" type="checkbox" />
              </div>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="latitudeField">Šířka</label>
            <input type="text"
              value={latitude}
              className="form-control"
              id="latitudeField" readOnly placeholder="" />
          </div>
          <div className="form-group">
            <label htmlFor="longtitudeField">Délka</label>
            <input type="text"
              value={longtitude}
              className="form-control"
              id="longtitudeField" readOnly placeholder="" />
          </div>
        </form>
      </div>
    </div>);
  }
}

export default EditPlaceFields;
