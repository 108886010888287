import React from 'react';

import AtlasRecordItem from './AtlasRecordItem';

import LoadingComponent from '../common/LoadingComponent';
import ErrorComponent from '../common/ErrorComponent';

class AtlasRecordList extends React.Component {
  render() {

    if (this.props.errored) {
      return (<ErrorComponent message={"Nelze načíst záznamy"} />);
    }

    // if (this.props.loading || this.props.isInit) {
    //   return (
    //     <div className='vertical-center-div'>
    //       <LoadingComponent />
    //     </div>
    //   );
    // }

    return (
      <div>
        {this.props.records.map((record) =>
          <AtlasRecordItem {...record} key={record.recordId}
            selectedRecordId={this.props.selectedRecordId}
            selectRoad={this.props.selectRoad} />
        )}
      </div>
    );
  }
}

export default AtlasRecordList;