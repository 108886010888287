import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const LoadingComponent = ({ sizeClass = 'fs-1', iconClass = '' }) => {
    return (
        <div className={"text-center " + sizeClass} >
            <FontAwesomeIcon icon={faSpinner} className={"rotate-item p-0 m-0 " + iconClass} />
        </div>
    );
};

export default LoadingComponent;