import {
    COMMENT_ADD_SUCCESS,
    COMMENT_ADD_LOADING,
    COMMENT_ADD_ERRORED,

    COMMENT_ADD_RECORD_PREVIEW_SUCCESS,
    COMMENT_ADD_RECORD_PREVIEW_LOADING,
    COMMENT_ADD_RECORD_PREVIEW_ERRORED
} from './commentAddActionTypes';

import { DriversRoadsApi, jsonAPIAuthorizedRequest, onApiError } from '../../api/apiHelper';
import history from '../../store/configureHistory';

function commentAddSuccess() {
    return {
        type: COMMENT_ADD_SUCCESS
    };
}

function commentAddLoading() {
    return {
        type: COMMENT_ADD_LOADING
    };
}

function commentAddErrored() {
    return {
        type: COMMENT_ADD_ERRORED
    };
}

export function addCommentToRecord(recordId, text) {
    return (dispatch) => {
        debugger;
        dispatch(commentAddLoading());
        let options = jsonAPIAuthorizedRequest("POST", { text });

        fetch(DriversRoadsApi + 'api/comment/add/' + recordId, options)
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => response.json())
            .then((id) => {
                dispatch(commentAddSuccess());
                history.push('/comment/' + id);
            })
            .catch((e) => {
                onApiError(e);
                dispatch(commentAddErrored());
            });
    };
}

function commentRecordPreviewFetchSuccess(record) {
    return {
        type: COMMENT_ADD_RECORD_PREVIEW_SUCCESS,
        record
    };
}

function commentRecordPreviewFetcLoading() {
    return {
        type: COMMENT_ADD_RECORD_PREVIEW_LOADING
    };
}

function commentRecordPreviewFetcErrored() {
    return {
        type: COMMENT_ADD_RECORD_PREVIEW_ERRORED
    };
}

export function fetchRecordPreview(recordId) {
    return (dispatch) => {

        dispatch(commentRecordPreviewFetcLoading());
        let options = jsonAPIAuthorizedRequest("GET");

        fetch(DriversRoadsApi + 'api/record/get-preview/' + recordId, options)
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => response.json())
            .then((record) => dispatch(commentRecordPreviewFetchSuccess(record)))
            .catch((e) => {
                onApiError(e);
                dispatch(commentRecordPreviewFetcErrored());
            });
    };
}