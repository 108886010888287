import React from 'react';
import { Helmet } from "react-helmet";

class NotFoundPage extends React.Component {

  render() {
    return (<div className='container content-wrapper'>
      <Helmet>
        <title>Stránka nenalezena - Drivers’ Roads</title>
      </Helmet>
      <div className='row justify-content-center text-center'>
        <div className="col-md-12">
          <h3>Stránka nenalezena</h3>
        </div>
      </div>
    </div>);
  }
}

export default NotFoundPage;