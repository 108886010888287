import React from 'react';
import { connect } from 'react-redux';
import { stringify } from 'qs';

import { recordCommentsFetch } from '../../actions/record/recordCommentsActionCreators';

import RequestHelper from '../../api/RequestHelper';
import LoadingComponent from '../common/LoadingComponent';
import CommentsList from '../comment/CommentsList';
import AddCommentButton from '../comment/helper/AddCommentButton';
import OptionSelect from '../form/OptionSelect';
import Pagination from '../common/Pagination';

class RecordComments extends React.Component {

    constructor(props) {
        super(props);

        this.itemsOnPage = 5;
        this.state = {
            sort: 0
        };

        this.changeToPage = this.changeToPage.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        let value = event.target.value;
        this.setState({ sort: value });

        let pagination = RequestHelper.buildPaginationAndSort(1, this.itemsOnPage, value);
        let paginationQuery = stringify(pagination);
        this.props.recordCommentsFetch(this.props.recordId, paginationQuery);
    }

    changeToPage(pageNumber) {
        let pagination = RequestHelper.buildPaginationAndSort(pageNumber, this.itemsOnPage, this.state.sort);
        let paginationQuery = stringify(pagination);
        this.props.recordCommentsFetch(this.props.recordId, paginationQuery);
    }

    componentDidMount() {
        let pagination = RequestHelper.buildPaginationAndSort(1, this.itemsOnPage, this.state.sort);
        let paginationQuery = stringify(pagination);
        this.props.recordCommentsFetch(this.props.recordId, paginationQuery);
    }

    render() {
        // if (this.props.isLoading && this.props.comments.length === 0) {
        if (this.props.isLoading) {
            return (<LoadingComponent />);
        }

        const sortOptions =
            [{ value: '0', text: 'Nejnovější' },
            { value: '1', text: 'Nejstarší' },
            { value: '7', text: 'Nejlajkovanejsi' }];

        let content;
        if (this.props.comments.length === 0) {
            content = <div className="row justify-content-center my-2 text-center">
                <div className='col-lg-8'>
                    <p>Zatím zde nejsou žádné komentáře</p>
                </div>
            </div>;
        } else {
            content = <div>
                <CommentsList isPrivate={this.props.isPrivate} comments={this.props.comments} onClick={this.props.recordCommentsFetch} />
                <Pagination
                    changeToPage={this.changeToPage}
                    pagination={this.props.pagination} />
            </div>;
        }

        return (
            <div className="container pb-4">
                <div className={'p-2 clearfix'}>
                    <AddCommentButton id={this.props.recordId} />
                    <OptionSelect name={'sort'} value={this.state.sort} options={sortOptions} onChange={this.handleChange} className={'form-group float-right'} />
                </div>
                {content}
            </div>);
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        recordCommentsFetch: (recordId, pagination) => dispatch(recordCommentsFetch(recordId, pagination))
    };
};

const mapStateToProps = (state) => {
    return {
        user: state.oidc.user,
        pagination: state.record.comments.pagination,
        comments: state.record.comments.comments,
        isLoading: state.record.comments.isLoading,
        errored: state.record.comments.errored
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RecordComments);