class RequestHelper {

    static buildPaginationAndSort(page, items, sort) {
        return {
            sort,
            items,
            page
        };
    }

    static buildRangeQuery(countryCode, lng, lat, range) {
        if (countryCode === range) {
            return {
                countryCode
            };
        }

        return {
            lng,
            lat,
            distance: range
        };
    }

    static buildMapQuery(map) {
        return {
            minLatitude: map.minLatitude,
            maxLatitude: map.maxLatitude,
            minLongitude: map.minLongitude,
            maxLongitude: map.maxLongitude,
        };
    }

    static buildFilter(filter, configuration) {
        let request = {};

        if (filter.recordType === 'roads') {
            request.type = 0;
        } else if (filter.recordType === 'places') {
            request.type = 1;
        }

        if (filter.minRating !== 0) {
            request.minRating = filter.minRating;
        }
        if (filter.maxRating !== 5) {
            request.maxRating = filter.maxRating;
        }

        if (filter.minNumRating !== 0) {
            request.minNumRating = filter.minNumRating;
        }

        if (filter.maxNumRating !== configuration.maxRatings) {
            request.maxNumRating = filter.maxNumRating;
        }

        if (filter.minVisits !== 0) {
            request.minVisits = filter.minVisits;
        }
        if (filter.maxVisits !== configuration.maxVisits) {
            request.maxVisits = filter.maxVisits;
        }

        if (filter.minComments !== 0) {
            request.minComments = filter.minComments;
        }
        if (filter.maxComments !== configuration.maxComments) {
            request.maxComments = filter.maxComments;
        }

        if (filter.minFavourites !== 0) {
            request.minFavourites = filter.minFavourites;
        }
        if (filter.maxFavourites !== configuration.maxFavourites) {
            request.maxFavourites = filter.maxFavourites;
        }

        if (filter.minDistance !== 0) {
            request.minDistance = filter.minDistance;
        }

        if (filter.maxDistance !== 500) {
            request.maxDistance = filter.maxDistance;
        }

        //not in init version
        // if (filter.hideVisited === true) {
        //     request.hideVisited = filter.hideVisited;
        // }

        return request;
    }
}

export default RequestHelper;