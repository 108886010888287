var prefix = 'fas';
var iconName = 'wheel';
var width = 512;
var height = 512;
var ligatures = [];
var unicode = 'ffff';
var svgPathData = 'M256,0C114.841,0,0,114.841,0,256s114.841,256,256,256s256-114.841,256-256S397.159,0,256,0z M65.903,186.792 c13.012-38.038,37.816-70.764,71.731-94.64C172.16,67.847,213.09,54.999,256,54.999c42.91,0,83.84,12.848,118.366,37.153 c33.915,23.876,58.719,56.602,71.731,94.64l4.528,13.236H61.375L65.903,186.792z M206.414,452.863 c-41.091-8.695-78.785-31.256-106.136-63.526c-27.673-32.648-43.709-74.126-45.154-116.792l-0.421-12.422l12.223,2.248 c87.067,16.012,150.807,91.975,151.558,180.624l0.105,12.444L206.414,452.863z M273.175,324.277 c-5.638,2.328-11.219,3.459-17.063,3.459c-13.89,0-33.12-7.288-41.606-27.828c-5.87-14.209-3.223-26.538,0.031-34.379 c3.256-7.841,10.12-18.417,24.334-24.287c5.642-2.33,11.225-3.463,17.069-3.463c13.888,0,33.117,7.287,41.604,27.824 C307.002,288.499,296.069,314.82,273.175,324.277z M411.722,389.338c-27.351,32.269-65.044,54.83-106.135,63.525l-12.175,2.577 l0.105-12.445c0.751-88.646,64.489-164.608,151.556-180.621l12.224-2.248l-0.421,12.422 C455.43,315.213,439.394,356.69,411.722,389.338z';

export const faWheel = {
    // Use a prefix like 'fac' that doesn't conflict with a prefix in the standard Font Awesome styles
    // (So avoid fab, fal, fas, far, fa)
    prefix: prefix,
    iconName: iconName,
    icon: [
        width,
        height,
        ligatures,
        unicode,
        svgPathData
    ]
};